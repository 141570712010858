// ==========================================================================
// Base
// ==========================================================================

html {
  height: 100%;
  color: $g-color-gray;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 62.5%;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  line-height: 1.8;
  letter-spacing: normal;
  background-color: $g-color-white;
  overflow-wrap: break-word;
  touch-action: manipulation;
}

html:has(.l-header__sp-menu[aria-hidden="true"]) {
  overflow: hidden;
}

html:has(.mfp-ready) {
  overflow: hidden;
}

body {
  height: 100%;
  font-size: 1.6rem;
}

// html,
// body {
//   touch-action: manipulation;
// }

*,
*:before,
*:after {
  box-sizing: border-box;
}

audio,
canvas,
iframe,
img,
svg,
video,
embed,
object {
  max-width: 100%;
  vertical-align: bottom;
}

img {
  width: auto;
  height: auto;
}

a {
  transition: opacity 300ms;

  @media (any-hover: hover) {
    &:hover {
      opacity: 0.25;
    }
  }
}

// simplebar
.simplebar-track.simplebar-vertical {
  width: 1.2rem;
  @include media-breakpoint-up(lg) {
    width: 1.6rem;
  }
}

.simplebar-scrollbar.simplebar-visible:before {
  opacity: 1;
  background-color: $g-color-blue-150;
  border-radius: 0.4rem;
}
