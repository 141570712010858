// ==========================================================================
// How to use
// ==========================================================================

.p-how-to-use-card {
  height: 100%;
  border-radius: 3.2rem;
  border: 0.1rem solid $g-color-blue-150;
  padding: 2.4rem 2.4rem 1.6rem;
  @include media-breakpoint-up(lg) {
    padding: 3.2rem;
  }

  &__figure {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 9.6rem;
    overflow: hidden;
    @include media-breakpoint-up(lg) {
      height: 12rem;
    }

    &.-ver01 {
      align-items: flex-start;

      .p-how-to-use-card__img {
        height: 11.4rem;
        @include media-breakpoint-up(lg) {
          height: 14.2rem;
        }
      }
    }

    &.-ver02 {
      .p-how-to-use-card__img {
        height: 12.8rem;
        @include media-breakpoint-up(lg) {
          height: 16rem;
        }
      }
    }

    &.-ver03 {
      .p-how-to-use-card__img {
        height: 12.3rem;
        @include media-breakpoint-up(lg) {
          height: 15.4rem;
        }
      }
    }

    &.-ver04 {
      .p-how-to-use-card__img {
        height: 14.8rem;
        @include media-breakpoint-up(lg) {
          height: 18.4rem;
        }
      }
    }

    &.-ver05 {
      .p-how-to-use-card__img {
        height: 11.2rem;
        @include media-breakpoint-up(lg) {
          height: 14.4rem;
        }
      }
    }

    &.-ver06 {
      .p-how-to-use-card__img {
        height: 9.6rem;
        @include media-breakpoint-up(lg) {
          height: 12rem;
        }
      }
    }
  }

  &__heading {
    text-align: center;
    margin-top: 2.4rem;
    @include media-breakpoint-up(lg) {
      text-align: left;
      margin-top: 3.2rem;
    }
  }

  &__text {
    margin-top: 1.2rem;
    @include media-breakpoint-up(lg) {
      margin-top: 0.8rem;
    }
  }
}
