// ==========================================================================
// About
// ==========================================================================

.p-about-cols {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  margin-top: 3.2rem;
  @include media-breakpoint-up(lg) {
    flex-direction: row;
    gap: 4rem;
  }

  &__img {
    height: 18.9rem;
    margin-left: auto;
    margin-right: auto;
    @include media-breakpoint-up(lg) {
      height: 24rem;
      margin: 0;
    }
  }
}
