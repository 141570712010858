// ==========================================================================
// Display
// ==========================================================================

@mixin utilities($media) {
  $keyword: if($media != null, "-" + $media, "");

  .u-display-none#{$keyword} {
    display: none !important;
  }

  .u-display-block#{$keyword} {
    display: block !important;
  }

  .u-display-inline-block#{$keyword} {
    display: inline-block !important;
  }

  .u-display-flex#{$keyword} {
    display: flex !important;
  }

  .u-display-inline-flex#{$keyword} {
    display: inline-flex !important;
  }
}

@include utilities(null);
@each $media-name, $media-width in $g-breakpoint {
  @include media-breakpoint-up($media-name) {
    @include utilities($media-name);
  }
}
