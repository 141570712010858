// ==========================================================================
// Mypage
// ==========================================================================

.p-mypage-step {
  display: flex;
  flex-direction: column;
  background: $g-color-blue-50;
  border-radius: 0.8rem;
  margin-top: 2.4rem;
  @include media-breakpoint-up(lg) {
    flex-direction: row;
    margin-top: 3.6rem;
  }

  &__col {
    display: flex;
    gap: 1.6rem;
    padding: 2.4rem 1.6rem 2.4rem;
    @include media-breakpoint-up(lg) {
      flex-direction: column;
      flex: 0 0 auto;
      width: calc(4 / 12 * (100% + 4.8rem) - 4.8rem);
      padding: 2.4rem 1.6rem 1.7rem 2.4rem;
    }
  }

  &__col-img {
    flex-shrink: 0;
    width: 6.4rem;
    height: 6.4rem;
  }

  &__col-heading {
    margin-top: -0.6rem;
    margin-bottom: 1.2rem;
  }

  &__col-rectangle {
    width: 100%;
    height: 2.4rem;
    display: flex;
    justify-content: center;
    overflow: hidden;
    @include media-breakpoint-up(lg) {
      width: 4.8rem;
      position: relative;
      overflow: hidden;
      height: auto;
    }

    &::before {
      content: "";
      width: 16.7rem;
      height: 16rem;
      background-image: url(/assets/img/option/rectangle_bottom.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: 0 0;
      filter: drop-shadow(0px 2px 4px rgba(17, 49, 143, 0.13));
      transform: translateZ(0);
      position: relative;
      top: -0.2rem;
      @include media-breakpoint-up(lg) {
        width: 100%;
        // height: 14.9rem;
        height: 100%;
        background-image: url(/assets/img/option/rectangle_right.svg);
        background-position: 0 center;
        position: absolute;
        top: 0;
        left: -0.3rem;
        bottom: 0;
        margin: auto;
      }
    }
  }
}
