// ==========================================================================
// Top
// ==========================================================================

.p-top-about {
  padding-top: 6.8rem;
  padding-bottom: 11.2rem;
  @include media-breakpoint-up(lg) {
    padding-top: 8.8rem;
    padding-bottom: 12rem;
  }

  &__heading {
    margin-bottom: 3.2rem;
    @include media-breakpoint-up(lg) {
      margin-bottom: 7rem;
    }
  }

  //
  &__col-lv1 {
    @include media-breakpoint-up(lg) {
      border-bottom: 0.1rem solid $g-color-blue-150;
      padding-bottom: 11rem;
      margin-bottom: 12rem;
    }

    &-cols {
      border-bottom: 0.1rem solid $g-color-blue-150;
      padding-bottom: 2.4rem;
      margin-bottom: 2.4rem;
      @include media-breakpoint-up(lg) {
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }

    &-img01 {
      width: auto;
      height: calc(179 / 393 * 100vw);
      margin: 0 auto 3.6rem;
      position: relative;
      left: calc(-1 * (10 / 393 * 100vw));
      @include media-breakpoint-up(lg) {
        height: 28rem;
        margin-bottom: 3rem;
        left: 0;
      }
    }

    &-img02 {
      width: auto;
      height: calc(179 / 393 * 100vw);
      margin: 0 auto 3.6rem;
      @include media-breakpoint-up(lg) {
        height: 28rem;
        margin-bottom: 3rem;
      }
    }

    &-heading {
      @include media-breakpoint-up(lg) {
        text-align: center;
      }
    }

    &-heading,
    &-text {
      padding-left: 0.8rem;
      padding-right: 0.8rem;
      @include media-breakpoint-up(lg) {
        padding-left: 2.4rem;
        padding-right: 2.4rem;
      }
    }

    &-text {
      margin-top: 1.6rem;
      @include media-breakpoint-up(lg) {
        font-size: 2rem;
        line-height: 1.8;
      }
    }
  }

  //
  &__col-lv2 {
    @include media-breakpoint-up(lg) {
      border-bottom: 0.1rem solid $g-color-blue-150;
      padding-bottom: 11rem;
      margin-bottom: 12rem;
    }

    &-cols {
      border-bottom: 0.1rem solid $g-color-blue-150;
      padding-bottom: 2.4rem;
      margin-bottom: 2.4rem;
      @include media-breakpoint-up(lg) {
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }

    &-img01,
    &-img02,
    &-img03 {
      margin-left: auto;
      margin-right: auto;
      @include media-breakpoint-up(lg) {
        margin-bottom: 3rem;
      }
    }

    &-img01 {
      width: calc(88 / 104 * 100%);
      @include media-breakpoint-up(lg) {
        width: auto;
        height: 18rem;
      }
    }

    &-img02 {
      width: 100%;
      @include media-breakpoint-up(lg) {
        width: auto;
        height: 18rem;
        padding: 1.8rem;
      }
    }

    &-img03 {
      width: calc(63 / 104 * 100%);
      @include media-breakpoint-up(lg) {
        width: auto;
        height: 18rem;
        padding: 1rem;
      }
    }

    &-heading {
      @include media-breakpoint-up(lg) {
        text-align: center;
      }
    }

    &-heading,
    &-text {
      padding-right: 0.8rem;
      @include media-breakpoint-up(lg) {
        padding-left: 2.4rem;
        padding-right: 2.4rem;
      }
    }

    &-text {
      margin-top: 1.6rem;
      @include media-breakpoint-up(lg) {
        font-size: 2rem;
        line-height: 1.8;
      }
    }
  }

  //
  &__col-lv3 {
    &-cols {
      @include media-breakpoint-up(lg) {
        display: flex;
        justify-content: center;
      }
    }

    &-img {
      width: calc(272 / 353 * 100%);
      margin: 0 auto 3.6rem;
      @include media-breakpoint-up(lg) {
        width: auto;
        height: 28rem;
        margin: 0;
      }
    }

    &-area {
      padding-left: 0.8rem;
      padding-right: 0.8rem;
      @include media-breakpoint-up(lg) {
        width: 56rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 2.4rem;
        padding-right: 2.4rem;
        position: relative;
        left: 2.4rem;
      }
    }

    &-heading {
      @include media-breakpoint-up(lg) {
        text-align: center;
      }
    }

    &-text {
      margin-top: 1.6rem;
      @include media-breakpoint-up(lg) {
        font-size: 2rem;
        line-height: 1.8;
      }
    }
  }
}
