// ==========================================================================
// Help Text
// ==========================================================================

.c-help-text {
  color: $g-color-red;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0.02em;
  margin-top: 1rem;
  @include media-breakpoint-up(lg) {
    font-size: 1.6rem;
  }
}
