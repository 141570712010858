// ==========================================================================
// About
// ==========================================================================

.p-about-media {
  margin-top: 3.4rem;
  @include media-breakpoint-up(lg) {
    border-top: 0.1rem solid $g-color-blue-150;
    border-bottom: 0.1rem solid $g-color-blue-150;
    margin-top: 4rem;
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
    @include media-breakpoint-up(lg) {
      flex-direction: row;
      align-items: center;
      gap: 32px;
      padding-top: 2.4rem;
      padding-bottom: 2.4rem;
    }

    &:is(.p-about-media__item + .p-about-media__item) {
      margin-top: 5.4rem;
      @include media-breakpoint-up(lg) {
        border-top: 0.1rem solid $g-color-blue-150;
        margin-top: 0;
      }
    }
  }

  &__image {
    padding-right: 10.7rem;
    @include media-breakpoint-up(lg) {
      flex: 0 0 auto;
      width: calc(3 / 10 * (100% + 6.4rem) - 6.4rem);
      padding: 0;
    }
  }

  &__box-indent {
    margin-top: 1.2rem;
    margin-bottom: 1.8rem;
    @include media-breakpoint-up(lg) {
      margin-top: 0.2rem;
      margin-bottom: 1.4rem;
    }
  }
}
