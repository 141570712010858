// ==========================================================================
// Mypage
// ==========================================================================

.p-mypage-cols {
  display: flex;
  align-items: baseline;
  gap: 1.6rem;
  @include media-breakpoint-up(lg) {
    gap: 2.4rem;
  }

  &01 {
    display: flex;
    flex-direction: column-reverse;
    align-items: baseline;
    gap: 2.4rem;
    @include media-breakpoint-up(lg) {
      flex-direction: row;
      gap: 3.2rem;
    }

    & * {
      width: 19.2rem;
      @include media-breakpoint-up(lg) {
        width: auto;
      }
    }
  }

  &02 {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    gap: 2.4rem;
    @include media-breakpoint-up(lg) {
      gap: 3.2rem;
    }
  }
}
