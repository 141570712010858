// ==========================================================================
// Height
// ==========================================================================

@mixin utilities($media) {
  $keyword: if($media != null, "-" + $media, "");

  .u-height-auto#{$keyword} {
    height: auto !important;
  }
}

@include utilities(null);
