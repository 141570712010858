// ==========================================================================
// Mypage
// ==========================================================================

.p-mypage-flow {
  margin-top: 3.2rem;

  &__list {
    display: flex;
    border-radius: 0.8rem;
    border: 0.1rem solid $g-color-blue-150;
    overflow: auto hidden;
    @include media-breakpoint-up(lg) {
      border: none;
    }
  }

  &__list-item {
    color: $g-color-gray-800;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 3.2rem;
    background-color: $g-color-white;
    padding-left: 3.2rem;
    padding-right: 1.6rem;
    white-space: nowrap;
    position: relative;

    @include media-breakpoint-up(lg) {
      display: inline-flex;
      align-items: center;
      flex-grow: 1;
      min-height: 4.4rem;
      font-size: 1.6rem;
      line-height: 1.4;
      padding-top: 0.8rem;
      padding-left: 4rem;
      padding-right: 2.4rem;
      padding-bottom: 0.8rem;
      white-space: normal;
    }

    &::before,
    &::after {
      content: "";
      width: 0;
      height: 0;
      display: block;
      position: absolute;
    }

    &::before {
      top: 0;
      right: -1rem;
      bottom: 0;
      z-index: 3;
      border-left: 1.2rem solid $g-color-white;
      border-top: 1.7rem solid transparent;
      border-bottom: 1.7rem solid transparent;
      margin-top: auto;
      margin-bottom: auto;
      @include media-breakpoint-up(lg) {
        border-top-width: 2.2rem;
        border-bottom-width: 2.2rem;
      }
    }

    &::after {
      right: -1.1rem;
      z-index: 2;
      border-left: 1.2rem solid $g-color-blue-150;
      border-top: 1.7rem solid transparent;
      border-bottom: 1.7rem solid transparent;
      @include media-breakpoint-up(lg) {
        border-top-width: 2.2rem;
        border-bottom-width: 2.2rem;
      }
    }

    &:first-child {
      padding-left: 1.6rem;
      @include media-breakpoint-up(lg) {
        padding-left: 2.4rem;
        border-radius: 0.8rem 0 0 0.8rem;
      }
    }

    &:last-child {
      &::before,
      &::after {
        content: none;
      }

      @include media-breakpoint-up(lg) {
        border-radius: 0 0.8rem 0.8rem 0;
      }
    }

    &.-current {
      font-weight: 700;
      background-color: $g-color-yellow-200;

      &::before {
        content: none;
      }

      &::after {
        border-left-color: $g-color-yellow-200;
        top: 0;
        right: -1.1rem;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
      }
    }

    &.-current-prev {
      &::after {
        content: none;
      }
    }
  }
}
