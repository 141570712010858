// ==========================================================================
// Mypage
// ==========================================================================

.p-mypage-form {
  &__cols {
    display: flex;
    flex-direction: column;
    gap: 3.2rem;
    @include media-breakpoint-up(lg) {
      gap: 4rem;
    }
  }

  &__cols-col {
    @include media-breakpoint-up(lg) {
      display: flex;
      align-items: center;
    }
  }

  &__cols-col-label {
    flex-shrink: 0;
    @include media-breakpoint-up(lg) {
      flex: 0 0 auto;
      width: 19.2rem;
      margin-bottom: 0;
    }
  }

  &__group {
    display: flex;
    flex-direction: column;
    gap: 3.2rem;
    @include media-breakpoint-up(lg) {
      gap: 4rem;
    }
  }

  &__group-cols {
    display: flex;
    flex-direction: column;
    @include media-breakpoint-up(lg) {
      flex-direction: row;
    }

    &.-label-none {
      @include media-breakpoint-up(lg) {
        margin-left: 19.2rem;
      }
    }
  }

  &__group-label {
    @include media-breakpoint-up(lg) {
      flex: 0 0 auto;
      width: 19.2rem;
    }
  }

  &__group-col {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    @include media-breakpoint-up(lg) {
      width: 100%;
      flex-direction: row;
      gap: 3.2rem;
    }

    &.-gap-0 {
      gap: 0;
    }

    &.-exp {
      flex-direction: row;
      align-items: center;
      gap: 0;

      .c-select {
        flex-shrink: 0;
        width: 8.5rem;
        @include media-breakpoint-up(lg) {
          width: 10.8rem;
        }
      }
    }
  }

  &__group-file {
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 1.6rem;
    @include media-breakpoint-up(lg) {
      width: 100%;
      align-items: center;
      flex-direction: row;
      gap: 4rem;
    }
  }

  &__group-file-img {
    display: block;
    width: 24.9rem;
    margin-top: -0.8rem;
    @include media-breakpoint-up(lg) {
      margin-top: -1.6rem;
      margin-left: 19.2rem;
    }
  }

  //
  &__panel {
    display: flex;
    align-items: center;
    gap: 1.6rem;
    border-radius: 2.4rem;
    box-shadow: 0 0 0 0.1rem $g-color-blue-150 inset;
    border: none;
    padding: 2rem 2.4rem 2.4rem 1.6rem;
    margin-top: 4.8rem;
    @include media-breakpoint-up(lg) {
      gap: 2.4rem;
      padding: 2.8rem 3.2rem;
      margin-top: 6.4rem;
    }

    &:has(.p-mypage-form__panel-input:checked) {
      box-shadow: 0 0 0 0.2rem $g-color-blue inset;
    }

    & + .p-mypage-form__panel {
      margin-top: 2.4rem;
    }
  }

  &__panel-area {
    @include media-breakpoint-up(lg) {
      width: calc(100% - 4.4rem);
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 2.4rem;
    }
  }

  &__panel-input {
    flex: 0 0 auto;
  }

  &__panel-label {
    flex: 0 0 auto;
    width: calc(100% - 3.6rem);
    @include media-breakpoint-up(lg) {
      flex: 0 1 auto;
      width: auto;
    }
  }

  &__panel-title {
    display: block;
    color: $g-color-blue;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.4;
    margin-bottom: 1.6rem;
    @include media-breakpoint-up(lg) {
      font-size: 1.6rem;
      margin-bottom: 2.4rem;
    }
  }

  &__panel-name {
    display: block;
    margin-bottom: 1.6rem;
    @include media-breakpoint-up(lg) {
      margin-bottom: 2rem;
    }
  }

  &__panel-zip {
    display: block;
  }

  &__panel-address {
    display: block;
    margin-top: 0.4rem;
  }

  &__panel-tel {
    display: block;
    margin-top: 0.8rem;
    @include media-breakpoint-up(lg) {
      margin-top: 1.6rem;
    }
  }

  &__panel-buttons {
    display: flex;
    gap: 1rem;
    margin-top: 2rem;
    @include media-breakpoint-up(lg) {
      flex-shrink: 0;
      flex-direction: column;
      margin-top: 0;
      margin-left: auto;
    }
  }
}
