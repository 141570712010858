// ==========================================================================
// Modal
// ==========================================================================

.c-modal {
  max-width: 81.2rem;
  background: $g-color-white;
  margin: 4rem auto;
  position: relative;
}

.mfp-container {
  padding-left: 2rem;
  padding-right: 2rem;
  overflow-y: scroll;
}
