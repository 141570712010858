// ==========================================================================
// Visit
// ==========================================================================

.p-price-visit {
  &__cols {
    margin-top: 5rem;
    @include media-breakpoint-up(lg) {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      // margin-top: 6.4rem;
    }
  }

  &__col {
    display: flex;
    align-items: center;
    gap: 2rem;
    @include media-breakpoint-up(lg) {
      align-items: center;
      gap: 2.7rem;
    }

    &:is(.p-price-visit__col + .p-price-visit__col) {
      border-top: 0.1rem solid $g-color-blue-150;
      padding-top: 2.8rem;
      margin-top: 2.8rem;
      @include media-breakpoint-up(lg) {
        margin-top: 2.4rem;
        padding-top: 2.4rem;
      }
    }
  }

  &__col-img {
    width: 9.6rem;
    flex-shrink: 0;
    @include media-breakpoint-up(lg) {
      width: 12rem;
    }
  }

  &__col-heading {
    margin-top: 0.4rem;
    margin-bottom: 1.6rem;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0.7rem;
    }
  }
}
