// ==========================================================================
// Text Style
// ==========================================================================

.c-text-style {
  // indent
  &__indent-lv1 {
    margin-top: 2rem;
    @include media-breakpoint-up(lg) {
      margin-top: 1.6rem;
    }
  }

  &__indent-lv2 {
    margin-top: 2.8rem;
  }

  &__indent-lv3 {
    margin-top: 12rem;
    @include media-breakpoint-up(lg) {
      margin-top: 16rem;
    }
  }

  &__indent-lv4 {
    margin-top: 4rem;
    @include media-breakpoint-up(lg) {
      margin-top: 7rem;
    }
  }

  &__indent-lv5 {
    margin-top: 4.8rem;
    @include media-breakpoint-up(lg) {
      margin-top: 6rem;
    }
  }

  &__indent-lv6 {
    margin-top: 5.2rem;
    @include media-breakpoint-up(lg) {
      margin-top: 4.8rem;
    }
  }

  &__indent-lv7 {
    margin-top: 3.2rem;
    @include media-breakpoint-up(lg) {
      margin-top: 4rem;
    }
  }

  &__indent-lv8 {
    margin-top: 2rem;
    @include media-breakpoint-up(lg) {
      margin-top: 1rem;
    }
  }

  &__indent-lv9 {
    margin-top: 11rem;
    @include media-breakpoint-up(lg) {
      margin-top: 15rem;
    }
  }

  &__indent-lv10 {
    margin-top: 6rem;
    @include media-breakpoint-up(lg) {
      margin-top: 8rem;
    }
  }

  &__indent-lv11 {
    margin-top: 7.4rem;
    @include media-breakpoint-up(lg) {
      margin-top: 11rem;
    }
  }

  // left
  &__indent-l-lv1 {
    padding-left: 1.6rem;
    @include media-breakpoint-up(lg) {
      padding-left: 0;
    }
  }

  &__indent-l-lv2 {
    @include media-breakpoint-up(lg) {
      padding-left: 2.8rem;
    }
  }

  // right
  &__indent-r-lv1 {
    padding-right: 1.6rem;
    @include media-breakpoint-up(lg) {
      padding-left: 0;
    }
  }

  // X
  &__indent-x-lv1 {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }

  &__indent-x-lv2 {
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    @include media-breakpoint-up(lg) {
      padding-left: 1.6rem;
      padding-right: 1.6rem;
    }
  }

  &__indent-x-lv3 {
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    @include media-breakpoint-up(lg) {
      padding-left: 0.8rem;
      padding-right: 0.8rem;
    }
  }

  &__indent-x-lg {
    @include media-breakpoint-up(lg) {
      padding-left: 1.6rem;
      padding-right: 1.6rem;
    }
  }

  // page
  &__page-heading {
    border-top: solid 0.2rem $g-color-blue;
    padding: 1.5rem 2rem 0;
    margin: 12rem -2rem 0;
    @include media-breakpoint-up(lg) {
      padding: 1.7rem 0 0;
      margin: 15.4rem 0 0;
    }

    &.-ver01 {
      padding-top: 2.2rem;
      padding-left: 0;
      padding-right: 0;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 3.2rem;
      @include media-breakpoint-up(lg) {
        padding-top: 2rem;
        margin-top: 12rem;
        margin-bottom: 2.8rem;
      }
    }

    &.-ver02 {
      padding-top: 2.2rem;
      margin-bottom: 3.2rem;
      @include media-breakpoint-up(lg) {
        padding-top: 2rem;
        margin-top: 12rem;
        margin-bottom: 2.8rem;
      }
    }

    &.-ver03 {
      position: relative;
      padding-right: 12.1rem;

      .-absolute {
        position: absolute;
        top: calc(50% + 1.1rem);
        right: 2rem;
        transform: translate(0, -50%);
        @include media-breakpoint-up(lg) {
          right: 0.8rem;
        }
      }
    }
  }

  // border
  &__border-top {
    &::before {
      display: block;
      content: "";
      width: 4rem;
      height: 0.4rem;
      background-color: $g-color-blue;
      margin-bottom: 0.7rem;
      @include media-breakpoint-up(lg) {
        width: 8rem;
        height: 0.8rem;
        margin-bottom: 1.6rem;
      }
    }
  }

  &__border-top-yellow {
    &::before {
      display: block;
      content: "";
      width: 4rem;
      height: 0.4rem;
      background-color: $g-color-yellow;
      margin-bottom: 0.7rem;
      @include media-breakpoint-up(lg) {
        width: 8rem;
        height: 0.8rem;
        margin-bottom: 1.6rem;
      }
    }
  }
}
