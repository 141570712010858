// ==========================================================================
// Mypage
// ==========================================================================

.p-mypage-buttons {
  display: flex;
  align-items: baseline;
  gap: 1.2rem;
  width: 100%;
  background-color: $g-color-blue;
  padding: 1.4rem;
  position: fixed;
  bottom: 0;
  z-index: map-get($g-zindex, mypage-button);
  margin-left: -2rem;
  margin-right: -2rem;
  display: none;

  @include media-breakpoint-up(lg) {
    display: flex;
    gap: 3.2rem;
    background-color: rgba(#000, 0);
    position: static;
    padding: 0;
    margin: 0;
    position: sticky;
    top: 3.6rem;
  }
}

.p-mypage-button {
  --button-font-size: 1.6rem !important;
  --button-border-radius: 0.8rem !important;
  --button-padding: 0.9rem 1.2rem !important;
  flex: 0 0 auto;
  width: calc(6 / 12 * (100% + 1.2rem) - 1.2rem);
  @include media-breakpoint-up(lg) {
    --button-font-size: 1.4rem !important;
    --button-padding: 1.2rem 0.8rem !important;
    width: calc(4 / 12 * (100% + 3.2rem) - 3.2rem);
    flex-direction: row;
    gap: 0.8rem;
  }

  @include media-breakpoint-up(xl) {
    --button-font-size: 1.6rem !important;
    --button-padding: 1.2rem 1.2rem !important;
    gap: 1.6rem;
  }

  &:last-child {
    display: none;
    @include media-breakpoint-up(lg) {
      display: inline-flex;
    }
  }

  &__img {
    display: none;
    @include media-breakpoint-up(lg) {
      display: block;
      flex-shrink: 0;
      width: 4rem;
    }
  }

  &.-active {
    @include media-breakpoint-up(lg) {
      --button-outline-color: #{$g-color-yellow};
      --button-background-color: #{$g-color-yellow};
      height: 6.4rem;
    }
  }
}
