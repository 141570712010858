// ==========================================================================
// Info
// ==========================================================================

.l-info {
  &__item {
    border-top: solid 0.4rem $g-color-yellow;
    padding-top: 1.6rem;
    margin-top: 12rem;
    @include media-breakpoint-up(lg) {
      margin-top: 16rem;
    }

    &:is(.l-info__item + .l-info__item) {
      margin-top: 0;
      @include media-breakpoint-up(lg) {
        margin-top: 8rem;
      }
    }
  }

  &__heading {
    color: $g-color-blue;
    font-size: 2.4rem;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: 0.04em;
    @include media-breakpoint-up(lg) {
      font-size: 3rem;
      line-height: 1.6;
      letter-spacing: 0.02em;
    }
  }

  &__text {
    color: $g-color-gray-500;
    font-size: 1.2rem;
    line-height: 1.6;
    letter-spacing: 0.02em;
    margin-top: 2rem;

    @include media-breakpoint-up(lg) {
      font-size: 1.4rem;
      margin-top: 1.6rem;
    }
  }

  &__text01 {
    font-size: 1.6rem;
    line-height: 1.8;
    letter-spacing: 0.02em;
    margin-top: 1.6rem;
    @include media-breakpoint-up(lg) {
      font-size: 1.8rem;
      line-height: 1.8;
      margin-top: 0;
    }
  }

  //
  &__box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    @include media-breakpoint-up(lg) {
      padding: 0;
    }

    &.-main {
      // padding-top: 3.2rem;
      // @include media-breakpoint-up(lg) {
      //   padding-top: 0;
      // }
    }
  }

  &__box-col {
    display: flex;
    padding: 0 0.8rem;
    align-items: center;
    gap: 3.2rem;
    align-self: stretch;
    @include media-breakpoint-up(lg) {
      padding: 0 1.6rem;
      gap: 4rem;
    }

    &:first-child {
      margin-top: 2.4rem;
      @include media-breakpoint-up(lg) {
        margin-top: 1.6rem;
      }
    }

    &:is(.l-info__box-col + .l-info__box-col) {
      border-top: $g-color-blue-300 solid 0.1rem;
      padding-top: 2rem;
      margin-top: 2rem;
      @include media-breakpoint-up(lg) {
        padding-top: 2.4rem;
        margin-top: 2.4rem;
      }
    }

    &:last-child {
      margin-bottom: 8rem;
      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
      }
    }
  }

  &__box-col-img {
    width: 4.8rem;
    height: 4.8rem;
    flex-shrink: 0;
    @include media-breakpoint-up(lg) {
      width: 6.4rem;
      height: 6.4rem;
    }
  }

  &__box-col-textarea {
    display: flex;
    flex-direction: column;
    @include media-breakpoint-up(lg) {
      width: 100%;
      align-items: center;
      // justify-content: space-between;
      flex-direction: row;
      gap: 3.2rem;
    }
  }

  &__box-col-heading {
    color: $g-color-black;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1.4; /* 22.4px */
    letter-spacing: 0.02em; // 0.32px

    @include media-breakpoint-up(lg) {
      width: 19.6rem;
      flex-shrink: 0;
      font-size: 2rem;
    }

    @include media-breakpoint-up(xxl) {
      width: 30rem;
    }
  }

  &__box-col-text {
    font-size: 1.6rem;
    line-height: 1.8;
    letter-spacing: 0.02em;
    margin-top: 0.2rem;
    @include media-breakpoint-up(lg) {
      font-size: 1.8rem;
      margin-top: 0;
    }
  }

  &__box-faq {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    border-bottom: $g-color-blue-300 solid 0.1rem;
    padding-bottom: 3.2rem;
    margin-bottom: 2.4rem;
    @include media-breakpoint-up(lg) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 2.5rem 1.6rem 4.2rem;
      margin-bottom: 0;
    }
  }

  &__tel-box {
    width: 100%;
    @include media-breakpoint-up(lg) {
      padding: 2.8rem 1.6rem 0;
    }
  }

  &__tel {
    display: flex;
    align-items: baseline;
    justify-content: center;
    gap: 0.8rem;
    margin-top: 1.8rem;
    margin-bottom: 2.6rem;
    @include media-breakpoint-up(lg) {
      justify-content: center;
      // align-items: center;
      margin-top: 2.8rem;
      margin-bottom: 4.3rem;
    }

    &-icon {
      @include media-breakpoint-up(lg) {
        width: 2.8rem;
      }
    }

    &-inner {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.8rem;
      @include media-breakpoint-up(lg) {
        flex-direction: row;
        flex-wrap: wrap;
        gap: 1rem 2.4rem;
      }
    }

    &-link {
      flex-shrink: 0;
      color: $g-color-blue;
      font-size: 3.3rem;
      font-weight: 700;
      line-height: 1;
      letter-spacing: 0.03em;
      text-decoration: none;
      @include media-breakpoint-up(lg) {
        font-size: 3.8rem;
        letter-spacing: 0.06em;
      }
    }

    &-text {
      color: $g-color-gray-500;
      font-size: 1.2rem;
      line-height: 1.6;
      letter-spacing: 0.02em;
      @include media-breakpoint-up(lg) {
        font-size: 1.4rem;
        margin-top: -0.3rem;
      }
    }
  }

  //
  &.-max-width-1104 {
    .l-info__box-col {
      @include media-breakpoint-up(lg) {
        padding-left: 0.8rem;
        padding-right: 0.8rem;
        gap: 30px;
      }
    }

    .l-info__box-faq {
      @include media-breakpoint-up(lg) {
        gap: 0;
        padding-left: 0.8rem;
        padding-right: 0.8rem;
      }
    }

    .l-info__tel-box {
      @include media-breakpoint-up(lg) {
        padding-left: 0.8rem;
        padding-right: 0.8rem;
      }
    }
  }

  &__indent {
    margin-top: 1.5em;
  }

  &__indent-top {
    margin-top: 2.8rem;
    @include media-breakpoint-up(lg) {
      margin-top: 4.2rem;
    }
  }

  &.-login {
    padding-left: 0 !important;
    padding-right: 0 !important;

    & > div {
      @include media-breakpoint-up(lg) {
        width: 100%;
      }
    }

    .l-info__item {
      @include media-breakpoint-up(lg) {
        gap: 0;

        .c-grid-col__4-10-lg {
          width: 29.2rem;
        }
        .c-grid-col__6-10-lg {
          width: calc(100% - 29.2rem);
        }
      }

      // イレギュラー
      @media screen and (min-width: 1400px) {
        gap: 0 6.4rem;

        .c-grid-col__4-10-lg {
          width: calc(4 / 10 * (100% + 6.4rem) - 6.4rem);
        }
        .c-grid-col__6-10-lg {
          width: calc(6 / 10 * (100% + 6.4rem) - 6.4rem);
        }
      }
    }

    .l-info__box-col {
      @include media-breakpoint-up(lg) {
        gap: 3.2rem;
      }
    }

    .l-info__box-col-heading {
      @include media-breakpoint-up(lg) {
        width: 13rem;
      }
      @include media-breakpoint-up(xl) {
        width: 19.6rem;
      }
    }

    .l-info__box-faq {
      @include media-breakpoint-up(lg) {
        gap: 0.8rem;
      }
    }
  }
}
