// ==========================================================================
// Figure
// ==========================================================================

.c-figure {
  border-top: 0.4rem solid $g-color-yellow;
  margin: 0 -2rem 3rem;
  @include media-breakpoint-up(lg) {
    border-width: 0.8rem;
    border-radius: 0 0 1.6rem 1.6rem;
    margin: 0 0 2.5rem;
    overflow: hidden;
  }

  &__img {
    width: 100%;
  }
}
