// ==========================================================================
// Simulation
// ==========================================================================

.p-simulation-floating {
  width: 12rem;
  height: 12rem;
  position: fixed;
  right: 1.2rem;
  bottom: 8rem;
  border-radius: 1.2rem;
  box-shadow: 0 0 0 0.4rem $g-color-blue inset;
  background-color: $g-color-white;
  text-align: center;
  padding: 1.2rem 0.4rem;
  z-index: 100;

  &__total {
    color: $g-color-gray-800;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1.4;
  }

  &__num {
    display: flex;
    align-items: baseline;
    justify-content: center;

    .-unit {
      color: $g-color-gray-800;
      font-size: 1.2rem;
      font-weight: 700;
      line-height: 1.4;
    }
  }

  &__input {
    width: 4.6rem;
    color: $g-color-gray-800;
    font-size: 2.4rem;
    font-weight: 700;
    line-height: 1.4;
    background-color: $g-color-white;
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
    text-align: center;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
      -moz-appearance: textfield;
    }
  }

  &__button {
    cursor: pointer;
    width: 8.8rem;
    height: 3.4rem;
    font-size: 1.4rem;
    border-radius: 1.2rem;
    padding: 0;
    margin-top: 0.2rem;
  }

  //
  &.-ver01 {
    right: 1.2rem;
    bottom: 2.4rem;
    @include media-breakpoint-up(lg) {
      right: 2.4rem;
    }
  }
}
