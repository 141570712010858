// ==========================================================================
// Radio
// ==========================================================================

.c-radios {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  gap: 4.8rem;
  margin-top: 4rem;
  margin-bottom: 3.2rem;
  @include media-breakpoint-up(lg) {
    gap: 4.3rem;
    margin-top: 4.8rem;
    margin-bottom: 2.8rem;
  }
}

.c-radio {
  display: inline-flex;
  align-items: center;
  gap: 0.8rem;
  color: $g-color-black;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.8;
  letter-spacing: 0.02em;
  cursor: pointer;
  @include media-breakpoint-up(lg) {
    font-size: 1.6rem;
  }

  &__input {
    position: relative;
    appearance: none;
    flex-shrink: 0;
    width: 2rem;
    height: 2rem;
    border: 0.1rem solid $g-color-blue-150;
    border-radius: 50%;
    background-color: $g-color-white;
    position: relative;

    &:checked {
      border-color: $g-color-blue;
      // border-width: 0.6rem;

      &::before {
        display: block;
        width: 1.4rem;
        height: 1.4rem;
        content: "";
        border-radius: 100%;
        background-color: $g-color-blue;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }
    }

    &:focus {
      outline: none;
      box-shadow: none;
      &:checked {
      }
    }

    &:focus-visible {
      outline: none;
      box-shadow: none;
    }
  }

  &[aria-disabled="true"] {
    cursor: not-allowed;
  }
}

.c-radio-list {
  border-top: 0.1rem solid $g-color-blue-150;
  border-bottom: 0.1rem solid $g-color-blue-150;
  @include media-breakpoint-up(lg) {
    margin-left: 1.6rem;
    margin-right: 1.6rem;
  }

  &__item {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1.6rem;
    padding: 1.1rem 0.8rem 1.1rem 1.6rem;
    @include media-breakpoint-up(lg) {
      padding: 2.1rem 1.6rem;
    }

    & + .c-radio-list__item {
      border-top: 0.1rem solid $g-color-blue-150;
    }

    &:has(.c-radio__input:checked) {
      background-color: $g-color-blue-50;
    }
  }

  &__item-area {
    @include media-breakpoint-up(lg) {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1.6rem;
    }
  }

  &__item-title {
    display: block;
  }

  &__item-price {
    flex-shrink: 0;
    display: block;
    color: $g-color-gray-800;
    font-weight: 700;
    line-height: 1.4;
    margin-top: 0.6rem;
    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }

    .-text {
      font-size: 1.2rem;
      @include media-breakpoint-up(lg) {
        font-size: 1.4rem;
      }
    }

    .-num {
      font-size: 1.6rem;
      padding-left: 0.8rem;
      @include media-breakpoint-up(lg) {
        font-size: 1.8rem;
      }
    }

    .-unit {
      font-size: 1.4rem;
      @include media-breakpoint-up(lg) {
        font-size: 1.4rem;
      }
    }

    .-tax {
      font-size: 1.2rem;
      @include media-breakpoint-up(lg) {
        font-size: 1.4rem;
      }
    }
  }
}
