// ==========================================================================
// Option
// ==========================================================================

.p-faq-a {
  &__inner {
    display: flex;
    align-items: baseline;
    gap: 0.8rem;
    padding-top: 2.2rem;
    padding-left: 1.2rem;
    padding-right: 0.8rem;
    @include media-breakpoint-up(lg) {
      padding: 2.4rem 1.6rem 3.4rem 2rem;
    }

    &::before {
      display: block;
      content: " A.";
      font-size: 1.8rem;
      font-weight: 700;
      line-height: 1;
      letter-spacing: 0.02em;
      @include media-breakpoint-up(lg) {
        font-size: 2.4rem;
      }
    }
  }

  &__content {
    & > * {
      & + * {
        margin-top: 2rem;
        @include media-breakpoint-up(lg) {
          font-size: 1.6rem;
        }
      }
    }
  }

  &__content-buttons {
    display: inline-flex;
    flex-direction: column;
    gap: 1.2rem;
  }
}
