/* =========================================================
 _grid
========================================================= */
.c-grid-cols {
  --grid-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  gap: var(--grid-gutter-y) #{map-get($grid-gutter, "sp")};

  @include media-breakpoint-up(lg) {
    gap: var(--grid-gutter-y) #{map-get($grid-gutter, "tab")};
  }

  @include media-breakpoint-up(xl) {
    gap: var(--grid-gutter-y) #{map-get($grid-gutter, "pc")};
  }

  &.-gutter-y-24 {
    --grid-gutter-y: 2.4rem;
  }

  &.-gutter-y-32 {
    --grid-gutter-y: 3.2rem;
  }

  &.-gutter-y-40 {
    --grid-gutter-y: 4rem;
  }

  &.-gutter-y-48 {
    --grid-gutter-y: 4.8rem;
  }

  &.-gutter-y-56 {
    --grid-gutter-y: 5.6rem;
  }

  &.-gutter-y-64 {
    --grid-gutter-y: 6.4rem;
  }

  &.-gutter-y-16-lg {
    @include media-breakpoint-up(lg) {
      --grid-gutter-y: 1.6rem;
    }
  }

  &.-gutter-y-40-lg {
    @include media-breakpoint-up(lg) {
      --grid-gutter-y: 4rem;
    }
  }

  &.-gutter-y-56-lg {
    @include media-breakpoint-up(lg) {
      --grid-gutter-y: 5.6rem;
    }
  }

  &.-gutter-y-64-lg {
    @include media-breakpoint-up(lg) {
      --grid-gutter-y: 6.4rem;
    }
  }

  // &.-gutter-y-0 {
  //   gap: 0 #{map-get($grid-gutter, "sp")};
  //   @include media-breakpoint-up(tab) {
  //     gap: 0 #{map-get($grid-gutter, "tab")};
  //   }
  // }

  // &.-gutter-y-sm {
  //   gap: #{map-get($grid-gutter, "sp")};
  //   @include media-breakpoint-up(tab) {
  //     gap: #{map-get($grid-gutter, "sp")} #{map-get($grid-gutter, "tab")};
  //   }
  // }

  // @each $media-name, $media-width in $grid-media-min-widths {
  //   @include media-breakpoint-up($media-name) {
  //     &.-gutter-y-0-#{$media-name} {
  //       gap: 0 #{map-get($grid-gutter, "tab")};
  //     }
  //   }
  // }
}

.c-grid-col {
  flex: 1 0 0%;

  // @each $num in $grid-columns-sp {
  //   @for $i from 1 through $num {
  //     &__#{$i}-#{$num} {
  //       flex: 0 0 auto;
  //       width: calc((#{$i} / #{$num} * (100% + #{map-get($grid-gutter, "sp")})) - #{map-get($grid-gutter, "sp")});
  //     }
  //   }
  // }

  // @each $num in $grid-columns-sp01 {
  //   @for $i from 1 through $num {
  //     &__#{$i}-#{$num} {
  //       flex: 0 0 auto;
  //       width: calc((#{$i} / #{$num} * (100% + #{map-get($grid-gutter, "sp")})) - #{map-get($grid-gutter, "sp")});
  //     }
  //   }
  // }

  // @each $num in $grid-columns-sp01 {
  //   @for $i from 1 through $num {
  //     &__#{$i}-#{$num}-tab {
  //       flex: 0 0 auto;
  //       width: calc((#{$i} / #{$num} * (100% + #{map-get($grid-gutter, "tab")})) - #{map-get($grid-gutter, "tab")});
  //     }
  //   }
  // }

  @each $num in 3 {
    @for $i from 1 through $num {
      &__#{$i}-#{$num} {
        flex: 0 0 auto;
        width: calc((#{$i} / #{$num} * (100% + #{map-get($grid-gutter, "sp")})) - #{map-get($grid-gutter, "sp")});

        // @include media-breakpoint-up(lg) {
        //   width: calc((#{$i} / #{$num} * (100% + #{map-get($grid-gutter, "tab")})) - #{map-get($grid-gutter, "tab")});
        // }
      }
    }
  }

  // @each $num in 12 {
  //   @for $i from 1 through $num {
  //     &__#{$i}-#{$num}-xl {
  //       flex: 0 0 auto;
  //       width: calc((#{$i} / #{$num} * (100% + #{map-get($grid-gutter, "pc")})) - #{map-get($grid-gutter, "pc")});
  //     }
  //   }
  // }

  @include media-breakpoint-up(lg) {
    @each $num in $grid-cols {
      @for $i from 1 through $num {
        &__#{$i}-#{$num}-lg {
          flex: 0 0 auto;
          width: calc((#{$i} / #{$num} * (100% + #{map-get($grid-gutter, "tab")})) - #{map-get($grid-gutter, "tab")});
          @include media-breakpoint-up(xl) {
            width: calc((#{$i} / #{$num} * (100% + #{map-get($grid-gutter, "pc")})) - #{map-get($grid-gutter, "pc")});
          }
        }
      }
    }
  }
}

// @each $media-name, $media-width in $grid-media-min-widths {
//   @include media-breakpoint-up($media-name) {
//     @each $num in $grid-cols {
//       @for $i from 1 through $num {
//         .c-grid-col__#{$i}-#{$num}-#{$media-name} {
//           flex: 0 0 auto;
//           width: calc((#{$i} / #{$num} * (100% + #{map-get($grid-gutter, "pc")})) - #{map-get($grid-gutter, "pc")});
//         }
//       }
//     }
//   }
// }

// .c-grid-col__6-10-tab {
//   color: #fff;
// }

// .c-grid-col__6-10-tab {
//   flex: 0 0 auto;
//   width: calc(6 / 10 * (100% + 6.4rem) - 6.4rem);
// }

// .c-grid-col__4-10-tab {
//   flex: 0 0 auto;
//   width: calc(4 / 10 * (100% + 6.4rem) - 6.4rem);
// }

// .c-grid-col__6-10-tab {
//   color: #fff;
// }

// イレギュラー
// @include media-breakpoint-up(tab) {
//   .c-grid-col__2_5-10-tab {
//     flex: 0 0 auto;
//     width: calc((2.5 / 10 * (100% + #{map-get($grid-gutter, "tab")})) - #{map-get($grid-gutter, "tab")});
//   }
// }
