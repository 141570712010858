// ==========================================================================
// Temporary-storage
// ==========================================================================

.p-temporary-storage-card {
  height: 100%;
  border-radius: 3.2rem;
  border: 0.1rem solid $g-color-blue-150;
  padding: 1.6rem 2.4rem 1.6rem;
  @include media-breakpoint-up(lg) {
    padding: 3.2rem;
  }

  &__figure {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 9.6rem;
    overflow: hidden;
    @include media-breakpoint-up(lg) {
      height: 12rem;
    }

    &.-ver01 .p-temporary-storage-card__img {
      height: 10.2rem;
      @include media-breakpoint-up(lg) {
        height: 12.8rem;
      }
    }

    &.-ver02 .p-temporary-storage-card__img {
      height: 9.9rem;
      @include media-breakpoint-up(lg) {
        height: 12.4rem;
      }
    }

    &.-ver03 .p-temporary-storage-card__img {
      height: 10.9rem;
      @include media-breakpoint-up(lg) {
        height: 13.6rem;
      }
    }

    &.-ver04 .p-temporary-storage-card__img {
      height: 10.9rem;
      @include media-breakpoint-up(lg) {
        height: 12.8rem;
      }
    }

    &.-ver05 .p-temporary-storage-card__img {
      height: 10.2rem;
      @include media-breakpoint-up(lg) {
        height: 12.8rem;
      }
    }

    &.-ver06 .p-temporary-storage-card__img {
      height: 10.2rem;
      @include media-breakpoint-up(lg) {
        height: 12.8rem;
      }
    }
  }

  &__heading {
    margin-top: 1.6rem;
    @include media-breakpoint-up(lg) {
      margin-top: 3.2rem;
    }
  }

  &__text {
    margin-top: 1.2rem;
    @include media-breakpoint-up(lg) {
      margin-top: 0.8rem;
    }
  }
}
