// ==========================================================================
// Checkbox
// ==========================================================================

.c-checkbox {
  display: inline-flex;
  align-items: flex-start;
  gap: 0.8rem;
  color: $g-color-black;
  cursor: pointer;

  &.-center {
    align-items: center;
  }

  &:has(.c-checkbox__input:checked) {
    .c-checkbox__input {
      outline: none;
      box-shadow: 0 0 0 0.1rem $g-color-blue inset;
      background-color: $g-color-blue-70;

      &::before {
        content: "";
        background-image: url(/assets/img/icon_check.svg);
        width: 1.4rem;
        height: 1.1rem;
        flex-shrink: 0;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        margin-top: auto;
        margin-bottom: auto;
        transform: translateX(-50%);
      }
    }
  }

  &:has(.c-checkbox__input:focus),
  &:has(.c-checkbox__input:focus-visible) {
    .c-checkbox__input {
      outline: none;
    }
  }

  &__input {
    display: flex;
    width: 2rem;
    height: 2rem;
    position: relative;
    -webkit-appearance: none;
    appearance: none;
    flex-shrink: 0;
    box-shadow: 0 0 0 0.1rem $g-color-blue-150 inset;
    border: none;
    border-radius: 0.4rem;
    background-color: $g-color-white;
    cursor: pointer;
  }

  &__text {
    color: $g-color-black;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: 0.02em;
    position: relative;
    @include media-breakpoint-up(lg) {
      font-size: 1.6rem;
      top: -0.125em;
    }

    .-small {
      display: block;
      color: $g-color-gray-500;
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 1.6;
      letter-spacing: 0.02em;
      margin-top: 0.667em;
      @include media-breakpoint-up(lg) {
        font-size: 1.4rem;
      }
    }
  }

  &__text01 {
    color: $g-color-black;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.8;
    letter-spacing: 0.02em;
    @include media-breakpoint-up(lg) {
      font-size: 1.6rem;
    }
  }

  &__group {
    @include media-breakpoint-up(lg) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 0.8rem;
    }
  }
}
