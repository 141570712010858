// ==========================================================================
// Card
// ==========================================================================

.c-card {
  height: 100%;
  border-radius: 1.6rem;
  border: 0.1rem solid $g-color-blue-150;
  overflow: hidden;

  &__body {
    padding: 2.4rem 2.4rem 1.6rem;
    @include media-breakpoint-up(lg) {
      padding: 3rem 3.2rem 2.4rem;
    }
  }

  &__body-cols {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  &__body-cols-button {
    flex-shrink: 0;
  }

  &__body-heading {
    color: $g-color-blue;
    font-size: 2.1rem;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: 0.02em;
    @include media-breakpoint-up(lg) {
      font-size: 2.4rem;
      line-height: 1.6;
    }
  }

  & &__indent-lv1 {
    margin-top: 1.8rem;
    @include media-breakpoint-up(lg) {
      margin-top: 1rem;
    }
  }

  & &__indent-lv2 {
    margin-top: 1.8rem;
  }
}
