// ==========================================================================
// Simulation
// ==========================================================================

.p-simulation-card {
  border: 0.1rem solid $g-color-blue;
  border-radius: 2.4rem;
  padding: 1.6rem 2.4rem 2.4rem;
  margin-top: 6.4rem;
  overflow: hidden;

  @include media-breakpoint-up(lg) {
    padding: 3.2rem 4rem 4rem;
  }

  &__item {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    border-bottom: 0.1rem solid $g-color-blue-150;
    padding: 0 1.2rem 2.4rem;
    margin: 1.4rem 0 2rem;
    @include media-breakpoint-up(lg) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0 0 4rem 0.8rem;
      margin: 2.4rem 0 3.2rem;
    }
  }

  &__item-num {
    color: $g-color-gray-800;
    font-weight: 700;
    line-height: 1.4;
    margin-bottom: 2rem;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }

    .-num {
      font-size: 2.4rem;
      @include media-breakpoint-up(lg) {
        font-size: 3rem;
      }
    }

    .-unit {
      font-size: 1.6rem;

      @include media-breakpoint-up(lg) {
        font-size: 1.8rem;
      }
    }
  }

  &__plan {
  }

  &__plan-cols {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    border-bottom: 0.1rem solid $g-color-blue-150;
    padding: 0 1.2rem 2.4rem;
    margin: 1.4rem 0 2rem;

    @include media-breakpoint-up(lg) {
      gap: 6.4rem;
      flex-direction: row;
      align-items: center;
      padding: 0 0 4rem 0;
      margin: 3.2rem 0 4rem 0;
      position: relative;

      &::before {
        display: block;
        content: "";
        width: 0.1rem;
        height: calc(100% - 4rem);
        background-color: $g-color-blue-150;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
    }
  }

  &__plan-col {
    border-bottom: 0.1rem solid $g-color-blue-150;
    padding-bottom: 1.8rem;
    margin-bottom: 2.4rem;
    @include media-breakpoint-up(lg) {
      flex: 0 0 auto;
      width: calc(0.5 * (100% + 6.4rem) - 6.4rem);
      border: none;
      padding: 0 0.8rem;
      margin: 0;
    }
  }

  &__plan-text {
    color: $g-color-gray-800;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1.4;
    @include media-breakpoint-up(lg) {
      font-size: 1.8rem;
    }
  }

  &__plan-price {
    color: $g-color-gray-800;
    font-weight: 700;
    line-height: 1.4;
    margin-top: 1.2rem;

    .-num {
      font-size: 2.4rem;
      @include media-breakpoint-up(lg) {
        font-size: 3rem;
      }

      &-sm {
        font-size: 1.6rem;
        @include media-breakpoint-up(lg) {
          font-size: 1.8rem;
        }
      }
    }
  }

  &__graph {
    width: 100%;
    @include media-breakpoint-up(lg) {
      flex: 0 0 auto;
      width: calc(0.5 * (100% + 6.4rem) - 6.4rem);
      padding: 0;
    }
  }
}
