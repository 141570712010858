// ==========================================================================
// Mypage
// ==========================================================================

.p-mypage-note {
  border-radius: 2.4rem;
  background: $g-color-blue-50;
  padding-top: 1.6rem;
  padding-left: 2.4rem;
  padding-right: 1.4rem;
  padding-bottom: 2.4rem;
  margin-top: 4rem;
  overflow: hidden;
  @include media-breakpoint-up(lg) {
    padding-top: 2rem;
    padding-left: 3.2rem;
  }

  &__inner {
    max-height: 58.8rem;
    @include media-breakpoint-up(lg) {
      max-height: 43.9rem;
    }
  }

  &__content {
    padding-right: 1.6rem;
    @include media-breakpoint-up(lg) {
      padding-right: 2.4rem;
    }

    & * {
      & + * {
        margin-top: 1.5em;
      }
    }
  }
}
