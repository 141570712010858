// ==========================================================================
// Top
// ==========================================================================

.p-top-fv {
  $fv-header-height-sp: 6rem;
  $fv-header-height-pc: 12rem;
  $fv-button-height-sp: 27.4rem;
  $fv-button-height-pc: 17rem;

  height: calc(100svh - #{$fv-header-height-sp});
  min-height: 600px;

  @include media-breakpoint-up(lg) {
    height: calc(100svh - #{$fv-header-height-pc});
  }

  &__titlearea {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 2.4rem;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
    margin-left: auto;
    margin-right: auto;
    z-index: 10;

    @include media-breakpoint-up(lg) {
      padding: 0;
      left: calc(20px + 30 * (100vw - 1024px) / 256);
      bottom: calc(60px + 30 * (100vw - 1024px) / 256);
    }

    @include media-breakpoint-up(xl) {
      left: 5rem;
      bottom: 6rem;
    }

    @include media-breakpoint-up(xxl) {
      left: 8rem;
      bottom: 9rem;
    }
  }

  &__heading {
    color: $g-color-blue;
    font-size: calc(18 / 393 * 100vw);
    font-weight: 700;
    line-height: 1.556;
    // line-height: 1.5625;
    letter-spacing: 0.06em;
    padding: 0.8rem 1.6rem;
    border-radius: 0.8rem;
    background: $g-color-white;
    @include media-breakpoint-up(sm) {
      font-size: 1.8rem;
    }

    @include media-breakpoint-up(lg) {
      font-size: calc(30px + 7 * (100vw - 1024px) / 256);
      background: rgba($g-color-white, 0);
      padding: 0;
      border-radius: 0;
    }

    @include media-breakpoint-up(xl) {
      font-size: 3.7rem;
    }

    @include media-breakpoint-up(xxl) {
      font-size: 3.8rem;
    }

    &-text {
      @include media-breakpoint-up(lg) {
        line-height: 1;
        background: $g-color-white;
        padding: 1.1rem 2.4rem;

        &.-first {
          border-radius: 0.8rem 0.8rem 0 0;
        }

        &.-second {
          border-radius: 0 0.8rem 0 0;
        }

        &.-third {
          border-radius: 0 0.8rem 0.8rem 0.8rem;
        }
      }
    }
  }

  &__slider {
    width: 100%;
    height: calc(100% - #{$fv-button-height-sp});
    padding-bottom: 2rem;
    position: relative;

    @include media-breakpoint-up(lg) {
      height: calc(100% - #{$fv-button-height-pc});
      padding-left: 2.4rem;
      padding-bottom: 0;
    }
  }

  &__slider-wrapper {
  }

  &__slider-slide {
    position: relative;
    @include media-breakpoint-up(lg) {
      overflow: hidden;
      border-radius: 1.6rem;
    }

    &-inner {
      width: 100%;
      height: 100%;
    }

    &-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &-progressbar {
      display: none;
      @include media-breakpoint-up(lg) {
        display: block;
        width: 100%;
        height: 0.8rem;
        background: $g-color-gray-100;
        position: absolute;
        bottom: 0;
        overflow: hidden;
        opacity: 0;
        transition: opacity 200ms;

        &::before {
          display: block;
          content: "";
          background: $g-color-blue;
          position: absolute;
          left: 0;
          top: 0;
          width: 0;
          height: 100%;
          transition: width 5000ms;
        }
      }
    }

    &.-custom-active {
      @include media-breakpoint-up(lg) {
        .p-top-fv__slider-slide-progressbar {
          opacity: 1;

          &::before {
            width: 100%;
          }
        }
      }
    }
  }

  // &:has(".-custom-active") {

  // }

  &__slider-pagination {
    display: flex;
    justify-content: center;
    gap: 1.6rem;
    top: auto !important;
    bottom: 0 !important;
    @include media-breakpoint-up(lg) {
      display: none;
      // gap: 0;
      // border-radius: 0 0 1.6rem 1.6rem;
    }

    .swiper-pagination-bullet {
      display: block;
      width: 4rem;
      height: 0.4rem;
      border-radius: 0;
      background: $g-color-gray-100;
      margin: 0 !important;
      opacity: 1 !important;
      overflow: hidden;
      position: relative;

      // @include media-breakpoint-up(lg) {
      //   width: calc(100% / 3);
      //   height: 0.8rem;
      // }

      &::before {
        display: block;
        content: "";
        background: $g-color-blue;
        position: absolute;
        left: 0;
        top: 0;
        width: 0;
        height: 100%;
      }

      &.swiper-pagination-bullet-active {
        &.-custom-first-active,
        &.-custom-active {
          &::before {
            transition: width 5000ms;
            width: 100%;
          }
        }
      }
    }
  }

  //
  &__discount {
    margin-top: 2.4rem;
    margin-left: -2rem;
    margin-right: -2rem;
    @include media-breakpoint-up(lg) {
      margin-left: -4.8rem;
      margin-right: -4.8rem;
    }
  }

  &__discount-area {
    max-width: 34.5rem;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    @include media-breakpoint-up(lg) {
      max-width: 100.6rem;
    }
  }

  &__discount-img {
    // @include media-breakpoint-up(lg) {
    //   display: block;
    //   width: 100.6rem;
    //   max-width: none;
    //   margin: 4rem auto 0;
    // }
  }

  & &__discount-button {
    width: 16rem;
    position: absolute;
    top: calc(120 / 335 * 100%);
    left: 0;
    right: 0;
    margin: auto;
    @include media-breakpoint-up(lg) {
      --button-font-size: 1.4rem;
      --button-padding: 0.5rem 0rem;
      width: 14.4rem;
      top: 2.1rem;
      left: auto;
      right: 5.5rem;
    }
  }

  //
  &__buttons {
    height: $fv-button-height-sp;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.6rem;
    padding-top: 2.4rem;
    padding-bottom: 4rem;

    @include media-breakpoint-up(lg) {
      height: $fv-button-height-pc;
      flex-direction: row;
      justify-content: space-between;
      padding-top: 1rem;
      padding-bottom: 0;
    }
  }

  &__button,
  &__area-button {
    width: 24.1rem;
  }

  &__button {
    @include media-breakpoint-up(lg) {
      width: auto;
    }
  }

  &__area {
    @include media-breakpoint-up(lg) {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      gap: 2.4rem;
    }
  }

  &__area-button {
    @include media-breakpoint-up(lg) {
      --button-font-size: 2.4rem !important;
      --button-padding: 1.9rem 1rem !important;
      width: 17.6rem !important;
      flex-shrink: 0;
    }
  }

  &__area-button-text-main {
    display: block;
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 1.4;
    margin-top: 0.5rem;
  }

  &__area-button-text-sub {
    display: block;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.4;

    span {
      font-size: 0.75em;
      vertical-align: top;
      @include media-breakpoint-up(lg) {
        font-size: 0.5em;
      }
    }
  }

  &__area-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.2rem;
    width: 24.1rem;
    color: $g-color-blue;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    border-left: 0.1rem solid $g-color-blue-150;
    border-right: 0.1rem solid $g-color-blue-150;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 3.2rem;
    @include media-breakpoint-up(lg) {
      width: auto;
      padding: 0 4rem;
      margin-top: 0;
    }

    &-main {
      font-size: 1.6rem;
      letter-spacing: 0.05em;
      @include media-breakpoint-up(lg) {
        font-size: 2rem;
        line-height: 1.4;
        letter-spacing: 0.05em;
      }
    }

    &-sub {
      font-size: 1.2rem;
      @include media-breakpoint-up(lg) {
        font-size: 1.6rem;
      }
    }
  }
}
