// ==========================================================================
// Select
// ==========================================================================

.c-select {
  position: relative;
  display: inline-block;
  width: 100%;

  &__label {
    -moz-appearance: none;
    -webkit-appearance: none;
    width: 100%;
    height: 4.2rem;
    color: $g-color-black;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.8;
    letter-spacing: 0.02em;
    border: 0.1rem solid $g-color-blue-150;
    border-radius: 0.8rem;
    background: $g-color-white;
    padding: 0.8rem 2.4rem;
    vertical-align: bottom;

    @include media-breakpoint-up(lg) {
      height: 4.4rem;
      font-size: 1.6rem;
      padding-top: 0.7rem;
      padding-bottom: 0.7rem;
    }

    &:focus {
      border-color: $g-color-blue;
      background: $g-color-white;
      // box-shadow: 0px 0px 0px 4px rgba(17, 49, 143, 0.13) inset;
      box-shadow: none;
    }

    &:focus-visible {
      border-color: $g-color-blue;
      outline: none;
    }
  }

  &__icon {
    flex-shrink: 0;
    width: 1.2rem;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 2.4rem;
    margin-top: auto;
    margin-bottom: auto;
    transform: translateX(50%);
    pointer-events: none;
    @include media-breakpoint-up(lg) {
      width: 1.6rem;
    }
  }

  //
  &.-size-lg {
    .c-select__label {
      height: 5.3rem;
      font-size: 1.8rem;
      padding: 0 2rem;
      @include media-breakpoint-up(lg) {
        height: 4.7rem;
        font-size: 2rem;
        padding: 0 2.4rem;
      }
    }

    .c-select__icon {
      right: 2rem;
      @include media-breakpoint-up(lg) {
        width: 1.2rem;
        right: 2.4rem;
      }
    }
  }

  &.-size-xl {
    .c-select__label {
      height: 5.3rem;
      font-size: 1.8rem;
      padding: 0 2rem;
      @include media-breakpoint-up(lg) {
        height: 6.3rem;
        font-size: 2rem;
        padding: 0 2.4rem;
      }
    }

    .c-select__icon {
      right: 2rem;
      @include media-breakpoint-up(lg) {
        width: 1.2rem;
        right: 2.4rem;
      }
    }
  }
}
