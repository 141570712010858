// ==========================================================================
// Top
// ==========================================================================

.p-top-voice {
  background: linear-gradient(180deg, #fff 0%, #ebeffa 100%);
  padding-top: 12rem;
  padding-bottom: 4rem;

  @include media-breakpoint-up(lg) {
    background: linear-gradient(180deg, #fff 0%, #f2f4fa 100%);
    padding-top: 10.6rem;
    padding-bottom: 16rem;
  }

  &__heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    text-align: center;
    margin-bottom: 4rem;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      justify-content: center;
      gap: 4rem;
      text-align: left;
      margin-bottom: 8.6rem;
    }

    &-icon {
      height: 4rem;
      @include media-breakpoint-up(lg) {
        height: 7.2rem;
      }
    }
  }

  &__grid {
    @include media-breakpoint-up(xl) {
      padding-left: calc(48px + 48 * (100vw - 1280px) / 640);
      padding-right: calc(48px + 48 * (100vw - 1280px) / 640);
    }

    @include media-breakpoint-up(xxl) {
      padding-left: 9.6rem;
      padding-right: 9.6rem;
    }
  }

  &__grid-link {
    display: block;
    position: relative;
    border-radius: 0.8rem;
    overflow: hidden;

    &::before {
      display: block;
      content: "";
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background: $g-color-blue-150;
      opacity: 0;
      transition: opacity 300ms;
    }

    @media (any-hover: hover) {
      &:hover {
        opacity: 1;

        &::before {
          opacity: 0.8;
        }
      }
    }
  }

  &__grid-heading {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1.1rem;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 2;
  }

  &__grid-heading-main {
    color: $g-color-blue;
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.4;
    position: relative;
    z-index: 2;
  }

  &__grid-heading-sub {
    color: $g-color-blue;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1.4;
    position: relative;
    z-index: 2;
  }

  &__grid-svg {
    position: absolute;
  }

  &__grid-img {
  }
}
