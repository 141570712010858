// ==========================================================================
// discount
// ==========================================================================

.p-price-discount {
  position: relative;
  &__img {
    display: block;
    width: 100%;
    max-width: 35.5rem;
    margin: 3.2rem auto 0;
    @include media-breakpoint-up(lg) {
      position: absolute;
      top: -3rem;
      right: -2.4rem;
      margin: 0;
    }
  }
}
