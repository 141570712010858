// ==========================================================================
// Footer
// ==========================================================================

.l-footer {
  width: 100%;
  background-color: $g-color-blue;
  margin-top: 12rem;
  position: sticky;
  top: 100svh;

  &__inner {
    display: flex;
    padding: 4rem 2rem 2.4rem 2rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 6.8rem;
    align-self: stretch;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      justify-content: space-between;
      padding: 3.6rem 4.8rem;
      gap: 1rem;
    }
  }

  &__col {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.6rem;
    align-self: stretch;
    @include media-breakpoint-up(lg) {
      flex-direction: row;
      gap: 4rem;
    }
  }

  &__col-link {
    color: $g-color-white;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.4; /* 16.8px */
    text-decoration: none;
  }

  &__copyright {
    color: $g-color-white;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.4; /* 14px */
  }
}
