// ==========================================================================
// How to use
// ==========================================================================

.p-how-to-use-step {
  display: flex;
  flex-direction: column;
  border-radius: 3.2rem;
  border: 0.1rem solid $g-color-blue-150;
  margin-top: 3.2rem;
  margin-bottom: 3.2rem;
  @include media-breakpoint-up(lg) {
    flex-direction: row;
    // margin-top: 10.4rem;
    margin-top: 6.4rem;
  }

  &__item {
    display: flex;
    gap: 2.4rem;
    padding: 2.4rem 0 1.6rem;
    margin: 0 2.4rem;
    @include media-breakpoint-up(lg) {
      gap: 1.6rem;
      flex-direction: column;
      flex: 0 0 auto;
      flex-grow: 1;
      width: 25%;
      padding: 0 3.2rem;
      margin: 2.4rem 0 1.1rem;
    }

    &:is(.p-how-to-use-step__item + .p-how-to-use-step__item) {
      border-top: 0.1rem solid $g-color-blue-150;
      @include media-breakpoint-up(lg) {
        border-top: none;
        border-left: 0.1rem solid $g-color-blue-150;
      }
    }
  }

  &__item-box {
  }

  &__num {
    display: flex;
    width: 5.6rem;
    height: 5.6rem;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 0.2rem solid $g-color-blue;
    border-radius: 100%;
    @include media-breakpoint-up(lg) {
      width: 6.4rem;
      height: 6.4rem;
    }
  }

  &__num-text {
    color: $g-color-blue;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 0.02em;
    @include media-breakpoint-up(lg) {
      font-size: 1.2rem;
    }
  }

  &__num-num {
    color: $g-color-blue;
    font-size: 2.4rem;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 0.02em;
    @include media-breakpoint-up(lg) {
      font-size: 2.8rem;
    }
  }

  &__heading {
  }

  &__text {
    margin-top: 1rem;
    @include media-breakpoint-up(lg) {
      margin-top: 1.6rem;
    }
  }
}
