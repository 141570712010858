// ==========================================================================
// Heading
// ==========================================================================

.c-heading {
  // h1
  &__h1-lv2 {
    color: $g-color-blue;
    font-size: 3.6rem;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: 0.02em;
    margin-top: 6.4rem;
    @include media-breakpoint-up(lg) {
      font-size: 6.4rem;
      margin-top: 9.3rem;
    }
  }

  &__h1-lv3 {
    color: $g-color-blue;
    font-size: 3.6rem;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: 0.02em;
    margin-top: 6.4rem;
    @include media-breakpoint-up(lg) {
      font-size: 5.4rem;
      margin-top: 6rem;
    }

    &.-ver01 {
      @include media-breakpoint-up(lg) {
        margin-top: 12.8rem;
      }
    }

    &.-ver02 {
      margin-top: 6.5rem;
      @include media-breakpoint-up(lg) {
        margin-top: 10rem;
      }
    }

    &.-ver03 {
      margin-top: 2.8rem;
      @include media-breakpoint-up(lg) {
        margin-top: 3.2rem;
      }
    }
  }

  // h2
  &__h2-lv1 {
    color: $g-color-blue;
    font-size: 2.4rem;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: 0.06em;
    @include media-breakpoint-up(lg) {
      font-size: 3.6rem;
    }
  }

  &__h2-lv2 {
    color: $g-color-blue;
    font-size: 2.4rem;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: 0.06em;
    @include media-breakpoint-up(lg) {
      font-size: 3rem;
    }
  }

  // h3
  &__h3-lv1 {
    color: $g-color-blue;
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: 0.02em;
    @include media-breakpoint-up(lg) {
      font-size: 2.4rem;
      line-height: 1.6;
    }
  }

  &__h3-lv2 {
    color: $g-color-blue;
    font-size: 2.1rem;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: 0.02em;
    @include media-breakpoint-up(lg) {
      font-size: 2.4rem;
      line-height: 1.6;
    }
  }

  // h4
  &__h4-lv1 {
    color: $g-color-black;
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: 0.02em;
    @include media-breakpoint-up(lg) {
      font-size: 2rem;
    }
  }

  &__h4-lv2 {
    color: $g-color-blue;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: 0.05em;

    @include media-breakpoint-up(lg) {
      font-size: 2rem;
    }
  }

  &__h4-lv3 {
    color: $g-color-blue;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1.4;
  }

  // h5
  &__h5-lv1 {
    color: $g-color-black;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: 0.02em;
    @include media-breakpoint-up(lg) {
      font-size: 1.8rem;
    }
  }

  // h6
  &__h6-lv1 {
    color: $g-color-black;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: 0.02em;
    @include media-breakpoint-up(lg) {
      font-size: 1.6rem;
    }
  }

  // Box
  &__box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1.6rem;
    padding: 1rem 1.6rem 1rem 1.2rem;
    border-radius: 1.2rem;
    box-shadow: 0 0 0 0.1rem $g-color-blue inset;
    @include media-breakpoint-up(lg) {
      gap: 2.4rem;
      border-radius: 1.6rem;
      padding: 1.7rem 2rem 1.6rem 1.6rem;
    }

    &-text {
      color: $g-color-blue;
      font-size: 1.6rem;
      font-weight: 700;
      line-height: 1.4;
      letter-spacing: 0.02em;

      @include media-breakpoint-up(lg) {
        font-size: 2rem;
        line-height: 1.6;
      }
    }

    &:has(.c-heading__box-icon) {
      padding-right: 1.6rem;
      cursor: pointer;
      transition: opacity 300ms, background-color 300ms, box-shadow 300ms;

      .c-heading__box-text {
        transition: color 300ms;
      }

      .c-heading__box-icon {
        &::before,
        &::after {
          transition: background-color 300ms;
        }
      }

      @media (any-hover: hover) {
        &:hover {
          opacity: 1;
          background-color: $g-color-blue-150;
          box-shadow: 0 0 0 0.1rem $g-color-blue-150 inset;

          & > span {
            color: $g-color-white;
          }

          .c-heading__box-icon {
            &::before,
            &::after {
              background-color: $g-color-white;
            }
          }
        }
      }

      @include media-breakpoint-up(lg) {
        padding-right: 2rem;
      }

      .c-heading__box-icon {
        width: 2.1rem;
        height: 2.1rem;
        position: relative;
        flex-shrink: 0;

        @include media-breakpoint-up(lg) {
          width: 2.5rem;
          height: 2.5rem;
        }

        &::before,
        &::after {
          content: "";
          background-color: $g-color-blue;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
        }

        &::before {
          width: 0.1rem;
          height: 100%;
          transition: transform 300ms;
        }

        &::after {
          width: 100%;
          height: 0.1rem;
        }
      }

      &.-active {
        .c-heading__box-icon {
          &::before {
            transform: rotate(90deg);
          }
        }
      }
    }
  }
}
