// ==========================================================================
// Mixin
// ==========================================================================

//------ breakpoint -------//

@mixin media-breakpoint-up($name) {
  @media screen and (min-width: map-get($g-breakpoint, $name)) {
    @content;
  }
}

@mixin media-breakpoint-down($name) {
  @media not screen and (min-width: map-get($g-breakpoint, $name)) {
    @content;
  }
}

//------ Typography -------//

@mixin typography($typography-name) {
  $typography-values: map-get($a-typography, $typography-name);
  $typography-value: map-get($typography-values, values);
  $typography-breakpoint: map-get($typography-values, breakpoint);

  @if ($typography-breakpoint != null) {
    @each $breakpoint-key, $breakpoint-value in $typography-breakpoint {
      @include media-breakpoint-up($breakpoint-key) {
        @each $breakpoint-css-property, $breakpoint-css-value in $breakpoint-value {
          #{$breakpoint-css-property}: #{$breakpoint-css-value};
        }
      }
    }
  }

  @each $css-property, $css-value in $typography-value {
    #{$css-property}: #{$css-value};
  }
}

//------ web font -------//
@mixin font-roboto {
  font-family: "Roboto", sans-serif;
  font-style: normal;
}

//------ Icon -------//

@mixin iconfont {
  font-family: "iconfont";
  font-style: normal;
  font-weight: normal;
  line-height: $g-line-height-100;
}

//------ Overflow -------//

@mixin overflow-cover {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: $g-color-gray-100;
  opacity: 0.9;
}

@mixin overflow-icon() {
  @include iconfont;
  content: map-get($iconfont-content, hand-pointer-left);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 6rem;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  margin: auto;
}

@mixin hover-opacity {
  cursor: pointer;
  transition: opacity 300ms;

  @media (any-hover: hover) {
    &:hover {
      opacity: 0.25;
    }
  }
}

// -----------------------------------------------------------------
// Grid
// -----------------------------------------------------------------

@mixin grid($property, $columns, $parent-columns, $gutters) {
  @debug $property;
  @if $gutters == null {
    #{$property}: calc((#{$columns} / #{$parent-columns} * (100% + #{map-get($grid-gutter, "tab")})) - #{map-get($grid-gutter, "tab")});
  } @else {
    #{$property}: calc(#{$columns} / #{$parent-columns} * (100% + #{map-get($grid-gutter, "tab")}));
  }
}
