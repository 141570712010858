// ==========================================================================
// Support
// ==========================================================================

.p-price-support {
  &__cols {
    @include media-breakpoint-up(lg) {
      margin-top: -0.8rem;
    }
  }

  &__col {
    display: flex;
    flex-direction: column;
    border-radius: 1.6rem;
    border: 0.1rem solid $g-color-blue-150;
    padding: 2.4rem;
    margin-top: 3.2rem;
    @include media-breakpoint-up(lg) {
      height: calc(100% - 6.4rem);
      margin-top: 6.4rem;
    }
  }

  &__col-img {
    height: 16rem;
    margin: 0 auto 1.6rem;
  }

  &__col-heading {
    text-align: center;
  }
}
