// ==========================================================================
// Simulation
// ==========================================================================

.p-simulation-others {
  display: flex;
  flex-wrap: wrap;
  padding: 2.4rem 1.2rem;
  border-top: 0.2rem solid $g-color-blue-150;
  border-bottom: 0.2rem solid $g-color-blue-150;

  &:first-child {
    border-top: none;
  }

  @include media-breakpoint-up(lg) {
    // flex-wrap: nowrap;
    align-items: flex-end;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }

  & + .p-simulation-others {
    border-top: none;
  }

  &:has(.p-simulation-others__danger-text) {
    background-color: $g-color-red-200;
    padding-bottom: 3rem;
  }

  &__label {
    // color: $g-color-black;
    // font-size: 1.2rem;
    // font-weight: 700;
    // line-height: 1.4;
    // letter-spacing: 0.02em;

    &.-name {
      width: 100%;
      @include media-breakpoint-up(lg) {
        width: calc(100% - 46.6rem);
        min-width: 2.05rem;
        padding-right: 3.2rem;
        // flex-grow: 1;
      }
    }

    &.-number {
      flex-grow: 1;
      @include media-breakpoint-up(lg) {
        flex-grow: 0;
      }
    }

    .-unit {
      color: $g-color-black;
      font-size: 1.2rem;
      font-weight: 700;
      line-height: 1.4;
      letter-spacing: 0.02em;
    }
  }

  &__number {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1.6rem;
    justify-content: space-between;
    margin-top: 2.2rem;
    @include media-breakpoint-up(lg) {
      width: auto;
      justify-content: flex-start;
      flex-shrink: 0;
      padding-right: 2.4rem;
      margin-top: 0;
      margin-left: auto;
    }
  }

  &__label-name {
    display: block;
    color: $g-color-black;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: 0.02em;
    margin-bottom: 1.4rem;
    @include media-breakpoint-up(lg) {
      font-size: 1.4rem;
      margin-bottom: 1.2rem;
    }
  }

  &__input {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.4rem;
  }

  &__input-text {
    height: 4.2rem;
    font-size: 1.4rem !important;
    // font-size: 1.6rem !important;
    padding: 1rem 1.6rem;
    border-radius: 0.8rem;
    @include media-breakpoint-up(lg) {
      font-size: 1.6rem !important;
      height: 4.4rem;
    }
  }

  &__input-number {
    width: 7rem;
    height: 4.2rem;
    padding: 0;
    text-align: center;
    border-radius: 0.8rem;
    @include media-breakpoint-up(lg) {
      font-size: 1.6rem !important;
      height: 4.4rem;
    }

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
      -moz-appearance: textfield;
    }
  }

  &__button {
    width: 100%;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    margin-top: 2.4rem;

    & > * {
      width: 100%;
    }

    @include media-breakpoint-up(lg) {
      width: auto;
      flex-shrink: 0;
      margin: 0 0 0.5rem;
    }
  }

  //
  &__success {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2.4rem;
    @include media-breakpoint-up(lg) {
      gap: 0;
    }
  }

  &__success-area {
    @include media-breakpoint-up(lg) {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 1.6rem;
    }
  }

  &__success-title {
    width: 100%;
    color: $g-color-gray-800;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.4;
    @include media-breakpoint-up(lg) {
      width: calc(100% - 46.6rem);
      width: calc(100% - 34.6rem);
      min-width: 2.05rem;
      font-size: 1.8rem;
      padding-right: 3.2rem;
    }
  }

  &__success-number {
    flex-grow: 1;
    color: $g-color-gray-800;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.4;
    margin-top: 0.4rem;
    @include media-breakpoint-up(lg) {
      flex-grow: 0;
      font-size: 1.8rem;
      margin-top: 0;
    }

    .-unit {
      font-size: 1.2rem;
      padding-left: 0.2rem;
      @include media-breakpoint-up(lg) {
        font-size: 1.8rem;
        padding-left: 0.4rem;
      }
    }

    .-x {
      font-size: 1.2rem;
      padding-left: 0.2rem;
      padding-right: 0.2rem;
      @include media-breakpoint-up(lg) {
        font-size: 1.8rem;
        padding-left: 0.8rem;
        padding-right: 0.8rem;
      }
    }
  }

  &__success-button {
    flex-shrink: 0;
    @include media-breakpoint-up(lg) {
      padding-left: 1.2rem;
      padding-right: 1.2rem;
    }
  }

  //
  &__danger {
    width: 100%;
    &:has(.p-simulation-others__danger-text) {
      margin-bottom: 1.6rem;
    }
  }

  &__danger-text {
    color: $g-color-red;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1.4;

    @include media-breakpoint-up(lg) {
      font-size: 1.6rem;
    }
  }
}
