// ==========================================================================
// Box Card
// ==========================================================================

.c-box-card {
  width: 100%;
  border-radius: 2.4rem;
  border: 0.1rem solid $g-color-blue-150;
  padding: 2.4rem 3.2rem 2.4rem;
  overflow: hidden;

  &__textcols {
    display: flex;
    flex-direction: column;
    margin-top: 1.6rem;
    @include media-breakpoint-up(lg) {
      flex-direction: row;
    }
  }

  &__tel {
    width: 100%;
    border-top: $g-color-blue-300 solid 0.1rem;
    margin-top: 2.8rem;
    padding-top: 2.8rem;

    @include media-breakpoint-up(lg) {
      width: auto;
      flex-shrink: 0;
      border-top: none;
      border-left: $g-color-blue-300 solid 0.1rem;
      padding-top: 0;
      padding-left: 6.4rem;
      margin-top: 0;
      margin-left: 6.4rem;
    }
  }

  &__tel-heading {
    color: $g-color-black;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: 0.02em;
  }

  &__tel-area {
    display: flex;
    align-items: baseline;
    gap: 0.4rem;
    margin: 0.5rem 0;
  }

  &__tel-icon {
    width: 1.6rem;
  }

  &__tel-link {
    color: $g-color-blue;
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: 0.05em;
    text-decoration: none;
  }

  &__tel-text {
    color: $g-color-gray-500;
    font-size: 1.4rem;
    line-height: 1.6;
    letter-spacing: 0.02em;
  }
}
