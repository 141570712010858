// ==========================================================================
// Table
// ==========================================================================

.c-table-list {
  margin-top: 2.8rem;
  @include media-breakpoint-up(lg) {
    margin-top: 1.4rem;
  }

  &__wrap {
    min-width: 100%;
    caption-side: bottom;
    border-collapse: collapse;
    border-color: $g-color-blue-150;
    table-layout: fixed;
  }

  &__thead,
  &__tbody,
  &__tr,
  &__th,
  &__td {
    text-align: left;
    vertical-align: middle;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
  }

  &__thead {
    vertical-align: bottom;

    .c-table__th {
      color: $g-color-gray-800;
      text-align: center;
      font-size: 1.6rem;
      font-weight: 700;
      line-height: 1.4;
      border-bottom-width: 0.2rem;
      @include media-breakpoint-up(lg) {
        font-size: 1.8rem;
      }
    }
  }

  &__tbody {
    vertical-align: inherit;
  }

  &__th,
  &__td {
    color: $g-color-gray-800;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1.4;
    border-bottom-width: 0.1rem;
    padding: 2.4rem 0.8rem;
    // white-space: nowrap;
    @include media-breakpoint-up(lg) {
      font-size: 1.8rem;
      padding: 1.6rem 0.6rem;
    }
  }

  &__td {
    width: calc(50% - 9.6rem);
    min-width: 10rem;
    @include media-breakpoint-up(lg) {
      width: calc(50% - 10.4rem);
      min-width: 10.4rem;
    }

    &.-item {
      @include media-breakpoint-up(lg) {
        width: 15.2rem;
      }
    }

    &.-button {
      width: 9.6rem;
      padding-left: 0;
      @include media-breakpoint-up(lg) {
        width: 10.4rem;
      }
    }
  }
}
