// ==========================================================================
// Guide
// ==========================================================================

.p-price-guide {
  background-color: $g-color-blue-50;
  padding-top: 6.4rem;
  padding-bottom: 12rem;
  margin-top: 8rem;
  @include media-breakpoint-up(lg) {
    padding-top: 5rem;
    padding-bottom: 5rem;
    margin-top: 16rem;
    margin-bottom: 16rem;
  }

  &__cols {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
    @include media-breakpoint-up(lg) {
      gap: 4rem;
    }
  }

  &__col {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
    @include media-breakpoint-up(lg) {
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 4rem;
    }
  }

  &__col-img {
    margin-left: auto;
    margin-right: auto;
    @include media-breakpoint-up(lg) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  &__col-box {
    text-align: center;
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-direction: column;
      text-align: left;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
    width: 22.8rem;
    margin-left: auto;
    margin-right: auto;
    @include media-breakpoint-up(lg) {
      flex-direction: row;
      width: auto;
      justify-content: center;
      gap: 6.4rem;
    }
  }

  &__button {
    @include media-breakpoint-up(lg) {
      width: 32.5rem;
    }
  }
}
