// ==========================================================================
// Voice
// ==========================================================================

.p-voice {
  display: flex;
  flex-direction: column;
  gap: 6.4rem;
  margin-top: 7.4rem;
  @include media-breakpoint-up(lg) {
    margin-top: 10.8rem;
  }

  &__item {
    display: flex;
    flex-direction: column;
    @include media-breakpoint-up(lg) {
      flex-direction: row;
      align-items: flex-start;
      gap: 4rem;
      padding-left: 2.4rem;

      &:nth-child(even) {
        flex-direction: row-reverse;
        padding-left: 0;
        padding-right: 2.4rem;

        .p-voice__bubble {
          &::before {
            border-left: 2.8rem solid $g-color-blue-150;
            border-right: 0;
            left: auto;
            right: -2.8rem;
          }

          &::after {
            border-left: 2.8rem solid $g-color-white;
            border-right: 0;
            left: auto;
            right: -2.6rem;
          }
        }
      }
    }
  }

  &__person {
    display: flex;
    align-items: center;
    gap: 2rem;
    @include media-breakpoint-up(lg) {
      width: 22.8rem;
      flex-shrink: 0;
      flex-direction: column;
      gap: 0;
      text-align: center;
    }
  }

  &__person-figure {
    width: 9.8rem;
    flex-shrink: 0;
    @include media-breakpoint-up(lg) {
      width: 100%;
    }
  }

  &__person-figure-img {
  }

  &__person-textarea {
    @include media-breakpoint-up(lg) {
      padding: 1.8rem 0.8rem 0;
    }
  }

  &__person-heading {
    margin-bottom: 0.5rem;
  }

  &__person-text {
  }

  &__bubble {
    border-radius: 2.4rem;
    border: 0.1rem solid $g-color-blue-150;
    padding: 1.6rem 2.4rem;
    margin-top: 1.6rem;
    @include media-breakpoint-up(lg) {
      width: 100%;
      padding: 2rem 3.2rem 2.4rem;
      margin-top: 0;
      position: relative;

      &::before {
        content: "";
        border-top: 1.6rem solid transparent;
        border-bottom: 1.6rem solid transparent;
        border-right: 2.8rem solid $g-color-blue-150;
        border-left: 0;
        position: absolute;
        top: 8rem;
        left: -2.8rem;
      }

      &::after {
        content: "";
        border-top: 1.6rem solid transparent;
        border-bottom: 1.6rem solid transparent;
        border-right: 2.8rem solid $g-color-white;
        border-left: 0;
        position: absolute;
        top: 8rem;
        left: -2.6rem;
      }
    }
  }

  &__bubble-heading {
  }

  &__bubble-info {
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
    margin-top: 2rem;
    @include media-breakpoint-up(lg) {
      flex-direction: row;
      gap: 0;
    }
  }

  & &__bubble-info-text {
    @include media-breakpoint-up(lg) {
      font-size: 1.8rem;
      &:is(.p-voice__bubble-info-text + .p-voice__bubble-info-text) {
        border-left: 0.1rem solid $g-color-blue-150;
        padding-left: 1.6rem;
        margin-left: 1.6rem;
      }
    }
  }

  &__bubble-text {
    margin-top: 1.6rem;
    @include media-breakpoint-up(lg) {
      margin-top: 2.4rem;
    }
  }
}
