// ==========================================================================
// Mypage
// ==========================================================================

.p-mypage-listform {
  margin-top: 2.8rem;
  @include media-breakpoint-up(lg) {
    margin-top: 2.4rem;
  }

  &__head {
    display: none;
    @include media-breakpoint-up(lg) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 0.2rem solid $g-color-blue-150;
      padding: 1rem 0.8rem;
    }
  }

  &__head-title01,
  &__head-title02,
  &__head-title03 {
    @include media-breakpoint-up(lg) {
      color: $g-color-gray-800;
      font-size: 1.6rem;
      font-weight: 700;
      line-height: 1.4;
    }
  }

  &__head-title02 {
    @include media-breakpoint-up(lg) {
      width: 13.6rem;
      flex-shrink: 0;
      margin-left: auto;
    }
  }

  &__head-title03 {
    @include media-breakpoint-up(lg) {
      width: 22rem;
      padding-left: 0.8rem;
      flex-shrink: 0;
    }
  }

  &__body {
    display: flex;
    align-items: flex-start;
    gap: 2rem;
    border-bottom: 0.2rem solid $g-color-blue-150;
    @include media-breakpoint-up(lg) {
      align-items: center;
      border-width: 0.1rem;
      gap: 1.6rem;
    }

    &.-center {
      align-items: center;
    }
  }

  &__body-image {
    flex: 0 0 auto;
    width: calc(1 / 4 * (100% + 2rem) - 2rem);
    padding: 0.8rem;
    @include media-breakpoint-up(lg) {
      width: 9.6rem;
      padding: 1.2rem;
    }
  }

  &__body-area {
    flex: 0 0 auto;
    width: calc(3 / 4 * (100% + 2rem) - 2rem);
    @include media-breakpoint-up(lg) {
      width: calc(100% - 9.6rem - 16px);
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__body-titlearea {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    padding: 1.6rem 1.2rem 1.6rem 0;
    @include media-breakpoint-up(lg) {
      width: 100%;
      gap: 0;
      padding: 0;
    }

    &.-column {
      flex-direction: column;
      align-items: flex-start;
      gap: 2rem;
      @include media-breakpoint-up(lg) {
        flex-direction: row;
        gap: 0;
      }
    }
  }

  &__body-name {
    color: $g-color-gray-800;
    font-weight: 700;
    line-height: 1.4;
    @include media-breakpoint-up(lg) {
      display: inline-flex;
      flex-direction: column;
      gap: 0.6rem;
      padding: 0.8rem 0.8rem 0.8rem 0;
    }

    .-main {
      font-size: 1.6rem;
      @include media-breakpoint-up(lg) {
        font-size: 1.8rem;
      }
    }

    .-sub {
      font-size: 1.2rem;
      font-weight: 400;
      @include media-breakpoint-up(lg) {
        font-size: 1.4rem;
      }
    }
  }

  &__body-num {
    flex-shrink: 0;
    width: 3.8rem;
    color: $g-color-gray-800;
    font-weight: 700;
    line-height: 1.4;
    text-align: right;
    @include media-breakpoint-up(lg) {
      width: 14.6rem;
      text-align: left;
      flex-shrink: 0;
    }

    .-num {
      font-size: 1.6rem;
      @include media-breakpoint-up(lg) {
        font-size: 1.8rem;
      }
    }

    .-unit {
      font-size: 1.2rem;
      @include media-breakpoint-up(lg) {
        font-size: 1.4rem;
      }
    }
  }

  &__body-option {
    border-top: 0.1rem solid $g-color-blue-150;
    padding: 1.6rem 1.2rem;
    @include media-breakpoint-up(lg) {
      flex-shrink: 0;
      width: 22rem;
      border-top: 0;
      padding: 0;
      padding-right: 0.8rem;
    }
  }

  &__body-option-inputarea {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.6rem;

    & + .p-mypage-listform__body-option-inputarea {
      margin-top: 0.8rem;
    }
  }

  &__body-option-inputarea-number {
    display: flex;
    align-items: center;
    gap: 0.4rem;
  }

  &__body-option-inputarea-number-volume {
    width: 100%;
    @include media-breakpoint-up(lg) {
      width: auto;
    }
  }

  &__body-option-inputarea-number-volume-box {
    width: 100%;
    height: 3.2rem;
    display: flex;
    align-items: center;
    gap: 0.8rem;
    border-radius: 8px;
    border: 0.1rem solid $g-color-blue;
    padding: 0.8rem 1.6rem;
    @include media-breakpoint-up(lg) {
      width: 12.3rem;
      height: 3.8rem;
      margin-top: auto;
    }
  }

  &__body-option-inputarea-number-volume-up,
  &__body-option-inputarea-number-volume-down {
    cursor: pointer;
    width: 1.6rem;
    height: 1.6rem;
    position: relative;
    flex-shrink: 0;

    &::before,
    &::after {
      content: "";
      width: 100%;
      height: 100%;
      background-color: $g-color-blue;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }

    span {
      opacity: 0;
      visibility: hidden;
    }
  }

  &__body-option-inputarea-number-volume-down {
    margin-right: auto;

    &::before {
      height: 0.2rem;
    }

    &::after {
      content: none;
    }
  }

  &__body-option-inputarea-number-volume-up {
    margin-left: auto;

    &::before {
      height: 0.2rem;
    }

    &::after {
      width: 0.2rem;
    }
  }

  &__body-option-inputarea-number-volume-input {
    width: 4rem;
    color: $g-color-gray-800;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1.4;
    text-align: center;
    border: none;
    outline: none;
    box-shadow: none;
    background-color: $g-color-white;
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
      -moz-appearance: textfield;
    }
    @include media-breakpoint-up(lg) {
      font-size: 1.8rem;
    }
  }

  &__body-option-text {
    color: $g-color-gray-800;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.4;
    margin-bottom: 1.2rem;
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  &__body-option-text01 {
    color: $g-color-gray-800;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1.4;
    @include media-breakpoint-up(lg) {
      font-size: 1.6rem;
    }
  }

  &__body-option-unit {
    color: $g-color-gray-800;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.4;
    @include media-breakpoint-up(lg) {
      font-size: 1.4rem;
    }
  }

  //
  &.-ver01,
  &.-ver02 {
    border-top: 0.1rem solid $g-color-blue-150;
    margin-top: 4rem;

    .p-mypage-listform__body {
      border-bottom-width: 0.1rem;
      padding-top: 0.6rem;
      padding-bottom: 0.6rem;
      @include media-breakpoint-up(lg) {
        padding: 0 1.6rem;
      }
    }
    .p-mypage-listform__body-area {
      flex: 0 1 auto;
      width: auto;
      @include media-breakpoint-up(lg) {
        width: 100%;
      }
    }
    .p-mypage-listform__body-num {
      @include media-breakpoint-up(lg) {
        width: 4.5rem;
        text-align: center;
      }
    }
  }

  &.-ver01 {
    .p-mypage-listform__body-image {
      width: calc(1 / 3 * (100% + 2rem) - 2rem);
      padding: 0;
      padding-left: 1.2rem;
      @include media-breakpoint-up(lg) {
        width: 9.6rem;
        padding: 0;
      }
    }
  }

  &.-ver02 {
    .p-mypage-listform__body-image {
      padding: 0;
    }
  }
}
