// ==========================================================================
// Top
// ==========================================================================

.p-top-visit {
  border-radius: 1.6rem;
  background: $g-color-white;
  padding: 1.6rem 2.4rem 2.1rem;
  margin: 4rem 0 0;
  @include media-breakpoint-up(lg) {
    padding: 2.8rem 4rem;
    margin-top: 6.4rem;
  }

  &__heading {
    margin-bottom: 1.8rem;
  }

  &__text {
    color: $g-color-blue;
  }

  &__area {
    @include media-breakpoint-up(lg) {
      display: flex;
      align-items: center;
    }
  }

  &__tel {
    border-top: $g-color-blue-300 solid 0.1rem;
    padding-top: 1.8rem;
    margin-top: 1.8rem;
    @include media-breakpoint-up(lg) {
      flex-shrink: 0;
      border-top: none;
      border-left: $g-color-blue-300 solid 0.1rem;
      padding-top: 0;
      padding-left: 3.2rem;
      margin-top: 0;
      margin-left: 3.2rem;
    }

    @include media-breakpoint-up(xxl) {
      padding-left: 6.4rem;
      margin-left: 6.4rem;
    }
  }

  &__tel-area {
    display: flex;
    align-items: baseline;
    gap: 0.4rem;
    margin: 0.5rem 0;
  }

  &__tel-icon {
    width: 1.2rem;
    @include media-breakpoint-up(lg) {
      width: 1.6rem;
    }
  }

  &__tel-link {
    color: $g-color-blue;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: 0.05em;
    text-decoration: none;
    @include media-breakpoint-up(lg) {
      font-size: 2rem;
    }
  }

  &__tel-text {
    margin-top: 0;
  }
}
