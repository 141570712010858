// ==========================================================================
// Mypage
// ==========================================================================

.p-mypage-purchases-accordion {
  margin-top: 4.8rem;
  @include media-breakpoint-up(lg) {
    margin-top: 8rem;
  }

  &__heading {
    margin-top: 3.1rem;
    padding: 1rem 1.6rem 1rem 1.2rem;
    @include media-breakpoint-up(lg) {
      margin-top: 4rem;
      padding: 1rem 2rem 1.6rem 1.6rem;
    }
  }

  &__info {
    padding-bottom: 1.6rem;
    margin-top: 2.2rem;
    @include media-breakpoint-up(lg) {
      padding-left: 1.6rem;
      padding-right: 1.6rem;
      margin-top: 2rem;
    }

    &.-accordion2 {
      padding-bottom: 1rem;
      @include media-breakpoint-up(lg) {
        padding-bottom: 0;
      }
    }
  }

  &__content {
    padding-bottom: 0;
    @include media-breakpoint-up(lg) {
      padding-bottom: 3.5rem;
    }

    &.-accordion1 {
      padding-bottom: 0;
      @include media-breakpoint-up(lg) {
        padding-bottom: 4.1rem;
      }
    }

    &.-accordion4 {
      padding-bottom: 0;
      @include media-breakpoint-up(lg) {
        padding-bottom: 1.6rem;
      }
    }

    &.-accordion5 {
      padding-bottom: 0;
      margin-bottom: -10px;
    }
  }
}

.p-mypage-purchases-steps {
  border-radius: 2.4rem;
  margin-top: 2em;
  padding: 0 2.4rem;
  @include media-breakpoint-up(lg) {
    padding: 0 3.2rem;
  }

  &__item {
    display: flex;
    align-items: center;
    gap: 2.4rem;
    padding: 1.7rem 0;
    @include media-breakpoint-up(lg) {
      font-size: 1.4rem;
      padding: 3.2rem 0;
    }

    &:not(:last-child) {
      border-bottom: solid 0.1rem $g-color-blue-150;
    }

    &:last-child {
      padding: 2.5rem 0 2.2rem;
      @include media-breakpoint-up(lg) {
        padding-bottom: 3.2rem !important;
      }
    }
  }

  &__num {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 6.4rem;
    width: 6.4rem;
    height: 6.4rem;
    border-radius: 50%;
    border: solid 0.2rem $g-color-blue;
    color: $g-color-blue;
    font-weight: 700;
    letter-spacing: 0.02em;
    line-height: 1;
    text-transform: uppercase;

    &-step {
      font-size: 1.2rem;
    }

    &-text {
      font-size: 2.8rem;
    }
  }
}

.p-mypage-purchases-link1 {
  display: inline-block;
  margin-top: 1.1em;
  @include media-breakpoint-up(lg) {
    margin-top: 1.8rem;
  }
}

.p-mypage-purchases-grid1 {
  text-align: center;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
  margin-top: 2.5rem;
  @include media-breakpoint-up(lg) {
    flex-direction: row;
    gap: 4.8rem;
  }
}
.p-mypage-purchases-card {
  flex: 1;
  border-radius: 2.4rem;
  border: 0.1rem solid $g-color-blue-150;
  text-align: center;

  &__heading {
    border-bottom: 0.1rem solid $g-color-blue-150;
    padding: 1.7rem 2rem 1.8rem;
    @include media-breakpoint-up(lg) {
      padding: 2.5rem 2rem 2.7rem;
    }
  }

  &__body {
    padding: 2rem 3.2rem 2.1rem;
    @include media-breakpoint-up(lg) {
      padding: 2.5rem 3.2rem 2.4rem;
    }
  }

  &__body-text {
    line-height: 1.4;
  }

  &__arrow {
    width: 2rem;
    height: 2.2rem;
    display: block;
    margin: 0.9rem auto;
  }
}

.p-mypage-purchases-list {
  margin: 2.3rem 0 4.2rem;
  border-bottom: solid 0.1rem $g-color-blue-150;
  @include media-breakpoint-up(lg) {
    margin: 2.3rem 0 5.6rem;
  }

  &__item {
    display: flex;
    align-items: flex-start;
    gap: 2rem;
    border-top: solid 0.1rem $g-color-blue-150;
    padding: 2.2rem 0 2rem;
    @include media-breakpoint-up(lg) {
      gap: 3.2rem;
      padding: 2rem 1.6rem;
    }

    &.-item7 {
      padding-top: 2rem;
      padding-bottom: 2.5rem;
    }
  }

  &__item-list {
    @include media-breakpoint-up(lg) {
      font-size: 1.6rem;
    }
  }

  &__img {
    width: 28.9%;
    min-width: 28.9%;
    height: auto;
    margin-top: 0.3rem;
    @include media-breakpoint-up(lg) {
      width: 19.8%;
      min-width: 19.8%;
    }
  }

  &__small {
    color: $g-color-gray-500;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.6;
    letter-spacing: 0.02em;
  }

  &__box1 {
    display: flex;
    flex-wrap: wrap;
    gap: 0;
    @include media-breakpoint-up(lg) {
      gap: 4rem;
      margin-top: 0.8rem;
    }

    > * {
      width: 100%;
      height: auto;
      margin: 1.6rem 0 0;
      @include media-breakpoint-up(lg) {
        width: 19.2rem;
        margin: 2.4rem 0 0.4rem;
      }
    }
  }
}

.p-mypage-purchases-list2 {
  .p-mypage-purchases-list__img {
    width: 28.9%;
    min-width: 28.9%;

    @include media-breakpoint-up(lg) {
      width: 19%;
      min-width: 19%;
      margin-top: 0.8rem;
    }
  }
  .p-mypage-purchases-list__item {
    border: none;
    padding: 2.2rem 0 0;
    gap: 2rem;
    @include media-breakpoint-up(lg) {
      padding: 1.5rem 0 0;
      gap: 2.5rem;
    }
  }
}

// Confirm Section
.p-mypage-purchases-confirm {
  padding-top: 3.9rem;
  padding-bottom: 0;
  @include media-breakpoint-up(lg) {
    padding-top: 5.8rem;
    padding-bottom: 18.9rem;
  }
  .p-mypage-confirm-step-card {
    margin-top: 3.4rem;
    @include media-breakpoint-up(lg) {
      margin-top: 4.3rem;
    }
  }
  .p-mypage-purchases-steps__item:last-child {
    padding: 2.5rem 0 1.5rem;
  }
}

.p-mypage-purchases-confirm__box1 {
  display: block;
  padding: 1.5rem 2.3rem 2.2rem;
  @include media-breakpoint-up(lg) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2.8rem 3.2rem;
    gap: 3.2rem;
  }

  > * {
    flex: 1;
  }
  .p-mypage-card__plan-price {
    margin: 0;
    @include media-breakpoint-up(lg) {
      margin: 0 0.8rem;
    }
  }
}

.p-mypage-confirm-step-card {
  padding: 1.4rem 2.4rem 0.9rem;
  @include media-breakpoint-up(lg) {
    padding: 1.8rem 3.2rem 0;
  }

  .p-mypage-purchases-steps {
    margin-top: 0;
    padding: 0;
    > li:first-child {
      border-top: solid 0.1rem $g-color-blue-150;
    }
  }
}
