// ==========================================================================
// Mypage
// ==========================================================================

.p-mypage-card-option {
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
  margin-top: 2.8rem;

  &__item {
    border: 0.1rem solid $g-color-blue-150;
    border-radius: 1.6rem;
    padding: 2.4rem 2.4rem 3.2rem;
    overflow: hidden;
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-direction: row-reverse;
      align-items: flex-start;
      gap: 3.2rem;
      padding: 3.2rem 3.2rem 4rem;
    }
  }

  &__image {
    border-radius: 1.6rem;
    margin-bottom: 1.8rem;
    overflow: hidden;
    @include media-breakpoint-up(lg) {
      width: 228px;
      width: calc(228 / 746 * 100%);
      flex-shrink: 0;
      margin-bottom: 0;
    }
  }

  &__indent {
    margin-top: 1.25em;
    @include media-breakpoint-up(lg) {
      margin-top: 1.111rem;
    }
  }

  &__indent01 {
    margin-top: 1.25em;
  }
}
