// ==========================================================================
// Cmplete
// ==========================================================================

.c-complete {
  @include media-breakpoint-up(lg) {
    max-width: 67.5rem;
    margin-left: auto;
    margin-right: auto;
  }

  &__heading {
    margin-top: 6.4rem;
    @include media-breakpoint-up(lg) {
      margin-top: 13rem;
    }
  }

  &__text {
    margin-top: 2.4rem;
    @include media-breakpoint-up(lg) {
      margin-top: 2rem;
    }
  }

  &__button {
    margin-top: 3.2rem;
  }

  &__info {
    border-radius: 2.4rem;
    border: 0.1rem solid $g-color-blue-150;
    padding: 2.4rem 2.4rem 2.8rem;
    margin-top: 4.8rem;
    @include media-breakpoint-up(lg) {
      padding: 2.4rem 3.2rem 2.8rem;
      margin-top: 6.4rem;
    }
  }

  &__info-tel {
    display: inline-flex;
    gap: 0.8rem;
    margin-top: 2rem;
    margin-bottom: 1.4rem;
  }

  &__info-tel-icon {
    width: 1.2rem;
    flex-shrink: 0;
    @include media-breakpoint-up(lg) {
      width: 1.6rem;
    }
  }

  &__info-tel-link {
    color: $g-color-blue;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: 0.05em;
    text-decoration: none;
    @include media-breakpoint-up(lg) {
      font-size: 2rem;
    }
  }

  &__info-tel-text {
    margin-top: 0;
    margin-left: 2rem;
    @include media-breakpoint-up(lg) {
      margin-left: 2.4rem;
    }
  }
}
