// ==========================================================================
// Margin
// ==========================================================================

@mixin utilities($media, $name, $value) {
  $keyword: if($media != null, "-" + $media, "");

  .u-mt-#{$name}#{$keyword},
  .u-my-#{$name}#{$keyword} {
    margin-top: $value !important;
  }

  .u-mb-#{$name}#{$keyword},
  .u-my-#{$name}#{$keyword} {
    margin-bottom: $value !important;
  }

  .u-ml-#{$name}#{$keyword},
  .u-mx-#{$name}#{$keyword} {
    margin-left: $value !important;
  }

  .u-mr-#{$name}#{$keyword},
  .u-mx-#{$name}#{$keyword} {
    margin-right: $value !important;
  }

  .u-m-#{$name}#{$keyword} {
    margin: $value !important;
  }
}

@mixin init-utilities($media: null) {
  @include utilities($media, auto, auto);

  @for $i from $utility-margin-for-start through $utility-margin-for-end {
    $name: ($i * $utility-margin-n);
    $value: ($i * $utility-margin-n-value);

    @include utilities($media, $name, $value);
  }
}

@include init-utilities();
@each $media-name, $media-width in $g-breakpoint {
  @include media-breakpoint-up($media-name) {
    @include init-utilities($media-name);
  }
}
