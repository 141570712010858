// ==========================================================================
// Guide
// ==========================================================================

.p-guide-card {
  border-top: 0.4rem solid $g-color-yellow;
  margin-left: -2rem;
  margin-right: -2rem;
  @include media-breakpoint-up(lg) {
    border-width: 0.8rem;
    display: flex;
    flex-direction: column;
    height: 100%;
    border-radius: 0 0 2.4rem 2.4rem;
    border-right: 0.1rem solid $g-color-blue-150;
    border-bottom: 0.1rem solid $g-color-blue-150;
    border-left: 0.1rem solid $g-color-blue-150;
    margin: 0;
  }

  &__inner {
    padding: 3.2rem 2rem 0;
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 3.2rem 4rem 4rem;
    }
  }

  &__img {
    height: 8rem;
    margin: 0 auto 1.5rem;
    @include media-breakpoint-up(lg) {
      margin-bottom: 1.8rem;
    }
  }

  &__heading {
    text-align: center;
    margin-bottom: 3.2rem;
    @include media-breakpoint-up(lg) {
      margin-bottom: 2.8rem;
    }
  }

  &__text {
    margin-bottom: 2.6rem;
    @include media-breakpoint-up(lg) {
      margin-bottom: 2.2rem;
    }
  }

  &__button {
    margin-top: auto;
  }
}
