// ==========================================================================
// Button
// ==========================================================================

.c-button {
  --button-color: #{$g-color-white};
  --button-font-size: 1.8rem;
  --button-border-radius: 1.6rem;
  --button-background-color: #{$g-color-blue};
  --button-padding: 1.8rem 2.4rem;
  --button-outline-color: #{$g-color-blue};
  box-shadow: 0 0 0 0.2rem $g-color-blue inset;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  color: var(--button-color);
  border-radius: var(--button-border-radius);
  background-color: var(--button-background-color);
  text-decoration: none;
  vertical-align: bottom;
  text-align: center;
  padding: var(--button-padding);
  box-shadow: 0 0 0 0.2rem var(--button-outline-color) inset;
  transition: color 300ms, background-color 300ms, box-shadow 300ms;

  @include media-breakpoint-up(lg) {
    --button-font-size: 2rem;
    --button-padding: 2.2rem 2.8rem;
  }

  @media (any-hover: hover) {
    &:hover {
      --button-color: #{$g-color-white};
      --button-background-color: #{$g-color-blue-150};
      --button-outline-color: #{$g-color-blue-150};
      opacity: 1;
    }
  }

  &__text {
    font-size: var(--button-font-size);
    font-weight: 700;
    line-height: 1.4;
  }

  &.-outline {
    --button-color: #{$g-color-blue};
    --button-background-color: #{$g-color-white};
    @media (any-hover: hover) {
      &:hover {
        --button-color: #{$g-color-white};
        --button-background-color: #{$g-color-blue-150};
        --button-outline-color: #{$g-color-blue-150};
      }
    }
  }

  &.-size-sm {
    --button-font-size: 1.4rem;
    --button-border-radius: 1.2rem;
    --button-padding: 0.7rem 1.6rem;
    @include media-breakpoint-up(lg) {
      --button-font-size: 1.6rem;
      --button-padding: 0.7rem 1.6rem;
    }
  }

  &.-size-lg {
    --button-font-size: 2.2rem;
    --button-padding: 2.1rem 3.2rem;
    @include media-breakpoint-up(lg) {
      --button-font-size: 2.4rem;
      --button-padding: 3.2rem 4rem;
    }
  }

  &.-only-size-lg {
    @include media-breakpoint-up(lg) {
      --button-font-size: 2.4rem;
      --button-padding: 3.2rem 4rem;
    }
  }

  &.-disabled,
  &:disabled {
    --button-color: #{$g-color-white};
    --button-background-color: #{$g-color-gray-200};
    --button-outline-color: #{$g-color-gray-200};
    pointer-events: none;
  }

  &.-disabled-outline {
    --button-color: #{$g-color-gray-450};
    --button-background-color: #{$g-color-white};
    --button-outline-color: #{$g-color-gray-450};
    font-weight: 700;
    pointer-events: none;
  }

  &.-size-228 {
    width: 22.8rem;
  }

  &.-size-240 {
    width: 21.4rem;
    @include media-breakpoint-up(lg) {
      width: 24rem;
    }
  }

  &.-danger.-outline {
    --button-color: #{$g-color-red};
    --button-background-color: #{$g-color-white};
    --button-outline-color: #{$g-color-red};
    @media (any-hover: hover) {
      &:hover {
        --button-color: #{$g-color-white};
        --button-background-color: #{$g-color-red-200};
        --button-outline-color: #{$g-color-red-200};
      }
    }
  }

  &.-danger:not(.-outline) {
    --button-color: #{$g-color-white};
    --button-background-color: #{$g-color-red};
    --button-outline-color: #{$g-color-red};
    @media (any-hover: hover) {
      &:hover {
        --button-color: #{$g-color-white};
        --button-background-color: #{$g-color-red-200};
        --button-outline-color: #{$g-color-red-200};
      }
    }
  }
}
