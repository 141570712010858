// -----------------------------------------------------------------
// Global Token
// -----------------------------------------------------------------

//------ Layout -------//

// breakpoint
$g-breakpoint: (
  sm: 393px,
  // md: 768px,
  lg: 1024px,
  xl: 1280px,
  xxl: 1920px,
);

// max-width
$g-max-width: (
  none: none,
  // sm: 96rem,
  // md: 128rem,
  lg: 160rem,
);

// container-padding
$g-container-padding: (
  zero: 0,
  md: 2rem,
);

// header
// $g-header-height: (
//   sm: 6rem,
//   md: 8rem,
//   lg: 10.8rem,
// );

// $g-header-height-scrolled: (
//   sm: map-get($g-header-height, "sm"),
//   md: map-get($g-header-height, "md"),
//   lg: 6.6rem,
// );

// form
// $g-form-width: (
//   xs: 5em,
//   sm: 12em,
//   md: 17em,
//   lg: 22em,
//   xl: 36em,
// );

// $g-field-group-spacing: (
//   zero: 0,
//   xs: 0.375em,
//   sm: 0.625em,
//   md: 1em,
//   lg: 1.5em,
//   xl: 2em,
// );

//------ Columns -------//

$g-grid-columns: 12;
$g-grid-gutters: (
  0: 0rem,
  // 1: 0.6rem,
  // 2: 1.6rem,
  // 3: 2.4rem,
  // 4: 3.6rem,
  // 5: 4.6rem,
  // 6: 5.5rem,,,,,,
);

//------ Z-index -------//

$g-zindex: (
  header-menu: 1000,
  header-menu-hover: 1010,
  header-register: 1100,
  header-hamburger: 1120,
  header-hamburger-menu: 1110,
  mypage-calendar: 950,
  mypage-button: 900,
);

//------ Font -------//

// font-family
$g-font-family-sans-serif: "Noto Sans JP", sans-serif;
$g-font-family-roboto-serif: "Roboto", sans-serif;

// font-size
// $g-font-size-html: 62.5%;
// $g-font-size-body: 1.6rem;

// font-weight
// $g-font-weight-thin: 100;
// $g-font-weight-extra-light: 200;
// $g-font-weight-light: 300;
// $g-font-weight-normal: 400;
// $g-font-weight-medium: 500;
// $g-font-weight-semi-bold: 600;
// $g-font-weight-bold: 700;
// $g-font-weight-extra-bold: 800;
// $g-font-weight-black: 900;

// line-height
// $g-line-height-100: 1;
// $g-line-height-110: 1.1;
// $g-line-height-120: 1.2;
// $g-line-height-125: 1.25;
// $g-line-height-130: 1.3;
// $g-line-height-140: 1.4;
// $g-line-height-150: 1.5;
// $g-line-height-160: 1.6;
// $g-line-height-170: 1.7;
// $g-line-height-175: 1.75;
// $g-line-height-180: 1.8;
// $g-line-height-190: 1.9;
// $g-line-height-200: 2;

//------ Color -------//

// black
$g-color-black: #000;

// white
$g-color-white: #fff;

// brand
$g-color-blue: #11318f;
$g-color-blue-500: #415aa5;
$g-color-blue-400: #7083bc;
$g-color-blue-300: #94a8e3;
$g-color-blue-200: #a0add2;
$g-color-blue-150: #c3cbe3;
$g-color-blue-100: #cfd6e9;
$g-color-blue-70: #f2f6ff;
$g-color-blue-50: #f0f3fa;

// $g-color-brand-100: #edf6fa;
// $g-color-brand-200: #bee5ea;
// $g-color-brand-250: #d1eaf3;
// $g-color-brand-270: #b6cccf;
// $g-color-brand-300: #9cd9e2;
// $g-color-brand-400: #37a5de;

// $g-color-brand-900: #283638;

// border-radius: 4px;
// background: var(--color-Blue, #);

// gray
$g-color-gray: #545454;
$g-color-gray-800: #404040;
$g-color-gray-500: #737373;
$g-color-gray-450: #808080;
$g-color-gray-200: #d9d9d9;
$g-color-gray-100: #e5e3e3;
$g-color-gray-80: #e6e6e6;

// $g-color-gray-50: #ebebeb;
// $g-color-gray-100: #f5f5f5;
// $g-color-gray-150: #ebebeb;
// $g-color-gray-200: #e3e3e3;
// $g-color-gray-300: #cfcfcf;
// $g-color-gray-400: #b7b7b7;
// $g-color-gray-500: #9d9d9d;
// $g-color-gray-550: #888888;
// $g-color-gray-600: #848484;
// $g-color-gray-700: #696969;
// $g-color-gray-800: #333333;
// $g-color-gray-900: #222222;

// red
$g-color-red: #cc4818;
// $g-color-red-100: #fef3f3;
$g-color-red-200: #f5f1f0;
// $g-color-red-300: #fac1c1;
// $g-color-red-400: #f7a0a0;
// $g-color-red-500: #f37878;
// $g-color-red-600: #ef4747;
// $g-color-red-700: #d01726;
// $g-color-red-800: #6a0c14;
// $g-color-red-900: #48080d;

// blue
// $g-color-blue-100: #ebf7fe;
// $g-color-blue-200: #c9e9fc;
// $g-color-blue-300: #9dd6fa;
// $g-color-blue-400: #68c0f7;
// $g-color-blue-500: #28a5f3;
// $g-color-blue-600: #0d85f2;
// $g-color-blue-700: #0b67ca;
// $g-color-blue-800: #084c94;
// $g-color-blue-900: #053160;

// yellow
$g-color-yellow: #fae26a;
$g-color-yellow-200: #fff5bf;

//Green
$g-color-green-500: #c9ec94;

// $g-color-yellow-100: #fbf6db;
// $g-color-yellow-200: #f3e592;
// $g-color-yellow-300: #eacf3b;
// $g-color-yellow-400: #d5b506;
// $g-color-yellow-500: #b69c12;
// $g-color-yellow-600: #98841d;
// $g-color-yellow-700: #77681c;
// $g-color-yellow-800: #3b330e;
// $g-color-yellow-900: #272209;

// brand-accent
// $g-color-brand-accent: #146d8a;
// $g-color-brand-accent-100: #eff7fa;
// $g-color-brand-accent-200: #d0e7ef;
// $g-color-brand-accent-300: #aed5e3;
// $g-color-brand-accent-400: #84c0d4;
// $g-color-brand-accent-500: #55a8c4;
// $g-color-brand-accent-600: #258fb4;
// $g-color-brand-accent-700: #127191;
// $g-color-brand-accent-800: #0a3848;
// $g-color-brand-accent-900: #07252f;

// facebook
// $g-color-thirdparty-facebook: #1877f2;

// twitter
// $g-color-thirdparty-twitter: #1d9bf0;

// line
// $g-color-thirdparty-line: #06c755;

// -----------------------------------------------------------------
// Alias Token
// -----------------------------------------------------------------

// 背景のデフォルト色
// $a-color-background-light: $g-color-white;
// $a-color-background-dark: $g-color-black;
// $a-color-background-default: $a-color-background-light;
// // $a-color-background-default: $a-color-background-dark;

// 文字のデフォルト色
// $a-color-text-light: $g-color-gray-900;
// $a-color-text-dark: $g-color-white;
// $a-color-text-default: $a-color-text-light;
// $a-color-text-default: $a-color-text-dark;

// リンク色
// $a-color-text-link-default: $g-color-blue-700;
// $a-color-text-link-hover: $g-color-blue-800;

// フォーム系配色
// $a-color-placeholder: $g-color-gray-300;
// $a-color-checked: $g-color-blue-500;
// $a-color-disabled-background: $g-color-gray-200;
// $a-color-disabled-text: $g-color-gray-400;
// $a-color-focus: $g-color-blue-600;
// $a-color-success: $g-color-blue-600;
// $a-color-danger: $g-color-red-600;

// Typography
// $a-typography: (
//   "heading-1": (
//     values: (
//       "font-size": 6rem,
//       "line-height": $g-line-height-120,
//       "font-weight": $g-font-weight-normal,
//     ),
//     breakpoint: (
//       md: (
//         "font-size": 6rem,
//         "line-height": $g-line-height-120,
//       ),
//     ),
//   ),
//   "heading-2": (
//     values: (
//       "font-size": 5rem,
//       "line-height": $g-line-height-120,
//       "font-weight": $g-font-weight-normal,
//     ),
//     breakpoint: (
//       md: (
//         "font-size": 5rem,
//         "line-height": $g-line-height-120,
//       ),
//     ),
//   ),
//   "heading-3": (
//     values: (
//       "font-size": 3.2rem,
//       "line-height": $g-line-height-140,
//       "font-weight": $g-font-weight-normal,
//     ),
//     breakpoint: (
//       md: (
//         "font-size": 3.2rem,
//         "line-height": $g-line-height-140,
//       ),
//     ),
//   ),
//   "heading-4": (
//     values: (
//       "font-size": 2.4rem,
//       "line-height": $g-line-height-130,
//       "font-weight": $g-font-weight-normal,
//     ),
//     breakpoint: (
//       md: (
//         "font-size": 2.4rem,
//         "line-height": $g-line-height-130,
//       ),
//     ),
//   ),
//   "small-text": (
//     values: (
//       "font-size": 1.4rem,
//       "line-height": $g-line-height-180,
//     ),
//     breakpoint: (
//       md: (
//         "font-size": 1.4rem,
//         "line-height": $g-line-height-180,
//       ),
//     ),
//   ),
// );

/* ---------------------------------------------------------
 Grid
--------------------------------------------------------- */
$grid-max-width: 1112px;
$grid-columns: 12;

$grid-media-min-widths: (
  tab: 744px,
  pc: 1080px,
);

$grid-cols: (12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1);

$grid-columns-sp: 2;

$grid-columns-sp01: 4;

$grid-gutter: (
  sp: 2rem,
  tab: 4.8rem,
  pc: 6.4rem,
);

// -----------------------------------------------------------------
// Utility
// -----------------------------------------------------------------

// font-size
$utility-font-size-for-start: 10; //for文の開始値
$utility-font-size-for-end: 30; //for文の終了値
$utility-font-size-n: 0.1rem; //n数指定
$utility-font-size-em: (
  xs: 0.8,
  sm: 0.9,
  md: 1,
  lg: 1.1,
  xl: 1.2,
);

// font-weight
$utility-font-weight: (
  "400": 400,
  "700": 700,
);

// line-height
$utility-line-height-for-start: 100; //for文の開始値
$utility-line-height-for-end: 200; //for文の終了値
$utility-line-height-n: 0.01; //n数指定

// margin
$utility-margin-for-start: 0; //for文の開始値
$utility-margin-for-end: 20; //for文の終了値
$utility-margin-n: 5; //n数指定
$utility-margin-n-value: 0.5rem; //n数毎の値指定

// padding
$utility-padding-for-start: 0; //for文の開始値
$utility-padding-for-end: 20; //for文の終了値
$utility-padding-n: 5; //n数指定
$utility-padding-n-value: 0.5rem; //n数毎の値指定

// spacing
$utility-spacing-for-start: 0; //for文の開始値
$utility-spacing-for-end: 23; //for文の終了値
$utility-spacing-n: 8; //n数指定
$utility-spacing-n-value: 0.8rem; //n数毎の値指定

// Color
$utility-color: (
  "black": (
    values: (
      "black": $g-color-black,
    ),
  ),
  "white": (
    values: (
      "white": $g-color-white,
    ),
  ),
  "blue": (
    values: (
      "blue": $g-color-blue,
      "blue-50": $g-color-blue-50,
    ),
  ),
  "red": (
    values: (
      "red": $g-color-red,
    ),
  ),
  "yellow": (
    values: (
      "yellow": $g-color-yellow,
    ),
  ),
  "green": (
    values: (
      "green-500": $g-color-green-500,
    ),
  ),
  "gray": (
    values: (
      "gray-80": $g-color-gray-80,
    ),
  ),
);
