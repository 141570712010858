// ==========================================================================
// flex
// ==========================================================================

// @mixin utilities($media) {
//   $keyword: if($media != null, "-" + $media, "");

//   .u-flex-wrap#{$keyword} {
//     flex-wrap: wrap !important;
//   }

//   .u-flex-wrap-reverse#{$keyword} {
//     flex-wrap: wrap-reverse !important;
//   }

//   .u-flex-wrap-nowrap#{$keyword} {
//     flex-wrap: nowrap !important;
//   }
// }

// @include utilities(null);
// @each $media-name, $media-width in $g-breakpoint {
//   @include media-breakpoint-up($media-name) {
//     @include utilities($media-name);
//   }
// }

.u-flex-shrink-0 {
  flex-shrink: 0;
}

.u-flex-shrink-none {
  flex-shrink: 1;
}
