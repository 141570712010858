// ==========================================================================
// Hero
// ==========================================================================

.c-hero {
  display: flex;
  flex-direction: column;
  margin-top: 8rem;
  margin-bottom: 7.3rem;
  @include media-breakpoint-up(lg) {
    flex-direction: row;
    align-items: flex-start;
    gap: 8rem;
    margin-top: 12rem;
    margin-bottom: 12rem;
  }

  &.-ver01 {
    @include media-breakpoint-up(lg) {
      align-items: center;
    }
  }

  &__area {
    margin-top: 2.5rem;
    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }
  }

  &__img {
    width: 16rem;
    flex-shrink: 0;
    margin-left: auto;
    margin-right: auto;
    @include media-breakpoint-up(lg) {
      margin: 0;
    }
  }

  & &__heading {
    text-align: center;
    margin-top: 0;
    margin-bottom: 3.4rem;
    @include media-breakpoint-up(lg) {
      text-align: left;
      margin-top: -2.4rem;
      margin-bottom: 3rem;
    }
  }
}
