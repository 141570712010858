// ==========================================================================
// Text
// ==========================================================================

@mixin utilities($media) {
  $keyword: if($media != null, "-" + $media, "");

  // Text-Align
  // ==========================================================================
  .u-text-align-left#{$keyword} {
    text-align: left !important;
  }

  .u-text-align-center#{$keyword} {
    text-align: center !important;
  }

  .u-text-align-right#{$keyword} {
    text-align: right !important;
  }

  // text-decoration
  // ========================================================================== */
  .u-text-decoration-none {
    text-decoration: none !important;
  }

  .u-text-decoration-underline {
    text-decoration: underline !important;
  }
}

@include utilities(null);
@each $media-name, $media-width in $g-breakpoint {
  @include media-breakpoint-up($media-name) {
    @include utilities($media-name);
  }
}
