// ==========================================================================
// plan-price
// ==========================================================================

.p-plan-price-card {
  border-radius: 1.6rem;
  border: 0.1rem solid $g-color-blue-150;
  overflow: hidden;
  @include media-breakpoint-up(lg) {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__body {
    padding: 2.8rem 2.4rem 2.4rem;
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }

  &__body-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2.4rem;
    @include media-breakpoint-up(lg) {
      flex-direction: column;
      gap: 1.4rem;
    }
  }

  &__body-main-button {
    flex-shrink: 0;
  }

  &__text {
    @include media-breakpoint-up(lg) {
      margin-bottom: 1rem;
    }
  }

  &__price-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
    @include media-breakpoint-up(lg) {
      margin-top: auto;
    }
  }

  &__price-before {
    text-align: center;

    .-title {
      display: block;
      color: $g-color-gray-800;
      font-size: 1.6rem;
      font-weight: 700;
      line-height: 1.4;
      text-align: center;
    }

    .-through {
      display: block;
      text-decoration: line-through;
    }

    .-num {
      color: $g-color-gray;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.6;
      letter-spacing: 0.02em;
      text-align: center;
    }

    .-unit,
    .-tax {
      color: $g-color-gray;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.6;
      letter-spacing: 0.02em;
      text-align: center;
    }
  }

  &__price-after {
    text-align: center;
    margin-top: 1.6rem;

    .-title {
      display: block;
      color: $g-color-blue;
      font-size: 1.6rem;
      font-weight: 700;
      line-height: 1;
      letter-spacing: 0.05em;
      margin-bottom: 0.8rem;

      span {
        font-size: 0.5em;
        vertical-align: top;
      }
    }

    .-border {
      display: inline-block;
      padding-left: 0.5em;
      padding-bottom: 1rem;
      position: relative;
      &::before,
      &::after {
        display: block;
        content: "";
        width: 100%;
        height: 0.3rem;
        background: $g-color-yellow;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }

      &::before {
        bottom: 0.5rem;
      }
    }

    .-num {
      color: $g-color-blue;
      font-size: 2.6rem;
      font-weight: 700;
      line-height: 1.4;
    }

    .-unit {
      color: $g-color-blue;
      font-size: 1.2rem;
      font-weight: 700;
      line-height: 1.4;
    }
  }

  &__price {
    color: $g-color-black;
    font-weight: 700;
    line-height: 1.4;
    text-align: center;
    margin: 1.8rem 0 2.8rem;

    @include media-breakpoint-up(lg) {
      margin-top: auto;
    }

    .-title {
      display: block;
      font-size: 1.6rem;
      margin-bottom: 0.3rem;

      @include media-breakpoint-up(lg) {
        font-size: 1.8rem;
        margin-bottom: 0;
      }
    }

    .-num {
      font-size: 2.2rem;
      font-weight: 500;
      @include media-breakpoint-up(lg) {
        font-size: 2.4rem;
        margin-bottom: 0;
      }
    }

    .-unit {
      font-size: 1.6rem;
      font-weight: 500;
      @include media-breakpoint-up(lg) {
        font-size: 1.8rem;
      }
    }

    .-tax {
      display: block;
      font-size: 1.4rem;
      font-weight: 400;

      @include media-breakpoint-up(lg) {
        font-size: 1.6rem;
      }
    }
  }
}
