// ==========================================================================
// Aside
// ==========================================================================

.l-aside {
  display: none;
  @include media-breakpoint-up(lg) {
    display: block;
  }

  &__wrap {
    margin-top: 2.8rem;
  }

  &__logo {
    display: block;
    width: 100%;
    padding: 1.1rem 1.2rem;
  }

  &__logo-img {
    width: 100%;
  }

  &__nav {
    border-top: 0.1rem solid $g-color-blue-150;
    margin-top: 2.8rem;
  }

  &__nav-lists {
  }

  &__nav-list {
    padding: 1.4rem 0;

    & + .l-aside__nav-list,
    & + .l-aside__nav-smalllist {
      border-top: 0.1rem solid $g-color-blue-150;
    }
  }

  &__nav-list-link,
  &__nav-list-text {
    width: 100%;
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    gap: 0.8rem;
    color: $g-color-blue;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1.2;
    border-radius: 0.8rem;
    padding: 0.6rem 1.2rem;
  }

  &__nav-list-svg {
    width: 1.4rem;
    height: 1.6rem;
    flex-shrink: 0;
  }

  &__nav-sublists {
    margin-top: 1.2rem;
  }

  &__nav-sublist {
    margin-left: 1.6rem;
    margin-right: 1.6rem;

    & + .l-aside__nav-sublist {
      margin-top: 0.4rem;
    }
  }

  &__nav-sublist-link {
    width: 100%;
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    color: $g-color-blue;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1.2;
    border-radius: 0.8rem;
    padding: 0.8rem 1.2rem;
  }

  &__nav-smalllist {
    padding-top: 1.6rem;
    & + .l-aside__nav-smalllist {
      margin-top: 0.8rem;
      padding-top: 0;
    }
  }

  &__nav-smalllist-link {
    width: 100%;
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    color: $g-color-blue;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1.2;
    border-radius: 0.8rem;
    padding: 0.8rem 1.2rem;
  }

  &__buttons {
    border-top: 0.1rem solid $g-color-blue-150;
    border-bottom: 0.1rem solid $g-color-blue-150;
    padding-top: 2.4rem;
    padding-bottom: 2.2rem;
    margin-top: 1.6rem;
  }

  &__button {
    width: 100%;
  }

  &__logout {
    display: inline-flex;
    align-items: center;
    gap: 0.6rem;
    margin-top: 2.4rem;
  }

  &__logout-text {
    color: $g-color-blue;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1.2;
  }

  &__nav-list-link,
  &__nav-sublist-link,
  &__nav-smalllist-link {
    transition: background-color 300ms;

    &.-active {
      background-color: $g-color-yellow;
    }
    @media (any-hover: hover) {
      &:hover {
        background-color: $g-color-yellow;
        opacity: 1;
      }
    }
  }
}
