// ==========================================================================
// Mypage
// ==========================================================================

.p-mypage-item-list {
  display: flex;
  flex-direction: column;
  margin-top: 2.6rem;

  @include media-breakpoint-up(lg) {
    flex-direction: row;
    gap: 2.4rem;
    margin-top: 6.8rem;
  }

  &__item {
    @include media-breakpoint-up(lg) {
      flex: 0 0 auto;
      width: 18.5rem;

      &:first-child {
        width: calc(100% - 41.8rem);
      }
    }
  }

  &__item-cols {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    @include media-breakpoint-up(lg) {
      flex-direction: row;
      flex-wrap: wrap;
      gap: 0 2.5rem;
    }

    .p-mypage-item-list__item-text {
      margin-bottom: 0;
      @include media-breakpoint-up(lg) {
        flex: 0 0 auto;
        width: 100%;
        margin-top: 0;
        margin-bottom: 1.2rem;
      }
    }
  }

  &__item-col {
    @include media-breakpoint-up(lg) {
      flex: 0 0 auto;
      width: 18.5rem;
    }
  }

  &__item-text {
    color: $g-color-black;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: 0.02em;
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;

    @include media-breakpoint-up(lg) {
      font-size: 1.4rem;
    }
  }
}
