// ==========================================================================
// Old
// ==========================================================================

.p-old {
  .amount_box__num {
    line-height: 1;
    padding-bottom: 5px;

    input {
      &:focus,
      &:focus-visible {
        border: none;
        outline: none;
        box-shadow: none;
      }
    }
  }
  .modal__overlay {
    z-index: 9999;
  }
}
