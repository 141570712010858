// ==========================================================================
// Flexbox
// ==========================================================================

@mixin utilities($media) {
  $keyword: if($media != null, "-" + $media, "");

  // .u-align-items-flex-start#{$keyword} {
  //   align-items: flex-start !important;
  // }

  .u-align-items-center#{$keyword} {
    align-items: center !important;
  }

  // .u-align-items-flex-end#{$keyword} {
  //   align-items: flex-end !important;
  // }

  // .u-align-items-baseline#{$keyword} {
  //   align-items: baseline !important;
  // }

  // .u-align-items-stretch#{$keyword} {
  //   align-items: stretch !important;
  // }
}

@include utilities(null);
@each $media-name, $media-width in $g-breakpoint {
  @include media-breakpoint-up($media-name) {
    @include utilities($media-name);
  }
}
