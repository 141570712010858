// ==========================================================================
// Temporary-storage
// ==========================================================================

.p-temporary-storage-box {
  border: 0.1rem solid $g-color-blue-150;
  border-radius: 2.4rem;
  padding: 2.4rem;
  @include media-breakpoint-up(lg) {
    padding: 3.2rem;
  }

  &__area {
    display: flex;
    flex-direction: column;
    @include media-breakpoint-up(lg) {
      flex-direction: row;
      gap: 3.2rem;
      align-items: center;
    }
  }

  &__area-img {
    width: 12rem;
    margin-left: auto;
    margin-right: auto;
    @include media-breakpoint-up(lg) {
      width: 14rem;
    }
  }

  &__textarea {
    margin-top: 2.4rem;
    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }
  }

  &__lists {
    border-radius: 1.6rem;
    background-color: $g-color-blue-50;
    padding: 1.6rem 2.4rem;
    margin-top: 1.6rem;
    @include media-breakpoint-up(lg) {
      margin-top: 3.2rem;
    }
  }

  &__list {
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-wrap: wrap;
      gap: 0.8rem 0;
    }
  }

  &__list-item {
    @include media-breakpoint-up(lg) {
      width: calc(3 / 12 * (100%));
      flex: 0 0 auto;
      font-size: 1.6rem;
      margin-top: 0 !important;
    }
  }
}
