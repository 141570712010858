// ==========================================================================
// Text Field
// ==========================================================================

.c-text-field {
  &__input {
    width: 100%;
    color: $g-color-black;
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.02em;
    background: $g-color-white;
    border: 0.1rem solid $g-color-blue;
    border-radius: 1.6rem;
    padding: 0.695em 1.111em;
    vertical-align: bottom;
    @include media-breakpoint-up(lg) {
      font-size: 2rem;
      padding: 0.8em 1.6em;
    }

    &::placeholder {
      color: $g-color-gray-450;
      font-size: 1.6rem;
      @include media-breakpoint-up(lg) {
        font-size: 1.8rem;
      }
    }

    &:placeholder-shown {
      color: $g-color-gray-450;
      // font-size: 1.6rem;
      background: $g-color-blue-50;
      border: 0.1rem solid $g-color-blue-50;
    }

    &:focus {
      border-color: $g-color-blue;
      background: $g-color-white;
      box-shadow: 0px 0px 0px 4px rgba(17, 49, 143, 0.13) inset;
    }

    &:focus-visible {
      border-color: $g-color-blue;
      background: $g-color-white;
      outline: none;
    }

    &:is(:-webkit-autofill, :autofill) {
      font-size: 1.8rem;
      box-shadow: 0 0 0 1000px white inset;
      transition: background-color 5000s ease-in-out 0s;
      @include media-breakpoint-up(lg) {
        font-size: 2rem;
      }
    }

    &.-size-sm {
      border-color: $g-color-blue-150;
      @include media-breakpoint-up(lg) {
        padding: 0.8rem 2.4rem;
        border-radius: 1.2rem;
      }

      &:placeholder-shown {
        border-color: $g-color-blue-50;
      }
    }

    // &:disabled {
    //   border-color: $a-color-disabled-background;
    //   background-color: $a-color-disabled-background;
    //   color: $a-color-disabled-text;
    //   cursor: not-allowed;
    //   -webkit-text-fill-color: $a-color-disabled-text;
    //   opacity: 1;
    // }

    &.-danger {
      border-color: $g-color-red;
      background: $g-color-red-200;
      box-shadow: none;
    }

    &.-number {
      width: 3.5rem;
      height: 2.8rem;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1;
      text-align: center;
      border-radius: 0.8rem;
      padding: 0;

      &::placeholder {
        color: $g-color-gray-450;
        font-size: 1.4rem;
        @include media-breakpoint-up(lg) {
          font-size: 1.4rem;
        }
      }

      // &:placeholder-shown {
      //   color: $g-color-gray-450;
      //   background: $g-color-blue-50;
      //   border: 0.1rem solid $g-color-blue-50;
      // }

      &:focus {
        box-shadow: none;
      }

      // &:focus-visible {
      //   border-color: $g-color-blue;
      //   background: $g-color-white;
      //   outline: none;
      // }

      &:is(:-webkit-autofill, :autofill) {
        font-size: 1.4rem;
      }

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
        -moz-appearance: textfield;
      }
    }

    &.-number-none {
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
        -moz-appearance: textfield;
      }
    }
  }

  &__search {
    width: 100%;
    height: 4.2rem;
    display: flex;
    align-items: center;
    gap: 1.2rem;
    padding: 0.8rem 2.4rem;
    background: $g-color-white;
    border: 0.1rem solid $g-color-blue;
    border-radius: 0.8rem;
    overflow: hidden;
    @include media-breakpoint-up(lg) {
      height: 4.4rem;
    }

    &:has(.c-text-field__search-input:placeholder-shown) {
      color: $g-color-gray-450;
      background: $g-color-blue-50;
      border: 0.1rem solid $g-color-blue-50;
    }
    &:has(.c-text-field__search-input:focus) {
      border-color: $g-color-blue;
      background: $g-color-white;
      box-shadow: 0px 0px 0px 4px rgba(17, 49, 143, 0.13) inset;
    }
    &:has(.c-text-field__search-input:focus-visible) {
      border-color: $g-color-blue;
      outline: none;
    }

    &-input {
      width: 100%;
      height: 100%;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.4;
      letter-spacing: 0.02em;
      outline: none;
      border: none;
      background: none;
      box-shadow: none;
      padding: 0;
      -webkit-appearance: none;
      border-radius: 0;
      margin-bottom: 0.1rem;
      @include media-breakpoint-up(lg) {
        font-size: 1.6rem;
      }

      &::placeholder {
        color: $g-color-gray-450;
      }

      &::-webkit-input-placeholder {
        color: $g-color-gray-450;
      }

      &:is(:-webkit-autofill, :autofill) {
        box-shadow: 0 0 0 1000px white inset;
        transition: background-color 5000s ease-in-out 0s;
      }
    }

    &-button {
      cursor: pointer;
      width: 2.4rem;
      height: 2.4rem;
      flex-shrink: 0;
      outline: none;
      border: none;
      background: none;
      box-shadow: none;
    }
  }

  &__birthday {
    display: flex;
    align-items: center;

    .c-text-field__input {
      max-width: 8rem;
      min-width: 5.3rem;
      height: 5.3rem;
      padding: 0;
      text-align: center;
      @include media-breakpoint-up(lg) {
        max-width: 14.4rem;
        min-width: 14.4rem;
        height: 6.3rem;
      }

      &.-size-sm {
        @include media-breakpoint-up(lg) {
          max-width: 10rem;
          min-width: 10rem;
          height: 4.7rem;
        }
      }
    }
  }
}
