// ==========================================================================
// Text link
// ==========================================================================

.c-text-link {
  &,
  &:hover,
  &:active {
    color: $g-color-blue;
    text-decoration: none;
  }

  font-weight: 700;
  padding-bottom: 0.125em;
  border-bottom: solid 0.2rem $g-color-blue;

  &__icon {
    display: inline;
    color: $g-color-blue;
    vertical-align: middle;
    margin-left: 0.8rem;
  }
}
