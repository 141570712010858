// ==========================================================================
// Option
// ==========================================================================

.p-option-textarea {
  display: flex;
  flex-direction: column;
  margin-top: 3.6rem;
  @include media-breakpoint-up(lg) {
    flex-direction: row;
    gap: 4.8rem;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    margin-top: 58px;
  }

  &__col {
    @include media-breakpoint-up(lg) {
      flex: 0 0 auto;
      width: calc(6 / 12 * (100% + 4.8rem) - 4.8rem);
    }
  }

  &__col-heading01 {
  }

  &__col-heading02 {
    margin-top: 1.6rem;
    @include media-breakpoint-up(lg) {
      margin-top: 3.2rem;
    }
  }

  &__col-heading03 {
    margin-top: 3.4rem;
    @include media-breakpoint-up(lg) {
      margin-top: 0rem;
    }
  }

  &__col-text {
    margin-top: 2rem;
    @include media-breakpoint-up(lg) {
      margin-top: 1.4rem;
    }
  }

  &__col-list {
    margin-top: 2rem;
    @include media-breakpoint-up(lg) {
      margin-top: 1.4rem;
    }
  }
}
