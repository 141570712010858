// ==========================================================================
// Mypage
// ==========================================================================

.p-mypage-detail {
  margin-top: 7.2rem;

  @include media-breakpoint-up(lg) {
    margin-top: 11.2rem;
  }

  & + .p-mypage-detail {
    margin-top: 6.4rem;
    @include media-breakpoint-up(lg) {
      margin-top: 12rem;
    }
  }

  &__image {
    width: 100%;
    border-radius: 0.8rem;
    border: 0.1rem solid $g-color-blue-150;
    line-height: 0;
    padding: 0.8rem;
  }

  &__image-status {
    margin-bottom: 0.8rem;
  }

  &__image-img {
    width: 100%;
    max-width: 100%;
    height: 0;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    padding-top: calc(239 / 317 * 100%);
    position: relative;
  }

  &__image-gallery {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.6rem;
    margin-top: 0.8rem;
  }

  &__image-gallery-text {
    display: inline-flex;
    align-items: center;
    gap: 0.8rem;
    color: $g-color-gray-800;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.4;

    &::before {
      display: block;
      content: "";
      width: 1.4rem;
      height: 1.4rem;
      background-image: url(/assets/img/icon_gallery.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
  }

  &__name {
    margin-top: 2.8rem;
    margin-bottom: 2.4rem;
    @include media-breakpoint-up(lg) {
      margin-top: 0;
      margin-bottom: 2.8rem;
    }
  }

  &__option {
    margin-bottom: 2.8rem;
    @include media-breakpoint-up(lg) {
      margin-bottom: 3.4rem;
    }
  }

  &__option-text {
    margin-bottom: 1.8rem;
  }

  &__option-lists {
    display: flex;
    flex-wrap: wrap;
    gap: 0.8rem 2.4rem;
  }

  // &__option-list {

  // }

  &__option-link {
    display: flex;
    align-items: baseline;
    gap: 0.6rem;
    color: $g-color-blue;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.8rem;
    letter-spacing: 0.02em;
    text-decoration: none;
    @include media-breakpoint-up(lg) {
      font-size: 1.6rem;
    }
  }

  &__option-list-icon {
    flex-shrink: 0;
    display: flex;
    width: 1.4rem;
    height: 1.4rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $g-color-white;
    font-size: 1.2rem;
    font-weight: 700;
    list-style: 1;
    letter-spacing: 0.02em;
    background-color: $g-color-blue;
    border-radius: 100%;
  }

  &__info {
  }

  &__info-text {
  }

  &__info-dl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1rem 0.8rem;
    margin-top: 1.6rem;
  }

  &__info-dt {
    flex: 0 0 auto;
    width: 8rem;
    color: $g-color-gray-800;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.4;
    @include media-breakpoint-up(lg) {
      font-size: 1.4rem;
    }

    &.-note {
      align-self: baseline;
    }
  }

  &__info-dd {
    flex: 0 0 auto;
    width: calc(100% - (8rem + 0.8rem));
    color: $g-color-gray-800;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.4;
    @include media-breakpoint-up(lg) {
      font-size: 1.6rem;
    }

    &.-note {
      align-self: baseline;
    }

    &.-tags {
      display: flex;
      flex-wrap: wrap;
      align-items: baseline;
      gap: 0.8rem;
      padding-top: 0.7rem;
      padding-bottom: 1rem;
    }
  }

  &__info-tag {
    display: inline-flex;
    align-items: center;
    gap: 0.6rem;
    color: $g-color-gray-800;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.4;
    border-radius: 0.4rem;
    box-shadow: 0 0 0 0.1rem $g-color-gray inset;
    padding: 0.25rem 0.6rem;
    text-decoration: none;
    @include media-breakpoint-up(lg) {
      font-size: 1.4rem;
      padding: 0.15rem 0.6rem;
    }

    &::after {
      display: block;
      content: "";
      width: 1rem;
      height: 1rem;
      background-image: url(/assets/img/icon_cancel.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      // position: relative;
      // top: 0.1rem;
    }
  }

  &__info-input-wrap {
    position: relative;
  }

  &__info-input {
    height: 4rem;
    font-size: 1.4rem !important;
    border-radius: 0.8rem;
    padding: 1.2rem 1.6rem;
    padding-right: 4rem;
    @include media-breakpoint-up(lg) {
      font-size: 1.6rem !important;
    }

    &:is(:-webkit-autofill, :autofill),
    &::placeholder {
      font-size: 1.4rem !important;
      @include media-breakpoint-up(lg) {
        font-size: 1.6rem !important;
      }
    }
  }

  &__info-button {
    width: 1.6rem;
    height: 1.6rem;
    position: absolute;
    top: 0;
    right: 1.6rem;
    bottom: 0;
    margin: auto;

    &::before,
    &::after {
      content: "";
      background-color: $g-color-blue;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }

    &::before {
      width: 0.2rem;
      height: 100%;
    }

    &::after {
      width: 100%;
      height: 0.2rem;
    }
  }
}
