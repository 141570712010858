// ==========================================================================
// Table
// ==========================================================================

.c-table {
  overflow: auto;
  margin-right: -2rem;

  @include media-breakpoint-up(lg) {
    margin-right: 0;
  }

  &__wrap {
    min-width: 100%;
    caption-side: bottom;
    border-collapse: collapse;
    border-color: $g-color-blue-150;
    table-layout: fixed;
  }

  &__thead,
  &__tbody,
  &__tr,
  &__th,
  &__td {
    text-align: left;
    vertical-align: middle;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
  }

  &__thead {
    vertical-align: bottom;

    .c-table__th {
      color: $g-color-gray-800;
      text-align: center;
      font-size: 1.6rem;
      font-weight: 700;
      line-height: 1.4;
      border-bottom-width: 0.2rem;
      @include media-breakpoint-up(lg) {
        font-size: 1.8rem;
      }
    }
  }

  &__tbody {
    vertical-align: inherit;
  }

  // &__td {
  // }

  &__th,
  &__td {
    color: $g-color-gray-800;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.4;
    border-bottom-width: 0.1rem;
    padding: 1.1rem 1.6rem;
    white-space: nowrap;
    @include media-breakpoint-up(lg) {
      font-size: 1.8rem;
      padding: 1.1rem 2.6rem;
    }
  }

  &__td {
    @include media-breakpoint-up(lg) {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }

  &.-ver01 {
    .c-table__wrap {
      table-layout: auto;
    }

    .c-table__colgroup-col {
      &:nth-child(1) {
        @include media-breakpoint-up(lg) {
          // width: 25.6rem;
          width: calc(256 / 1072 * 100%);
        }
      }
      &:nth-child(2) {
        @include media-breakpoint-up(lg) {
          // width: 28rem;
          width: calc(280 / 1072 * 100%);
        }
      }
    }

    .c-table__th,
    .c-table__td {
      text-align: center;
    }
  }

  &.-ver02 {
    .c-table__wrap {
      table-layout: auto;
      @include media-breakpoint-up(lg) {
        table-layout: fixed;
      }
    }

    .c-table__colgroup-col {
      @include media-breakpoint-up(lg) {
        width: 25%;
      }
    }

    .c-table__th,
    .c-table__td {
      text-align: center;
    }
  }
}
