// ==========================================================================
// Mypage
// ==========================================================================

.p-mypage-info {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin-top: 7.2rem;

  @include media-breakpoint-up(lg) {
    flex-direction: row;
    gap: 1.6rem;
    margin-top: 9rem;
  }

  &__item {
    margin-top: 1rem;
  }

  &__text {
    margin-top: 1.6rem;
  }
}
