// ==========================================================================
// Information
// ==========================================================================

.p-information-indent {
  &__lv1 {
    margin-top: 11rem;
    @include media-breakpoint-up(lg) {
      margin-top: 20.4rem;
    }
  }

  &__lv2 {
    margin-top: 2.4rem;
    @include media-breakpoint-up(lg) {
      margin-top: 2.8rem;
    }
  }
}
