// ==========================================================================
// GEO ID
// ==========================================================================

.p-geo-id-card {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $g-color-blue;
  font-size: 2.2rem;
  font-weight: 700;
  line-height: 1.4;
  text-align: center;
  border: 0.2rem solid $g-color-blue;
  border-radius: 1.6rem;
  background-color: $g-color-white;
  padding: 1.9rem 3.2rem;
  @include media-breakpoint-up(lg) {
    font-size: 2.4rem;
    padding-top: 3.1rem;
    padding-bottom: 3rem;
  }
}
