// ==========================================================================
// Mypage
// ==========================================================================

.p-mypage-card {
  width: 100%;
  height: 100%;
  border-radius: 2.4rem;
  border: 0.1rem solid $g-color-blue-150;
  padding: 1.6rem 2.4rem 2.4rem;
  overflow: hidden;
  @include media-breakpoint-up(lg) {
    padding: 2rem 3.2rem 2.4rem;
  }

  &__heading {
    margin-bottom: 2.6rem;
    @include media-breakpoint-up(lg) {
      margin-bottom: 3.4rem;
    }
  }

  &__plan {
    border-top: 0.1rem solid $g-color-blue-150;
    border-bottom: 0.1rem solid $g-color-blue-150;
    padding: 1.4rem 1.2rem;
    margin-top: 1.2rem;
    @include media-breakpoint-up(lg) {
      padding-left: 0.8rem;
      padding-right: 0.8rem;
      margin-bottom: 1.2rem;
    }
  }

  &__plan-price {
    color: $g-color-gray-800;
    font-weight: 700;
    line-height: 1.4;
    @include media-breakpoint-up(lg) {
      padding-left: 0.8rem;
      padding-right: 0.8rem;
    }

    .-num {
      font-size: 2.4rem;
      @include media-breakpoint-up(lg) {
        font-size: 3rem;
      }

      &-sm {
        font-size: 1.6rem;
        @include media-breakpoint-up(lg) {
          font-size: 1.8rem;
        }
      }
    }

    .-unit {
      font-size: 1.6rem;

      @include media-breakpoint-up(lg) {
        font-size: 1.8rem;
      }

      &-sm {
        font-size: 1.2rem;
        @include media-breakpoint-up(lg) {
          font-size: 1.4rem;
        }
      }
    }

    .-tax {
      font-size: 1.2rem;
      @include media-breakpoint-up(lg) {
        font-size: 1.4rem;
      }
    }
  }

  &__plan01 {
    display: flex;
    flex-direction: column;
    border-bottom: 0.1rem solid $g-color-blue-150;
    padding-bottom: 1.8rem;
    @include media-breakpoint-up(lg) {
      border-bottom: none;
      padding: 0 0.8rem;
      padding-left: 0.8rem !important;
    }
  }

  &__plan01-text {
    color: $g-color-gray-800;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1.4;
    margin-bottom: 1.2rem;
    @include media-breakpoint-up(lg) {
      font-size: 1.8rem;
    }
  }

  &__text-small {
    margin-top: 1.2rem;
  }

  &__button {
    margin-top: 2.8rem;
    @include media-breakpoint-up(lg) {
      margin-top: 3.2rem;
    }
  }

  &__itemnum {
    color: $g-color-blue;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: 0.05em;
    margin-top: 2.4rem;
    margin-bottom: 1rem;
    @include media-breakpoint-up(lg) {
      font-size: 2rem;
      margin-top: 2rem;
      margin-bottom: 1.8rem;
    }

    .-num {
      font-size: 1.8rem;
      letter-spacing: 0.06em;
      padding-left: 1.6rem;
      @include media-breakpoint-up(lg) {
        font-size: 2.4rem;
      }
    }

    .-num-sm {
      font-size: 1.8rem;
      letter-spacing: 0.06em;
      padding-left: 1.6rem;
      @include media-breakpoint-up(lg) {
        font-size: 2rem;
      }
    }

    .-unit {
      font-size: 1.2rem;
      letter-spacing: normal;
      @include media-breakpoint-up(lg) {
        font-size: 1.6rem;
      }
    }
  }

  &__itemnum01 {
    color: $g-color-blue;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.4;
    @include media-breakpoint-up(lg) {
      font-size: 1.6rem;
    }
  }

  &__textcols {
    display: flex;
    align-items: baseline;
    flex-direction: column;
    gap: 3.2rem;
    margin-top: 1.6rem;
    @include media-breakpoint-up(lg) {
      flex-direction: row;
    }

    &.-ver01 {
      align-items: normal;
      gap: 0;
      margin-top: 3rem;
    }

    &.-ver02 {
      gap: 1.6rem;
      margin-top: 1.6rem;
    }

    &.-ver03 {
      gap: 0;
      margin-top: 0;
      @include media-breakpoint-up(lg) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }
    }
  }

  &__textcols-button {
    flex-shrink: 0;
    @include media-breakpoint-up(lg) {
      position: relative;
      top: 0.8rem;
    }
  }

  // Graph
  &__graph {
    display: flex;
    align-items: center;
    gap: 1.6rem;
    margin-top: 3rem;
  }

  &__graph-bar {
    --percent: 0%;
    width: 100%;
    height: 2.9rem;
    border-radius: 0.8rem;
    background-color: $g-color-blue-50;
    position: relative;
    overflow: hidden;
    @include media-breakpoint-up(lg) {
      height: 3.5rem;
    }

    &::before {
      display: block;
      content: "";
      width: var(--percent);
      height: 100%;
      background-color: $g-color-blue;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
  }

  &__graph-text {
    flex-shrink: 0;
    color: $g-color-blue;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    @include media-breakpoint-up(lg) {
      line-height: 1.2;
    }

    .-num {
      font-size: 1.6rem;
      letter-spacing: 0.05em;
      @include media-breakpoint-up(lg) {
        font-size: 2rem;
      }
    }

    .-unit {
      font-size: 1.2rem;
      @include media-breakpoint-up(lg) {
        font-size: 1.6rem;
      }
    }

    .-text {
      font-size: 1.2rem;
      @include media-breakpoint-up(lg) {
        font-size: 1.6rem;
      }
    }
  }

  &__hr {
    border-top: 0.1rem solid $g-color-blue-150;
    padding-top: 1.6rem;
    margin-top: 3.2rem;
  }

  &__plan-cols {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: 1.6rem;

    @include media-breakpoint-up(lg) {
      gap: 6.4rem;
      flex-direction: row;
      align-items: center;
      margin-top: 1.8rem;

      &::before {
        display: block;
        content: "";
        width: 0.1rem;
        height: 100%;
        background-color: $g-color-blue-150;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }
    }

    & > * {
      padding-left: 1.2rem;
      padding-right: 1.2rem;
      @include media-breakpoint-up(lg) {
        flex: 0 0 auto;
        width: calc(6 / 12 * (100% + 6.4rem) - 6.4rem);
        padding: 0;
      }
    }
  }

  &__cols {
    @include media-breakpoint-up(lg) {
      display: flex;
      align-items: baseline;
      gap: 3.2rem;
    }
  }

  &__cols-col {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.6rem;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    margin-top: 1.8rem;
    @include media-breakpoint-up(lg) {
      margin-top: 0;
      margin-left: auto;

      .p-mypage-card__plan-price {
        min-width: 23rem;
      }
    }
  }

  &__cols01 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2.4rem;
  }

  &__cols-arrow {
    @include media-breakpoint-up(lg) {
      display: flex;
      padding-top: 1.2rem;
      padding-bottom: 0.6rem;
    }
  }

  &__cols-arrow-col {
    flex-grow: 1;
  }

  &__cols-agree {
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-direction: row-reverse;
      align-items: flex-start;
      gap: 3.2rem;
      margin-top: 0.8rem;
    }
  }

  &__cols-agree-img {
    width: 16.3rem;
    margin: 0.8rem auto 2.8rem;
    @include media-breakpoint-up(lg) {
      flex-shrink: 0;
      margin: 1.2rem 0 0;
    }
  }

  &__cols-agree-col {
    // @include media-breakpoint-up(lg) {
    //   display: flex;
    //   padding-top: 1.2rem;
    //   padding-bottom: 0.6rem;
    // }
  }

  &__cols-arrow-icon {
    flex-shrink: 0;
    margin: 1.6rem auto;
    @include media-breakpoint-up(lg) {
      margin: 0 4.8rem;
    }
  }

  &__price-cols {
    display: flex;
    flex-direction: column;
    @include media-breakpoint-up(lg) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 1.6rem;
      margin-top: 0.8rem;
      margin-bottom: 0.4rem;
    }
  }

  &__price-cols-col {
    flex-shrink: 0;
  }

  // __listbox
  &__listbox {
    border-top: 0.1rem solid $g-color-blue-150;
    margin-top: 2rem;
  }

  &__listbox-item {
    padding: 1.2rem;

    @include media-breakpoint-up(lg) {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      gap: 3.2rem;
      padding: 2.2rem 0.8rem;
    }

    & + .p-mypage-card__listbox-item {
      border-top: 0.1rem solid $g-color-blue-150;
    }
  }

  &__listbox-item-text {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: baseline;
    gap: 0.6rem 1.6rem;
    color: $g-color-gray-800;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.4;

    @include media-breakpoint-up(lg) {
      font-size: 1.6rem;
    }

    .-small {
      font-size: 1.4rem;
    }
  }

  &__listbox-item-col {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    gap: 1.6rem;
    margin-top: 0.6rem;

    @include media-breakpoint-up(lg) {
      gap: 3.2rem;
      margin-top: 0;
    }

    .-last {
      margin-left: auto;

      @include media-breakpoint-up(lg) {
        min-width: 19.6rem;
        text-align: right;
      }
    }
  }

  &__listbox-item-num {
    color: $g-color-gray-800;
    font-weight: 700;
    line-height: 1.4;

    @include media-breakpoint-up(lg) {
      min-width: 7.3rem;
      text-align: center;
    }

    .-num {
      font-size: 1.8rem;
    }

    .-unit {
      font-size: 1.4rem;
    }
  }

  &__listbox-item-text-small {
    color: $g-color-gray-800;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.4;
  }

  &__listbox-item-total {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    gap: 1.6rem;
    border-top: 0.2rem solid $g-color-blue-150;
    padding-top: 0.8rem;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    @include media-breakpoint-up(lg) {
      gap: 3.2rem;
      padding-left: 0.8rem;
      padding-right: 0;
    }
  }

  &__listbox-item-total-text {
    color: $g-color-gray-800;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1.4;

    @include media-breakpoint-up(lg) {
      font-size: 1.8rem;
    }
  }

  // __listbox01
  &__listbox01 {
    margin-top: 1rem;
    @include media-breakpoint-up(lg) {
      margin-top: 1.6rem;
    }

    &.-ver01 {
      border-top: 0.1rem solid $g-color-blue-150;
      margin-top: 2rem;

      .p-mypage-card__listbox01-item {
        padding: 1.2rem 0 1.2rem 1.2rem;
        @include media-breakpoint-up(lg) {
          padding: 2rem 0.8rem;
        }
      }

      .p-mypage-card__listbox01-item-text {
        .-small {
          display: block;
          font-size: 1.4rem;
          @include media-breakpoint-up(lg) {
            display: inline;
            padding-left: 1.6rem;
          }
        }
      }

      .p-mypage-card__listbox01-item-total {
        padding: 1.2rem 0 0 1.2rem;
        @include media-breakpoint-up(lg) {
          padding: 1.2rem 0.8rem;
        }
      }
    }
  }

  &__listbox01-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.6rem;
    padding: 0 0.8rem 1.2rem;

    @include media-breakpoint-up(lg) {
      align-items: center;
      padding-top: 1.6rem;
      padding-bottom: 2rem;
    }

    & + .p-mypage-card__listbox01-item {
      border-top: 0.1rem solid $g-color-blue-150;
      padding-top: 1.2rem;
      @include media-breakpoint-up(lg) {
        padding-top: 2rem;
      }
    }
  }

  &__listbox01-item-text {
    gap: 0.6rem 1.6rem;
    color: $g-color-gray-800;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.4;
    overflow-wrap: anywhere;

    @include media-breakpoint-up(lg) {
      font-size: 1.6rem;
    }

    .-small {
      font-size: 1.4rem;
    }
  }

  &__listbox01-item-col {
    display: flex;
    flex-direction: column;
    text-align: center;
    flex-shrink: 0;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      align-items: center;
    }

    .-last {
      margin-left: auto;

      @include media-breakpoint-up(lg) {
        min-width: 19.6rem;
        text-align: right;
      }
    }
  }

  &__listbox01-item-num {
    color: $g-color-gray-800;
    font-weight: 700;
    line-height: 1.4;

    @include media-breakpoint-up(lg) {
      min-width: 7.3rem;
      text-align: center;
    }

    .-num {
      font-size: 1.8rem;
    }

    .-unit {
      font-size: 1.4rem;
    }
  }

  &__listbox01-item-button {
    margin-top: 1rem;
    @include media-breakpoint-up(lg) {
      width: 22rem;
      text-align: right;
      margin-top: 0;
    }
  }

  &__listbox01-item-text-small {
    color: $g-color-gray-800;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.4;
  }

  &__listbox01-item-total {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    gap: 1.6rem;
    border-top: 0.2rem solid $g-color-blue-150;
    padding-top: 0.8rem;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    @include media-breakpoint-up(lg) {
      gap: 3.2rem;
      padding-left: 0.8rem;
      padding-right: 0;
    }
  }

  &__listbox01-item-total-text {
    color: $g-color-gray-800;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1.4;

    @include media-breakpoint-up(lg) {
      font-size: 1.8rem;
    }
  }

  &__tel {
    width: 100%;
    border-top: $g-color-blue-300 solid 0.1rem;
    margin-top: 2.8rem;
    padding-top: 2.8rem;

    @include media-breakpoint-up(lg) {
      width: auto;
      flex-shrink: 0;
      border-top: none;
      border-left: $g-color-blue-300 solid 0.1rem;
      padding-top: 0;
      padding-left: 3.2rem;
      margin-top: 0;
      margin-left: 2.8rem;
    }

    @include media-breakpoint-up(xxl) {
      padding-left: 6.4rem;
      margin-left: 6.4rem;
    }
  }

  &__tel-area {
    display: flex;
    align-items: baseline;
    gap: 0.4rem;
    margin: 0.5rem 0;
  }

  &__tel-icon {
    width: 1.2rem;
    @include media-breakpoint-up(lg) {
      width: 1.6rem;
    }
  }

  &__tel-link {
    color: $g-color-blue;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: 0.05em;
    text-decoration: none;
    @include media-breakpoint-up(lg) {
      font-size: 2rem;
    }
  }

  &__tel-text {
    margin-top: 0;
  }

  //
  &.-size-sm {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    padding: 1.9rem 2.4rem;
    border-radius: 1.6rem;
    @include media-breakpoint-up(lg) {
      flex-direction: row;
      gap: 3.2rem;
      align-items: center;
    }
  }
}
