// ==========================================================================
// Container
// ==========================================================================

.c-container {
  padding-left: 2rem;
  padding-right: 2rem;

  @include media-breakpoint-up(lg) {
    padding-left: 4.8rem;
    padding-right: 4.8rem;
    margin-left: auto;
    margin-right: auto;
  }

  &.-max-width-1104 {
    max-width: 17.6 + 160 + rem;

    @include media-breakpoint-up(xl) {
      padding-left: 8.8rem;
      padding-right: 8.8rem;
    }
  }

  &.-max-width-1600 {
    max-width: 169.6rem;
  }
}
