@use "sass:math";

// -----------------------------------------------------------------
// Web Font
// -----------------------------------------------------------------

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&display=swap");

// ==========================================================================
// Foundation
// ==========================================================================

@import "foundation/variable";
@import "foundation/function";
@import "foundation/mixin";
@import "foundation/reset";
@import "foundation/base";
// @import "foundation/font";

// ==========================================================================
// Layout
// ==========================================================================
@import "layout/header";
@import "layout/info";
@import "layout/footer";
@import "layout/aside";
// @import "layout/main";

// ==========================================================================
// Object
// ==========================================================================

// -----------------------------------------------------------------
// Component
// -----------------------------------------------------------------

@import "object/component/anchor-link";
@import "object/component/badge";
@import "object/component/button";
@import "object/component/box-card";
@import "object/component/container";
@import "object/component/card";
@import "object/component/figure";
@import "object/component/grid";
@import "object/component/heading";
@import "object/component/hero";
@import "object/component/list";
@import "object/component/none";
@import "object/component/table";
@import "object/component/table-attr";
@import "object/component/table-list";
@import "object/component/text";
@import "object/component/text-link";
@import "object/component/text-style";
@import "object/component/modal";

// @import "object/component/overflow";
// @import "object/component/card";
// @import "object/component/container";
// @import "object/component/badges";
// @import "object/component/badge";
// @import "object/component/button";
// @import "object/component/icon";
// @import "object/component/row";
// @import "object/component/breadcrumb";
// @import "object/component/pagination";
// @import "object/component/collapse";
// @import "object/component/tab";
// @import "object/component/table";
// @import "object/component/text-link";
// @import "object/component/message";
// @import "object/component/list";
// @import "object/component/embed";
// @import "object/component/text-style";
// @import "object/component/figure";
// @import "object/component/article-list";
// @import "object/component/hero";
// @import "object/component/progress";
// @import "object/component/cookie";
// @import "object/component/org-chart";
// @import "object/component/header";
// @import "object/component/scroll-to-top-button";
// @import "object/component/footer";
// @import "object/component/floating-banner";
// @import "object/component/switch";

// form
// @import "object/component/form";
@import "object/component/label";
@import "object/component/text-field";
@import "object/component/text-area";
@import "object/component/select";
@import "object/component/radio";
@import "object/component/checkbox";
@import "object/component/checkbox-item";
// @import "object/component/field-group";
@import "object/component/help-text";
@import "object/component/confirm";
@import "object/component/complete";

//

// @import "object/component/hidden";
// @import "object/component/article-list";

// @import "object/component/card";
// @import "object/component/sns";

// -----------------------------------------------------------------
// Project
// -----------------------------------------------------------------

// @import "object/project/top";

// @import "object/project/card-carousel";
// // @import "object/project/categorys";
// @import "object/project/main-cards";
// @import "object/project/main-lists";
// @import "object/project/categorys-switch";
// @import "object/project/layout-switch";
// @import "object/project/card";
// @import "object/project/article";
// @import "object/project/input-search";

// TOP
@import "object/project/top/fv";
@import "object/project/top/about";
@import "object/project/top/simulation";
@import "object/project/top/visit";
@import "object/project/top/voice";

// price
@import "object/project/price/line-up";
@import "object/project/price/guide";
@import "object/project/price/support";
@import "object/project/price/visit";
@import "object/project/price/discount";

// temporary-storage
@import "object/project/temporary-storage/card";
@import "object/project/temporary-storage/box";

// option
@import "object/project/option/cols";
@import "object/project/option/textarea";
@import "object/project/option/step";

// faq
@import "object/project/faq/search";
@import "object/project/faq/heading";
@import "object/project/faq/q";
@import "object/project/faq/a";

// about
@import "object/project/about/indent";
@import "object/project/about/cols";
@import "object/project/about/media";

// information
@import "object/project/information/indent";
@import "object/project/information/list";

// guide
@import "object/project/guide/card";

// voice
@import "object/project/voice/voice";

// plan price
@import "object/project/plan-price/card";
@import "object/project/plan-price/cols";

// how-to-use
@import "object/project/how-to-use/main";
@import "object/project/how-to-use/step";
@import "object/project/how-to-use/card";

// Simulation
@import "object/project/simulation/item";
@import "object/project/simulation/card";
@import "object/project/simulation/others";
@import "object/project/simulation/floating";

// GEO ID
@import "object/project/geo-id/_card";

// Mypage
@import "object/project/mypage/content";
@import "object/project/mypage/button";
@import "object/project/mypage/info";
@import "object/project/mypage/card";
@import "object/project/mypage/cols";
@import "object/project/mypage/form";
@import "object/project/mypage/listbox";
@import "object/project/mypage/flow";
@import "object/project/mypage/note";
@import "object/project/mypage/decoration";
@import "object/project/mypage/card-option";
@import "object/project/mypage/listform";
@import "object/project/mypage/item";
@import "object/project/mypage/step";
@import "object/project/mypage/list";
@import "object/project/mypage/detail";
@import "object/project/mypage/item-list";
@import "object/project/mypage/purchases";
@import "object/project/mypage/qrcode";
@import "object/project/mypage/campaigns-form";
@import "object/project/mypage/calendar";

// OLD
@import "object/project/old/old";

// @import "object/project/top-topics";
// @import "object/project/top-about";
// @import "object/project/top-blank";
// article
// @import "object/project/article";
// @import "object/project/article-fv";
// @import "object/project/article-frame";
// @import "object/project/article-content";
// @import "object/project/article-landmark";
// @import "object/project/article-connection";
// @import "object/project/article-sns";
// @import "object/project/article-graph";

// -----------------------------------------------------------------
// Utility
// -----------------------------------------------------------------

@import "object/utility/align";
@import "object/utility/background";
@import "object/utility/border";
@import "object/utility/color";
@import "object/utility/display";
@import "object/utility/flex";
@import "object/utility/font";
@import "object/utility/height";
@import "object/utility/justify";
// @import "object/utility/letter";
// @import "object/utility/line";
// @import "object/utility/list-style";
@import "object/utility/margin";
// @import "object/utility/spacing";
// @import "object/utility/padding";
// @import "object/utility/pointer";
@import "object/utility/text";
// @import "object/utility/vertical";
@import "object/utility/width";
@import "object/utility/spacing";
