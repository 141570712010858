// ==========================================================================
// Option
// ==========================================================================

.p-faq-q {
  margin-top: 3.6rem;
  @include media-breakpoint-up(lg) {
    margin-top: 4rem;
  }

  .c-heading__h3-lv1 {
    text-indent: -1.444em;
    padding-left: 1.444em;
  }
}
