// ==========================================================================
// Information
// ==========================================================================

.p-information-list {
  margin-top: 3.1rem;
  @include media-breakpoint-up(lg) {
    margin-top: 4rem;
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    @include media-breakpoint-up(lg) {
      flex-direction: row;
      align-items: center;
      gap: 32px;
      padding-left: 1.6rem;
      padding-right: 1.6rem;
    }

    &:is(.p-information-list__item + .p-information-list__item) {
      border-top: 0.1rem solid $g-color-blue-150;
      padding-top: 1.5rem;
      margin-top: 2rem;
      @include media-breakpoint-up(lg) {
        padding-top: 4.1rem;
        margin-top: 3.8rem;
      }
    }
  }

  &__box-indent01 {
    display: block;
    margin-bottom: 0.4rem;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0.2em;
    }
  }

  &__box-indent02 {
    margin-bottom: 1.8rem;
    @include media-breakpoint-up(lg) {
      margin-bottom: 1.5rem;
    }
  }

  &__box-indent03 {
    margin-top: 1.9rem;
    margin-bottom: 2.6rem;
    @include media-breakpoint-up(lg) {
      margin-top: 1.6rem;
    }
  }
}
