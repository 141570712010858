// ==========================================================================
// Confirm
// ==========================================================================

.c-confirm {
  &__indent {
    margin-top: 3.2rem;
    @include media-breakpoint-up(lg) {
      margin-top: 4rem;
    }
  }

  &__text {
    display: inline-flex;
    gap: 2.4rem;
    color: $g-color-black;
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 1.8;
    letter-spacing: 0.02em;
    margin-top: 0.8rem;

    @include media-breakpoint-up(lg) {
      gap: 3.2rem;
      font-size: 2rem;
      margin-top: 0;
    }
  }

  &__buttons {
    display: flex;
    gap: 2.4rem;
    margin-top: 2.2rem;
    @include media-breakpoint-up(lg) {
      gap: 4rem;
    }
  }
}
