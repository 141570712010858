// ==========================================================================
// Mypage
// ==========================================================================

.p-mypage-item {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  @include media-breakpoint-up(lg) {
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__card {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0 2.4rem;
    flex: 0 0 auto;
    border-radius: 0.8rem;
    box-shadow: 0 0 0 0.2rem $g-color-blue inset;
    padding: 0.8rem;
    @include media-breakpoint-up(lg) {
      width: calc(3 / 12 * (100% + 2.4rem) - 2.4rem);
      align-items: normal;
      flex-direction: column;
    }
  }

  &__img {
    width: calc(90 / 337 * 100%);
    @include media-breakpoint-up(lg) {
      width: 100%;
    }
  }

  &__name {
    color: $g-color-gray-800;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1.4;
    @include media-breakpoint-up(lg) {
      padding: 0.4rem 0.8rem;
      margin-top: 1.2rem;
    }
  }
}
