// ==========================================================================
// Option
// ==========================================================================

.p-option-cols {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  margin-top: 3.2rem;
  @include media-breakpoint-up(lg) {
    gap: 6.4rem;
    flex-direction: row-reverse;
    margin-top: 2.8rem;
  }

  &__image {
    border-radius: 1.6rem;
    overflow: hidden;
  }

  &__image-img {
  }

  &__image,
  &__col {
    @include media-breakpoint-up(lg) {
      flex: 0 0 auto;
      width: calc(6 / 12 * (100% + 6.4rem) - 6.4rem);
    }
  }
}
