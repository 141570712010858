// ==========================================================================
// List
// ==========================================================================

.c-list {
  // color: $g-color-gray;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.6;
  list-style-type: none;
  padding-left: 1.5em;
  @include media-breakpoint-up(lg) {
    font-size: 1.8rem;
  }

  &__item {
    position: relative;
    &::before {
      content: "";
      width: 0.4rem;
      height: 0.4rem;
      background-color: $g-color-gray;
      border-radius: 100%;
      position: absolute;
      top: 0.75em;
      left: -0.875em;
      @include media-breakpoint-up(lg) {
        width: 0.5rem;
        height: 0.5rem;
      }
    }

    &:is(.c-list__item + .c-list__item) {
      margin-top: 1rem;
    }
  }

  &.-decimal {
    list-style-type: decimal;

    .c-list__item {
      &::before {
        content: none;
      }
    }
  }

  &.-size-sm {
    font-size: 1.4rem;
    @include media-breakpoint-up(lg) {
      font-size: 1.6rem;
    }
  }
}
