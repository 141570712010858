// ==========================================================================
// Mypage
// ==========================================================================

.p-mypage-listbox {
  border-bottom: 0.1rem solid $g-color-blue-150;

  &__info {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    border-bottom: 0.2rem solid $g-color-blue-150;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    padding-bottom: 1.9rem;
    margin-top: 1.1rem;
    @include media-breakpoint-up(lg) {
      flex-direction: row;
      align-items: center;
      gap: 2.8rem 0;
      padding-bottom: 2.8rem;
      margin-top: 4.5rem;
    }
  }

  &__info-title {
    margin-top: 2.1rem;
    @include media-breakpoint-up(lg) {
      flex: 0 0 auto;
      width: 25.6rem;
      margin-top: 0;
    }
  }

  &__info-text {
    margin-top: 0.8rem;
    @include media-breakpoint-up(lg) {
      flex: 0 0 auto;
      width: calc(100% - 25.6rem);
      margin-top: 0;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    padding: 2.4rem 0.8rem;

    & + .p-mypage-listbox__item {
      border-top: 0.1rem solid $g-color-blue-150;
    }
  }

  &__item-area {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.6rem;
  }

  &__item-text {
    color: $g-color-gray-800;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1.4;
    @include media-breakpoint-up(lg) {
      font-size: 1.8rem;
    }

    &.-price {
      flex-shrink: 0;
    }

    &.-price01 {
      flex-shrink: 0;
      margin-top: 1.2rem;
      @include media-breakpoint-up(lg) {
        margin-top: 0;
      }
    }

    .-unit {
      font-size: 1.4rem;
    }

    .-unit-sm {
      font-size: 1.2rem;
      @include media-breakpoint-up(lg) {
        font-size: 1.4rem;
      }
    }

    .-total {
      font-size: 1.2rem;
      padding-right: 1.6rem;
      @include media-breakpoint-up(lg) {
        font-size: 1.4rem;
      }
    }
  }

  &__item-text-small {
    color: $g-color-gray-500;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.6;
    letter-spacing: 0.02em;
    margin-top: 1.6rem;
    @include media-breakpoint-up(lg) {
      font-size: 1.4rem;
    }
  }

  &__item-text-small01 {
    color: $g-color-gray-800;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.4;
    margin-top: 1rem;
    @include media-breakpoint-up(lg) {
      font-size: 1.6rem;
      margin-top: 0;
    }

    & + .p-mypage-listbox__item-text-small01 {
      margin-top: 0.5rem;
      @include media-breakpoint-up(lg) {
        margin-top: 0;
      }
    }
  }

  &.-ver01 {
    border-top: 0.1rem solid $g-color-blue-150;
    margin-top: 6.5rem;
    @include media-breakpoint-up(lg) {
      margin-top: 8rem;
    }

    .p-mypage-listbox__item {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 1.6rem;
      @include media-breakpoint-up(lg) {
        padding: 2rem 0.8rem;
      }
    }

    .p-mypage-listbox__item-area {
      flex-direction: column;
      align-items: flex-start;
      gap: 0;

      @include media-breakpoint-up(lg) {
        flex-direction: row;
        align-items: center;
      }
    }

    .p-mypage-listbox__item-text {
      @include media-breakpoint-up(lg) {
        width: 15rem;
        flex-shrink: 0;
      }
    }

    .p-mypage-listbox__item-button {
      flex-shrink: 0;
    }
  }

  &.-ver02 {
    border-top: 0.1rem solid $g-color-blue-150;
    margin-top: 4rem;
    @include media-breakpoint-up(lg) {
      margin-top: 5.6rem;
    }

    .p-mypage-listbox__item {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 1.6rem;
      padding-top: 1.8rem;
      padding-bottom: 1.8rem;
      @include media-breakpoint-up(lg) {
        gap: 6.4rem;
        padding: 2.4rem 0.8rem;
      }
    }

    .p-mypage-listbox__item-area {
      flex-direction: column;
      align-items: flex-start;
      gap: 0;
      @include media-breakpoint-up(lg) {
        flex-grow: 1;
        flex-direction: row;
        align-items: center;
      }
    }

    .p-mypage-listbox__item-button {
      flex-shrink: 0;
    }
  }

  &.-ver03 {
    margin-top: 0;
    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }

    .p-mypage-listbox__item {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 1.6rem;
      @include media-breakpoint-up(lg) {
        padding: 2rem 0.8rem 2rem 1.6rem;
      }
    }

    .p-mypage-listbox__item-area {
      flex-direction: column;
      align-items: flex-start;
      gap: 0;

      @include media-breakpoint-up(lg) {
        flex-direction: row;
        align-items: center;
      }
    }

    .p-mypage-listbox__item-text {
      @include media-breakpoint-up(lg) {
        width: 25.6rem;
        flex-shrink: 0;
      }
    }

    .p-mypage-listbox__item-button {
      flex-shrink: 0;
    }
  }
}
