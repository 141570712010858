// ==========================================================================
// Font
// ==========================================================================

// Font Size
// ==========================================================================
// @mixin utilities-size($media) {
//   $keyword: if($media != null, "-" + $media, "");

//   @for $i from $utility-font-size-for-start through $utility-font-size-for-end {
//     $name: $i;
//     $value: ($utility-font-size-n * $i);

//     .u-font-size-#{$name}#{$keyword} {
//       font-size: $value !important;
//     }
//   }
// }

// @mixin utilities-size-relative($media) {
//   $keyword: if($media != null, "-" + $media, "");

//   @each $size, $value in $utility-font-size-em {
//     .u-font-size-#{$size}#{$keyword} {
//       font-size: $value + em !important;
//     }
//   }
// }

// Font Weight
// ==========================================================================
@mixin utilities-weight($media) {
  $keyword: if($media != null, "-" + $media, "");

  @each $weight-name, $weight-value in $utility-font-weight {
    .u-font-weight-#{$weight-name}#{$keyword} {
      font-weight: #{$weight-value} !important;
    }
  }
}

// @include utilities-size(null);
// @include utilities-size-relative(null);
@include utilities-weight(null);
// @each $media-name, $media-width in $g-breakpoint {
//   @include media-breakpoint-up($media-name) {
//     @include utilities-size($media-name);
//     @include utilities-size-relative($media-name);
//     @include utilities-weight($media-name);
//   }
// }
