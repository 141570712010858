// ==========================================================================
// Text Area
// ==========================================================================

.c-text-area {
  position: relative;

  &__input {
    width: 100%;
    height: 16.1rem;
    color: $g-color-black;
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 1.8;
    letter-spacing: 0.02em;
    background: $g-color-white;
    border: 0.1rem solid $g-color-blue;
    border-radius: 1.6rem;
    padding: 1.111em;
    vertical-align: bottom;
    @include media-breakpoint-up(lg) {
      height: 32.3rem;
      font-size: 2rem;
      padding: 0.8em 1.6em;
    }

    &::placeholder {
      color: $g-color-gray-450;
      font-size: 1.6rem;
      @include media-breakpoint-up(lg) {
        font-size: 1.8rem;
      }
    }

    &:placeholder-shown {
      color: $g-color-gray-450;
      background: $g-color-blue-50;
      border: 0.1rem solid $g-color-blue-50;
    }

    &::placeholder {
      color: $g-color-gray-450;
      font-size: 1.6rem;
      @include media-breakpoint-up(lg) {
        font-size: 1.8rem;
      }
    }

    &:focus {
      border-color: $g-color-blue;
      background: $g-color-white;
      box-shadow: 0px 0px 0px 4px rgba(17, 49, 143, 0.13) inset;
    }

    &:focus-visible {
      border-color: $g-color-blue;
      outline: none;
    }

    &.-danger {
      border-color: $g-color-red;
      background: $g-color-red-200;
      box-shadow: none;
    }
  }

  &__length {
    color: $g-color-gray-450;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.02em;
    position: absolute;
    right: 1.313em;
    bottom: 1.375em;
    @include media-breakpoint-up(lg) {
      right: 2em;
      bottom: 1em;
    }
  }

  &.-size-sm {
    .c-text-area__input {
      height: 17.3rem;
    }
  }
}
