// ==========================================================================
// plan-price
// ==========================================================================

.p-plan-price-cols {
  display: flex;
  flex-direction: column;
  margin-top: 3.4rem;
  margin-bottom: 12rem;
  @include media-breakpoint-up(lg) {
    flex-direction: row;
    align-items: center;
    gap: 4rem;
    margin-top: 8rem;
  }

  &__img {
    width: 12.7rem;
    margin-left: auto;
    margin-right: auto;
    @include media-breakpoint-up(lg) {
      margin: 0;
    }
  }

  &__area {
    margin-top: 1.6rem;
    @include media-breakpoint-up(lg) {
      width: 100%;
      margin-top: 0;
    }
  }

  &__box {
    @include media-breakpoint-up(lg) {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 4rem;
    }
  }

  &__text {
    margin-top: 1.6rem;
    @include media-breakpoint-up(lg) {
      margin-top: 1.2rem;
    }
  }

  &__button {
    width: 100%;
    margin-top: 2.4rem;
    flex-shrink: 0;
    @include media-breakpoint-up(lg) {
      width: 18rem;
      margin-top: 0;
    }
  }

  &__col {
    margin-top: 2.4rem;
    @include media-breakpoint-up(lg) {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 6.4rem;
    }
  }
}
