// ==========================================================================
// Mypage
// ==========================================================================

.p-mypage-calendar {
  .calender-panel {
    @include media-breakpoint-up(lg) {
      padding-top: 0.8rem;
    }
  }
  .calendar_wrap {
    display: flex;
    justify-content: center;
    @include media-breakpoint-up(lg) {
      gap: 3.4rem;
    }
    > * {
      &:nth-child(2) {
        display: none;
        @include media-breakpoint-up(lg) {
          display: block;
        }
      }
      &:nth-child(3) {
        display: none;
        @include media-breakpoint-up(xl) {
          display: block;
        }
      }
    }
  }
  .calender__date_label {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    min-height: 4.4rem;
    color: $g-color-gray-800;
    border-radius: 1.6rem;
    border: solid 1px $g-color-blue-150;
    font-weight: 700;
    gap: 2.4rem;
    @include media-breakpoint-up(lg) {
      font-size: 1.8rem;
      border-radius: 1.8rem;
      gap: 6.4rem;
    }
  }

  .calendar_head {
    font-size: 1.4rem;
    margin: 0;
    padding: 2.9rem 0 0.5rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    @include media-breakpoint-up(lg) {
      font-size: 1.2rem;
      padding: 4.1rem 0 0;
    }
  }

  .calender-page-container_panel {
    position: relative;
  }

  .calendar-container {
    table {
      height: auto;
      margin: 0 auto;
    }
    th,
    td {
      text-align: center;
      vertical-align: middle;
      width: 3.8rem;
      height: 4.2rem;
      padding: 0.2rem;
      line-height: 0;
      position: relative;
      @include media-breakpoint-up(lg) {
        width: 3rem;
        height: 3.4rem;
        padding: 0.2rem;
      }
    }
    th {
      font-weight: 700;
      @include media-breakpoint-up(lg) {
        font-size: 1.2rem;
      }
    }

    td {
      font-size: 2rem;
      @include media-breakpoint-up(lg) {
        font-size: 1.4rem;
      }
    }
  }

  .day {
    td:first-child {
      color: #df6020;
    }

    td:nth-child(7) {
      color: #4080bf;
    }

    td.disabled_day {
      color: #ccc;
    }
  }

  .calender_day {
    font-size: 2.4rem;
    @include media-breakpoint-up(lg) {
      font-size: 1.8rem;
    }
  }

  .calender_slash {
    margin-left: 0.4rem;
    margin-right: 0.4rem;
  }

  .is-disabled {
    visibility: hidden;
  }

  .calender_date__input {
    display: none;
    + label {
      border: 0.2rem solid transparent;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      transition: 0.2s;
      //width: 100%;
      //aspect-ratio: 1;
      width: 3.4rem;
      height: 3.4rem;
      @include media-breakpoint-up(lg) {
        width: 2.6rem;
        height: 2.6rem;
      }
      // &:hover {
      //   border-color: $g-color-blue;
      //   font-weight: 700;
      // }
    }
  }

  [id^="reserveOn"] .calender_date__input + label {
    transition: color 300ms, background-color 300ms, border 300ms;
  }

  [id^="reserveOn"].selected .calender_date__input + label {
    background: $g-color-blue;
    color: $g-color-white !important;
    font-weight: 700;
  }

  [id^="reserveOn"] {
    @media (any-hover: hover) {
      &:hover .calender_date__input + label {
        border: 0.1rem solid $g-color-blue;
        border-radius: 100%;
      }
    }
  }

  .calender-page-nav {
    pointer-events: none;
    position: absolute;
    top: calc(50% + 2rem);
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    display: flex;
    justify-content: space-between;
    > * {
      pointer-events: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1rem;
      height: 1.6rem;
      background-image: url(/assets/img/mypage/icon-next.svg);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      font-size: 0;
      &.calender-page-nav__prev {
        background-image: url(/assets/img/mypage/icon-prev.svg);
      }
    }
  }

  .dialog_layout {
    padding: 0 2rem 2.4rem;
    @include media-breakpoint-up(lg) {
      padding-left: 0;
      padding-right: 0;
    }
    &.disabled {
      opacity: 0.3;
      pointer-events: none;
    }
    // label {
    //   &:hover {
    //     font-weight: 700;
    //   }
    // }
  }

  .input-radio {
    display: flex;
    align-items: center;
    line-height: 1;
    gap: 1rem;

    > *:last-child {
      margin-left: auto;
    }
  }

  .input-radio__text {
    padding-left: 0.6rem;

    &.selecting {
      color: $g-color-black;
    }

    &.disabled {
      color: #ccc;
    }
  }

  .modal__overlay {
    align-items: center;
    background: rgba(0, 0, 0, 0.7);
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: map-get($g-zindex, mypage-calendar);
  }

  .modal__container {
    background-color: $g-color-white;
    border-radius: 2.4rem;
    box-sizing: border-box;
    max-width: 33.5rem;
    overflow-y: auto;
    padding: 2.4rem;
    width: 100%;
    border: solid 1px $g-color-blue;
    padding: 1.5rem 2.4rem 2.4rem;
    @include media-breakpoint-up(lg) {
      max-width: 31.2rem;
      padding: 2.4rem 3.2rem 3.2rem;
    }
  }

  .modal_title {
    color: $g-color-black;
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: 0.02em;
    margin-bottom: 3.2rem;
    text-align: center;
    @include media-breakpoint-up(lg) {
      font-size: 2rem;
    }
  }

  .modal_time_input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 2rem;
    height: 2rem;
    position: relative;
    border: solid 1px $g-color-blue-150;
    border-radius: 50%;
    &:checked {
      border-color: $g-color-blue;
      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 1.4rem;
        height: 1.4rem;
        background-color: $g-color-blue;
        border-radius: 50%;
      }
      + *,
      + * + * {
        font-weight: 700;
      }
    }
  }

  .modal_button_area {
    display: flex;
    justify-content: space-between;
    margin-top: 2.8rem;
    @include media-breakpoint-up(lg) {
      margin-top: 2rem;
    }
  }

  .p-mypage-calendar-modal__button {
    cursor: pointer;
    background-color: $g-color-blue;
    color: $g-color-white;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid $g-color-blue;
    font-size: 1.4rem;
    font-weight: 700;
    text-align: center;
    width: 12.8rem;
    height: 3.4rem;
    @include media-breakpoint-up(lg) {
      width: 10.8rem;
      height: 3.6rem;
      font-size: 1.6rem;
    }
    &.--cancel {
      background-color: $g-color-white;
      color: $g-color-blue;
    }
    &.disabled {
      pointer-events: none;
      border-color: $g-color-gray-200;
      background-color: $g-color-gray-200;
    }
  }
}

.p-mypage-calendar__submit-button {
  display: block;
  width: 100%;
  max-width: 33.5rem;
  height: 6.1rem;
  margin: 8rem auto 0;
  @include media-breakpoint-up(lg) {
    width: 20.4rem;
    height: 7.1rem;
    margin: 12rem 0 0;
  }
}
