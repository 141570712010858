// ==========================================================================
// Header
// ==========================================================================

.l-header {
  $header-submenu-height: 7rem;
  line-height: normal; // OLD
  width: 100%;
  position: fixed;
  background-color: $g-color-white;
  z-index: map-get($g-zindex, header-menu);

  & + main {
    padding-top: 6rem;
    @include media-breakpoint-up(lg) {
      padding-top: 0;
    }
  }

  @include media-breakpoint-up(lg) {
    position: relative;
  }

  &:has(.l-header__col-bottom-list.-active) {
    @include media-breakpoint-up(lg) {
      padding-bottom: $header-submenu-height;
      position: relative;
    }
  }

  &.l-header.-top {
    position: relative;
    .l-header__inner {
      @include media-breakpoint-up(lg) {
        border-bottom: none;
      }
    }

    & + main {
      padding-top: 0;
    }
  }

  &:not(.l-header.-top) {
    .l-header__submenu {
      border-top: none;
    }
  }

  &.-login {
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  &__inner {
    height: 6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 0.1rem solid $g-color-blue-150;
    padding: 1.6rem 2rem;
    @include media-breakpoint-up(lg) {
      height: 12rem;
      padding: 2.4rem 4.8rem;
    }
  }

  &__logo {
    flex-shrink: 0;

    &-img {
      @include media-breakpoint-up(lg) {
        width: 24.6rem;
      }
    }

    &:hover {
      opacity: 1;
    }
  }

  // SP
  &__sp {
    display: flex;
    align-items: center;
    gap: 1.6rem;

    @include media-breakpoint-up(lg) {
      display: none;
    }

    &-menu {
      width: 100%;
      height: 100%;
      min-height: 100%;
      display: flex;
      flex-direction: column;
      background-color: $g-color-white;
      padding: 8rem 2rem 4rem 2rem;
      position: fixed;
      top: 0;
      left: 0;
      z-index: map-get($g-zindex, header-hamburger-menu);
      overflow: auto;
      opacity: 0;
      visibility: hidden;
      transition: opacity 300ms;

      &[aria-hidden="true"] {
        opacity: 1;
        visibility: visible;
      }
    }

    &-menu-buttons {
      width: 100%;
      display: flex;
      gap: 2rem;
      border-bottom: 0.2rem solid $g-color-blue-150;
      padding-bottom: 3.2rem;

      &:has(.l-header__sp-menu-button:nth-child(2)) {
        .l-header__sp-menu-button {
          width: calc(6 / 12 * (100% + 2rem) - 2rem);
        }
      }
    }

    & &-menu-button,
    & &-menu-button-last {
      display: flex;
      flex-direction: row;
      gap: 0.8rem;
      flex: 0 0 auto;
      height: 4.8rem;
      border-radius: 0.8rem;
      padding: 1rem;
    }

    & &-menu-button {
      width: 100%;
    }

    & &-menu-button-last {
      width: calc(12 / 12 * (100% + 2rem) - 2rem);
      margin-top: 3.2rem;
    }

    & &-menu-button-icon {
      width: 2rem;
      // height: 2.3rem;
      flex-shrink: 0;
    }

    &-menu-buttons01 {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      gap: 2rem;
      width: 100%;
      border-bottom: 0.2rem solid #c3cbe3;
      padding-bottom: 3.2rem;
    }

    & &-menu-buttons01-button {
      --button-font-size: 1.8rem !important;
      --button-border-radius: 0.8rem !important;
      --button-padding: 1.2rem 1.6rem !important;
      flex: 0 0 auto;
      width: 100%;
      flex-direction: row;
      justify-content: flex-start;
      gap: 0.8rem;
    }

    &-menu-buttons01-button-img {
      flex-shrink: 0;
      width: 4rem;
    }

    // & &-menu-buttons01-button-text {
    //   font-size: 1.8rem;
    // }

    &-menu-parent,
    &-menu-child {
      margin: 0;
    }

    &-menu-parent {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      border-bottom: 0.1rem solid $g-color-blue-150;
      padding: 2.4rem 1.6rem;

      &.-border {
        border-bottom-width: 0.2rem;
      }
    }

    &-menu-child {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      padding-left: 3.2rem;
      margin-top: 2rem;
    }

    // &-menu-parent-link {
    // }

    &-menu-parent-link,
    &-menu-child-link {
      color: $g-color-blue;
      font-size: 1.8rem;
      font-weight: 700;
      line-height: 1.4;
      letter-spacing: 0.02em;
      text-decoration: none;
    }

    &-menu-parent-link {
      &.-icon {
        display: inline-flex;
        gap: 0.8rem;
        align-items: center;
      }
    }

    &-menu-child-link {
      font-size: 1.6rem;
    }
  }

  &__logout {
    display: inline-flex;
    align-items: center;
    gap: 0.6rem;
    border-top: 0.2rem solid #c3cbe3;
    padding-top: 3.2rem;
    margin-top: 3.2rem;
  }

  &__mypage {
    color: $g-color-blue;
    display: flex;
    align-items: center;
    gap: 0.4rem;
    text-decoration: none;
    margin-right: 4.7rem;
  }

  &__mypage-text {
    text-align: right;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.2;
  }

  &__hamburger {
    display: block;
    width: 3rem;
    height: 2.1rem;
    border: none;
    background-color: transparent;
    margin-left: auto;
    position: absolute;
    right: 2rem;
    z-index: map-get($g-zindex, header-hamburger);

    &[aria-expanded="true"] {
      .l-header__hamburger-line {
        background-color: transparent;

        &::before {
          top: 0;
          transform: rotate(45deg);
        }

        &::after {
          top: 0;
          transform: rotate(-45deg);
        }
      }
    }
  }

  &__hamburger-line {
    display: block;
    width: 100%;
    height: 0.3rem;
    transform: translateX(0);
    background-color: $g-color-blue;

    &::before,
    &:after {
      content: "";
      display: block;
      height: 100%;
      position: absolute;
      width: 100%;
      background-color: $g-color-blue;
      transition: inherit;
      transition: top 300ms, transform 300ms;
    }

    &::before {
      top: -0.9rem;
    }
    &::after {
      top: 0.9rem;
    }
  }

  // PC
  &__pc {
    display: none;
    @include media-breakpoint-up(lg) {
      display: flex;
      // flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0.2rem;
      margin-left: auto;
    }
  }

  &__col {
    margin-right: 1.2rem;
  }

  &__col-top,
  &__col-bottom {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &-list {
      &.-active,
      &:hover {
        .l-header__submenu {
          opacity: 1;
          visibility: visible;
        }
      }

      &:hover {
        .l-header__submenu {
          z-index: map-get($g-zindex, header-menu-hover);
        }
      }
    }

    &-link {
      color: $g-color-blue;
      font-weight: 700;
      line-height: 1;
      border-radius: 1.2rem;
      padding: 0.6rem 1.2rem;
      text-decoration: none;
      transition: background-color 300ms;

      &.-active,
      &:hover {
        background-color: $g-color-yellow;
        opacity: 1;
      }
    }
  }

  &__col-top {
    margin-bottom: 1rem;
    @include media-breakpoint-up(lg) {
      gap: 1.6rem;
      margin-bottom: 1.4rem;
    }

    &-link {
      font-size: 1.4rem;
    }
  }

  &__col-bottom {
    gap: 1.6rem;

    &-list {
      margin-bottom: -3rem;
      padding-bottom: 3rem;
    }

    &-link {
      font-size: 1.6rem;
    }
  }

  &__mypage-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.6rem;
    color: $g-color-white;
    border-radius: 1.6rem;
    background: $g-color-blue;
    padding: 1.4rem 1.2rem 1.2rem 1.2rem;
    padding: 1.2rem 1.2rem 1rem;
    margin-left: 2.2rem;
    text-decoration: none;
    box-shadow: 0 0 0 0.2rem $g-color-blue inset;
    transition: color 300ms, background-color 300ms, box-shadow 300ms;
    position: relative;

    @media (any-hover: hover) {
      &:hover {
        color: $g-color-white;
        background-color: $g-color-blue-150;
        box-shadow: 0 0 0 0.2rem $g-color-blue-150 inset;
        opacity: 1;
      }
    }

    &::before {
      display: block;
      content: "";
      width: 0.1rem;
      height: 100%;
      background-color: rgba($g-color-blue, 0.25);
      position: absolute;
      top: 0;
      left: -2.2rem;
      bottom: 0;
      margin: auto;
    }

    &-icon {
      flex-shrink: 0;
      width: 2.1rem;
      // height: 2.3rem;
    }

    &-text {
      font-size: 1.6rem;
      font-weight: 700;
      line-height: 1.2; /* 19.2px */
    }
  }

  &.-fixed {
    .l-header__submenu {
      @include media-breakpoint-up(lg) {
        position: fixed;
        top: 0;
      }
    }
  }

  &__submenu {
    width: 100%;
    height: $header-submenu-height;
    background: $g-color-white;
    border-top: 0.1rem solid $g-color-blue-150;
    border-bottom: 0.1rem solid $g-color-blue-150;
    position: absolute;
    top: 12rem;
    left: 0;
    right: 0;
    z-index: map-get($g-zindex, header-menu);
    margin: auto;
    opacity: 0;
    visibility: hidden;
    transition: opacity 300ms, visibility 300ms;
  }

  &__submenu-lists {
    height: 100%;
    display: flex;
    align-items: center;
    gap: 3.2rem;
    padding-left: 4.8rem !important;
    padding-right: 4.8rem !important;
    @include media-breakpoint-up(xl) {
      padding-left: 8.8rem !important;
      padding-right: 8.8rem !important;
    }
  }

  &__submenu-list {
    &.-parent {
      border-right: 0.1rem solid $g-color-blue-150;
      .l-header__submenu-link {
        margin-right: 3.2rem;
      }
    }
  }

  &__submenu-link {
    color: $g-color-blue;
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 1.2;
    border-radius: 1.2rem;
    padding: 0.6rem 1.2rem;
    text-decoration: none;
    transition: background-color 300ms;

    &.-active,
    &:hover {
      background-color: $g-color-yellow;
      opacity: 1;
    }
  }

  //
  &__register-top {
    position: fixed;
    z-index: map-get($g-zindex, header-register);
    top: 0;
    right: 2rem;
    opacity: 0;
    visibility: hidden;
    transition: all 300ms;
    transform: translate(0, -5rem);
    @include media-breakpoint-up(lg) {
      // top: 0;
      right: 4.8rem;
    }

    &.-active {
      opacity: 1;
      visibility: visible;
      transform: translate(0, 0);
    }

    & &-button {
      width: 8.8rem;
      height: 3.8rem;
      border-radius: 0;
      border-radius: 0 0 1.2rem 1.2rem;
      @include media-breakpoint-up(lg) {
        width: auto;
        height: 9.8rem;
        border-radius: 0 0 1.6rem 1.6rem;
      }

      &.-lg {
        width: 13.4rem;
        height: 5.7rem;
        padding: 0;

        @include media-breakpoint-up(lg) {
          width: 20.7rem;
          height: 8.9rem;
        }
      }
    }
  }

  &:not(.l-header.-top) {
    .l-header__register {
      display: block;
    }
  }

  &.-fixed {
    .l-header__register {
      @include media-breakpoint-up(lg) {
        position: fixed;
        top: 0;
      }
    }
  }

  &__register {
    display: none;
    position: absolute;
    top: 6rem;
    right: 2rem;
    z-index: map-get($g-zindex, header-register);

    @include media-breakpoint-up(lg) {
      top: 11.9rem;
      right: 4.8rem;
    }

    & &-button {
      width: 8.8rem;
      height: 3.8rem;
      border-radius: 0;
      border-radius: 0 0 1.2rem 1.2rem;

      @include media-breakpoint-up(lg) {
        width: auto;
        height: 9.8rem;
        border-radius: 0 0 1.6rem 1.6rem;
      }

      &.-lg {
        width: 13.4rem;
        height: 5.7rem;
        padding: 0;

        @include media-breakpoint-up(lg) {
          width: 20.7rem;
          height: 8.9rem;
        }
      }
    }

    &-text-main {
      display: block;
      font-size: 1.4rem;
      font-weight: 700;
      line-height: 1.4;
      margin-top: 0.5rem;
      @include media-breakpoint-up(lg) {
        font-size: 2rem;
      }
    }

    &-text-sub {
      display: block;
      font-size: 1rem;
      font-weight: 700;
      line-height: 1.4;
      @include media-breakpoint-up(lg) {
        font-size: 1.4rem;
      }
      span {
        font-size: 0.75em;
        vertical-align: top;
        @include media-breakpoint-up(lg) {
          font-size: 0.5em;
        }
      }
    }
  }
}
