// ==========================================================================
// Mypage
// ==========================================================================

.p-mypage-campaigns-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @include media-breakpoint-up(lg) {
    flex-direction: row;
    align-items: center;
  }
}
