// ==========================================================================
// Label
// ==========================================================================

.c-label {
  display: inline-flex;
  align-items: center;
  gap: 0.8rem;
  margin-bottom: 1em;

  &__hint {
    flex-shrink: 0;
    margin-left: 1.6rem;
  }

  &.-wrap {
    flex-wrap: wrap;

    .c-label__hint {
      margin-left: 0;
      @include media-breakpoint-up(lg) {
        margin-left: 1.6rem;
      }
    }
  }
}
