// ==========================================================================
// Mypage
// ==========================================================================

.p-mypage-qrcode {
  display: flex;
  align-items: flex-start;
  gap: 1.75rem;

  &__img {
    flex-shrink: 0;
    width: 10.4rem;
    margin-top: -0.3rem;
    margin-right: -0.6rem;
  }
}
