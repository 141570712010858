// ==========================================================================
// Table
// ==========================================================================

.c-table-attr {
  width: 100%;
  padding-bottom: 1.6rem;
  margin-top: 3.2rem;
  @include media-breakpoint-up(lg) {
    // padding: 0 1.6rem;
    table-layout: fixed;
    border-bottom: 0.1rem solid $g-color-blue-150;
    margin: 2.2rem 1.3rem 0;
    padding-bottom: 0;
  }

  &__thead {
    // display: none;
    // @include media-breakpoint-up(lg) {
    //   display: table-row;
    // }

    @include media-breakpoint-down(lg) {
      display: none;
    }
  }

  &__tbody {
    @include media-breakpoint-up(lg) {
      position: relative;
      &::before {
        display: block;
        content: "";
        width: 100%;
        height: 0.1rem;
        background-color: $g-color-blue-150;
        position: absolute;
        top: 0;
      }

      &:is(.c-table-attr__thead + .c-table-attr__tbody) {
        &::before {
          height: 0.2rem;
        }
      }
    }

    &:is(.c-table-attr__tbody + .c-table-attr__tbody) {
      display: block;
      margin-top: 4.8rem;
      @include media-breakpoint-up(lg) {
        display: table-row;
        margin-top: 0;
      }
    }
  }

  &__heading {
    // display: flex;
    display: block;
    color: $g-color-gray-800;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.4;
    padding: 1.2rem 1.6rem 1.1rem;
    border-radius: 8px;
    background-color: $g-color-blue-50;
    margin-bottom: 1rem;

    @include media-breakpoint-up(lg) {
      display: flex;
      flex-direction: column;
      font-size: 1.6rem;
      margin: 1.6rem 1rem 1.6rem 0;
    }

    // @include media-breakpoint-down(lg) {
    //   display: flex;
    // }

    .-wrap {
      display: flex;
      align-items: baseline;
      flex-wrap: wrap;
      gap: 0.6rem 1.2rem;
      @include media-breakpoint-up(lg) {
        gap: 0.6rem 2.4rem;
      }

      &.-adjustment {
        gap: 0.6rem 0.8rem;
        @include media-breakpoint-up(lg) {
          gap: 0.6rem 0.8rem;
        }
      }
    }

    .-new-line {
      flex: 0 0 auto;
      width: 100%;
    }

    .-ss {
      font-size: 1.2rem;
      @include media-breakpoint-up(lg) {
        font-size: 1.4rem;
      }
    }

    .-slash {
      padding-left: 0.8rem;
    }
  }

  &__th {
    @include media-breakpoint-up(lg) {
      color: $g-color-gray-800;
      font-size: 1.8rem;
      font-weight: 700;
      line-height: 1.4;
      text-align: center;
      padding-bottom: 1.9rem;
    }

    &.-none {
      width: 53.4rem;
    }
  }

  &__td {
    width: 100%;
    // display: flex;
    padding-left: 1.6rem;

    @include media-breakpoint-up(lg) {
      width: auto;
      // display: table-cell;
      padding-left: 0;
    }

    @include media-breakpoint-down(lg) {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      gap: 2rem;
    }

    &::before {
      width: calc(58% - 3rem);
      content: attr(data-label);
      color: $g-color-gray-800;
      font-size: 1rem;
      font-weight: 700;
      line-height: 1.4;

      @include media-breakpoint-up(lg) {
        content: none;
      }
    }
  }

  &__price {
    width: calc(42% - -1rem);
    color: $g-color-gray-800;
    font-weight: 700;
    line-height: 1.4;
    text-align: right;
    @include media-breakpoint-up(lg) {
      display: block;
      width: 100%;
      text-align: center;
    }

    &:has(.-none) {
      text-align: center;
    }

    .-num {
      font-size: 1.6rem;
      @include media-breakpoint-up(lg) {
        font-size: 1.8rem;
      }
    }

    .-unit {
      font-size: 1.2rem;
      @include media-breakpoint-up(lg) {
        font-size: 1.4rem;
      }
    }

    .-tax,
    .-none {
      font-size: 1.2rem;
      font-weight: 400;
      @include media-breakpoint-up(lg) {
        display: block;
        font-size: 1.4rem;
      }
    }
  }
}
