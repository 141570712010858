// ==========================================================================
// justify
// ==========================================================================

@mixin utilities($media) {
  $keyword: if($media != null, "-" + $media, "");

  // .u-justify-content-flex-start#{$keyword} {
  //   justify-content: flex-start !important;
  // }

  // .u-justify-content-flex-end#{$keyword} {
  //   justify-content: flex-end !important;
  // }

  .u-justify-content-center#{$keyword} {
    justify-content: center !important;
  }

  // .u-justify-content-space-between#{$keyword} {
  //   justify-content: space-between !important;
  // }

  // .u-justify-content-space-around#{$keyword} {
  //   justify-content: space-around !important;
  // }

  // .u-justify-content-space-evenly#{$keyword} {
  //   justify-content: space-evenly !important;
  // }
}

@include utilities(null);
@each $media-name, $media-width in $g-breakpoint {
  @include media-breakpoint-up($media-name) {
    @include utilities($media-name);
  }
}
