// ==========================================================================
// Anchor link
// ==========================================================================

.c-anchor-link {
  &,
  &:hover,
  &:active {
    color: $g-color-blue;
    text-decoration: none;
  }

  display: inline-flex;
  align-items: baseline;
  gap: 0.8rem;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.4;

  @include media-breakpoint-up(lg) {
    font-size: 1.8rem;
    line-height: 1.4;
  }

  &__links {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 2.2rem;
    @include media-breakpoint-up(lg) {
      flex-direction: row;
      gap: 4.8rem;
    }
  }
}
