// ==========================================================================
// Text
// ==========================================================================

.c-text {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.8;
  letter-spacing: 0.02em;
  @include media-breakpoint-up(lg) {
    font-size: 1.8rem;
  }

  &__small {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.6;
    letter-spacing: 0.02em;
    @include media-breakpoint-up(lg) {
      font-size: 1.6rem;
    }
  }

  &__small-lv1 {
    color: $g-color-gray-800;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.4;
    @include media-breakpoint-up(lg) {
      font-size: 1.6rem;
    }
  }

  &__small-lv2 {
    color: $g-color-gray-800;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.4;
    @include media-breakpoint-up(lg) {
      font-size: 1.8rem;
    }
  }

  &__body-small {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.6;
    letter-spacing: 0.02em;
    margin-top: 1.484em;
  }

  &__lead {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.8;
    letter-spacing: 0.02em;
    margin-top: 1.4rem;
    @include media-breakpoint-up(lg) {
      font-size: 2rem;
      margin-top: 2.8rem;
    }

    &.-ver01 {
      font-size: 1.8rem;
      margin-top: 3.4rem;
      @include media-breakpoint-up(lg) {
        font-size: 2rem;
        margin-top: 2.8rem;
      }
    }
  }

  &__footage {
    color: $g-color-gray-500;
    font-size: 1.2rem;
    line-height: 1.6;
    letter-spacing: 0.02em;
    margin-top: 2.4rem;

    @include media-breakpoint-up(lg) {
      font-size: 1.4rem;
      margin-top: 1.4rem;
    }
  }

  &__notes {
    display: flex;
    gap: 1rem;
    color: $g-color-gray-500;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.6;
    letter-spacing: 0.02em;
    margin-top: 1.1rem;
    @include media-breakpoint-up(lg) {
      font-size: 1.4rem;
    }
  }
}
