// ==========================================================================
// Simulation
// ==========================================================================

.p-simulation-item {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  padding-bottom: 4rem;
  margin-top: 2.4rem;
  @include media-breakpoint-up(lg) {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    gap: 4.8rem;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    padding-bottom: 0;
    margin-top: 4rem;
  }

  &__col {
    display: flex;
    align-items: center;
    gap: 2rem;
    padding-bottom: 2.4rem;
    position: relative;
    @include media-breakpoint-up(lg) {
      flex: 0 0 auto;
      width: calc(4 / 12 * (100% + 4.8rem) - 4.8rem);
      flex-direction: column;
      align-items: flex-start;
      gap: 0;
      padding-bottom: 0;
    }

    &::after {
      display: block;
      content: "";
      width: calc(100% - 2.4rem);
      height: 0.1rem;
      background-color: $g-color-blue-150;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      margin-left: auto;
      margin-right: auto;
      @include media-breakpoint-up(lg) {
        content: none;
      }
    }

    &:last-child {
      padding-bottom: 0;

      &::after {
        content: none;
      }
    }
  }

  &__image {
    flex: 0 0 auto;
    width: calc(1 / 3 * (100% + 2rem) - 2rem);
    padding-left: 1.2rem;
    @include media-breakpoint-up(lg) {
      width: 100%;
      flex: 0 1 auto;
      display: flex;
      justify-content: center;
      height: 0;
      padding-top: calc(128 / 228 * 100%);
      padding-left: 0;
      margin-bottom: 1.2rem;
      position: relative;
    }
  }

  &__image-img {
    width: 100%;
    @include media-breakpoint-up(lg) {
      width: auto;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }
  }

  &__area {
    flex: 0 0 auto;
    width: calc(2 / 3 * (100% + 2rem) - 2rem);
    padding-right: 1.2rem;
    @include media-breakpoint-up(lg) {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      flex: 0 1 auto;
      padding: 0;
    }
  }

  &__area-name {
    color: $g-color-gray-800;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1.4;
    margin-bottom: 2rem;
    @include media-breakpoint-up(lg) {
      font-size: 1.8rem;
      margin-bottom: 1.6rem;
    }
  }

  &__volume {
    width: 100%;
    height: 3.2rem;
    display: flex;
    align-items: center;
    gap: 0.8rem;
    border-radius: 8px;
    border: 0.1rem solid $g-color-blue;
    padding: 0.8rem 1.6rem;
    @include media-breakpoint-up(lg) {
      margin-top: auto;
      height: 3.8rem;
    }
  }

  &__volume-down,
  &__volume-up {
    cursor: pointer;
    width: 1.6rem;
    height: 1.6rem;
    position: relative;

    &::before,
    &::after {
      content: "";
      width: 100%;
      height: 100%;
      background-color: $g-color-blue;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }

    span {
      opacity: 0;
      visibility: hidden;
    }
  }
  &__volume-down {
    margin-right: auto;

    &::before {
      height: 0.2rem;
    }
    &::after {
      content: none;
    }
  }

  &__volume-up {
    margin-left: auto;

    &::before {
      height: 0.2rem;
    }
    &::after {
      width: 0.2rem;
    }
  }

  &__volume-num {
    color: $g-color-gray-800;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1.4;
    text-align: center;
    border: none;
    outline: none;
    box-shadow: none;
    background-color: $g-color-white;
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
      -moz-appearance: textfield;
    }
    @include media-breakpoint-up(lg) {
      font-size: 1.8rem;
    }
  }

  &__ver01 {
    .p-simulation-item__col {
      @include media-breakpoint-up(lg) {
        width: calc(3 / 12 * (100% + 4.8rem) - 4.8rem);
      }
    }

    .p-simulation-item__image {
      @include media-breakpoint-up(lg) {
        padding-top: calc(128 / 232 * 100%);
      }
    }
  }
}
