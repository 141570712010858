// ==========================================================================
// Top
// ==========================================================================

.p-top-simulation {
  background: linear-gradient(180deg, rgba(127, 219, 251, 0.35) 0%, rgba(250, 226, 106, 0.7) 70%);
  padding-top: 4rem;
  padding-bottom: 8rem;
  position: relative;
  overflow: hidden;

  @include media-breakpoint-up(lg) {
    padding-top: 6.4rem;
    padding-bottom: 7.2rem;
  }

  &::before {
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    background-image: url(../img/top/simulation_bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    opacity: 0.2;
    z-index: -1;
  }

  &__heading {
    margin-bottom: 3.2rem;
  }

  &__illust01 {
    flex-shrink: 0;
    width: 100%;
    padding-left: 4.4rem;
    padding-right: 4.4rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5.9rem;
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  &__illust02 {
    flex-shrink: 0;
    margin-left: -0.8rem;
    margin-right: -0.8rem;
    @include media-breakpoint-up(lg) {
      display: none;
    }

    &-img {
      width: 100%;
    }
  }

  &__illust03 {
    display: none;
    @include media-breakpoint-up(lg) {
      display: block;
      width: 100%;
    }
  }

  &__text {
    color: $g-color-blue;
    font-size: 1.6rem;
    line-height: 1.8;
    letter-spacing: 0.02em;

    @include media-breakpoint-up(lg) {
      font-size: 2rem;
    }

    &:is(.p-top-simulation__text + .p-top-simulation__text) {
      margin-top: 1.6rem;
      margin-bottom: 3.2rem;
      @include media-breakpoint-up(lg) {
        margin-top: 1.2rem;
        margin-bottom: 0;
      }
    }

    &:first-child {
      margin-top: 2.4rem;
      @include media-breakpoint-up(lg) {
        margin-top: 0;
      }
    }
  }

  &__button {
    width: 30.5rem;
    margin-left: auto;
    margin-right: auto;

    @include media-breakpoint-up(lg) {
      width: 100%;
    }
  }
}
