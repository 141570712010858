// ==========================================================================
// Width
// ==========================================================================

@mixin utilities($media) {
  $keyword: if($media != null, "-" + $media, "");

  // .u-width-auto#{$keyword} {
  //   width: auto !important;
  // }

  // .u-width-50per#{$keyword} {
  //   width: 50% !important;
  // }

  .u-width-100per#{$keyword} {
    width: 100% !important;
  }

  .u-width-auto#{$keyword} {
    width: auto !important;
  }

  // .u-max-width-100per#{$keyword} {
  //   max-width: 100% !important;
  // }
}

@include utilities(null);
@each $media-name, $media-width in $g-breakpoint {
  @include media-breakpoint-up($media-name) {
    @include utilities($media-name);
  }
}
