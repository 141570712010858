// ==========================================================================
// Color
// ==========================================================================

@mixin utilities($media) {
  $keyword: if($media != null, "-" + $media, "");

  @each $color-key, $color-values in $utility-color {
    $values: map-get($color-values, values);

    @each $name, $value in $values {
      .u-color-#{$name}#{$keyword} {
        color: #{$value} !important;
      }
    }
  }
}

@include utilities(null);
// @each $media-name, $media-width in $g-breakpoint {
//   @include media-breakpoint-up($media-name) {
//     @include utilities($media-name);
//   }
// }
