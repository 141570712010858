// ==========================================================================
// Badge
// ==========================================================================

.c-badge {
  display: inline-flex;
  flex-shrink: 0;
  color: $g-color-white;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.8;
  letter-spacing: 0.02em;
  border-radius: 0.4rem;
  background: $g-color-blue;
  padding: 0.1em 0.6em;

  &.-outline {
    color: $g-color-gray-800;
    font-weight: 400;
    box-shadow: 0 0 0 0.1rem $g-color-gray inset;
    background-color: $g-color-white;
  }

  &.-size-lg {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.4;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
  }
}
