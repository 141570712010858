// ==========================================================================
// Line up
// ==========================================================================

.p-price-line-up {
  padding-top: 3.2rem;
  @include media-breakpoint-up(lg) {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }

  &__main {
    display: flex;
    flex-direction: column;
    @include media-breakpoint-up(lg) {
      flex-direction: row;
      gap: 3.2rem;
      align-items: flex-start;
    }
  }

  &__main-image {
    border-radius: 1.6rem;
    overflow: hidden;
    margin-bottom: 3.2rem;
    @include media-breakpoint-up(lg) {
      flex: 0 0 auto;
      width: calc(704 / 1072 * 100%);
      margin-bottom: 0;
    }
  }

  &__main-textarea {
    border-bottom: 0.1rem solid $g-color-blue-150;
    padding-bottom: 2.4rem;
    @include media-breakpoint-up(lg) {
      border-bottom: none;
      padding: 0 2.4rem 0;
    }
  }

  &__main-price-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 0.1rem solid $g-color-blue-150;
    padding-bottom: 2.4rem;
    margin-bottom: 2.4rem;
    @include media-breakpoint-up(lg) {
      flex-direction: column;
      padding-bottom: 3.2rem;
    }
  }

  &__main-price {
    flex-shrink: 0;
    width: 11.4rem;
    color: $g-color-gray-800;
    font-weight: 700;
    line-height: 1.4;
    text-align: center;
    border-right: 0.1rem solid $g-color-blue-150;
    padding-right: 1.6rem;
    margin-right: 1.6rem;
    @include media-breakpoint-up(lg) {
      width: 100%;
      border-right: 0;
      border-bottom: 0.1rem solid $g-color-blue-150;
      padding-right: 0;
      padding-bottom: 2.4rem;
      margin-right: 0;
      margin-bottom: 2rem;
    }

    .-title {
      display: block;
      font-size: 1.2rem;
      margin-bottom: 0.5rem;

      @include media-breakpoint-up(lg) {
        font-size: 1.4rem;
        margin-bottom: 0;
      }
    }

    .-num {
      font-size: 2.4rem;
      @include media-breakpoint-up(lg) {
        font-size: 3rem;
      }
    }

    .-unit {
      font-size: 1.4rem;

      @include media-breakpoint-up(lg) {
        font-size: 1.6rem;
      }
    }

    .-tax {
      display: block;
      font-size: 1.2rem;
      font-weight: 400;

      @include media-breakpoint-up(lg) {
        font-size: 1.4rem;
      }
    }
  }

  &__main-price-before {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    text-align: center;

    &-icon {
      width: 1.6rem;
      margin-left: 1rem;
      margin-right: 1rem;
      @include media-breakpoint-up(lg) {
        display: none;
      }
    }

    .-title {
      display: block;
      color: $g-color-gray-800;
      font-size: 1.2rem;
      font-weight: 700;
      line-height: 1.4;
      text-align: center;
      @include media-breakpoint-up(lg) {
        font-size: 1.4rem;
      }
    }

    .-through-pc {
      @include media-breakpoint-up(lg) {
        text-decoration: line-through;
      }
    }

    .-through-sp {
      display: block;
      text-decoration: line-through;
      @include media-breakpoint-up(lg) {
        display: inline;
        text-decoration: none;
      }
    }

    .-num {
      color: $g-color-gray;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.6;
      letter-spacing: 0.02em;
      text-align: center;
      @include media-breakpoint-up(lg) {
        font-size: 1.6rem;
      }
    }

    .-unit,
    .-tax {
      color: $g-color-gray;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.6;
      letter-spacing: 0.02em;
      text-align: center;
      @include media-breakpoint-up(lg) {
        font-size: 1.6rem;
      }
    }
  }

  &__main-price-after {
    text-align: center;
    @include media-breakpoint-up(lg) {
      margin-top: 1.6rem;
    }

    .-title {
      display: block;
      color: $g-color-blue;
      font-size: 1.6rem;
      font-weight: 700;
      line-height: 1;
      letter-spacing: 0.05em;
      @include media-breakpoint-up(lg) {
        font-size: 1.6rem;
        margin-bottom: 0.8rem;
      }

      span {
        font-size: 0.75em;
        vertical-align: top;
        @include media-breakpoint-up(lg) {
          font-size: 0.5em;
        }
      }
    }

    .-border {
      display: inline-block;
      padding-left: 0.5em;
      padding-bottom: 1rem;
      position: relative;
      &::before,
      &::after {
        display: block;
        content: "";
        width: 100%;
        height: 0.3rem;
        background: $g-color-yellow;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }

      &::before {
        bottom: 0.5rem;
      }
    }

    .-num {
      color: $g-color-blue;
      font-size: 2.6rem;
      font-weight: 700;
      line-height: 2;
      letter-spacing: 0.06em;
      @include media-breakpoint-up(lg) {
        font-size: 3rem;
        letter-spacing: normal;
        line-height: 1.4;
      }
    }

    .-unit {
      color: $g-color-blue;
      font-size: 1.2rem;
      font-weight: 700;
      line-height: 1.4;
      @include media-breakpoint-up(lg) {
        font-size: 1.6rem;
      }
    }
  }

  &__main-text {
    padding-left: 0.8rem;
    @include media-breakpoint-up(lg) {
      padding-left: 0;
    }
  }

  &__sub {
    display: flex;
    flex-wrap: wrap;
    gap: 2.2rem 2rem;
    margin-top: 2.4rem;
    @include media-breakpoint-up(lg) {
      gap: 2.8rem 3.2rem;
      padding-left: 1.6rem;
      padding-right: 1.6rem;
      margin-top: 8rem;
    }
  }

  &__sub-title {
    flex: 0 0 auto;
    width: 100%;
    color: $g-color-black;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: 0.02em;
    margin-bottom: 0.6rem;
    @include media-breakpoint-up(lg) {
      aspect-ratio: 304 / 228;
      width: calc(2 / 12 * (100% + 3.2rem) - 3.2rem);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      align-self: start;
      font-size: 1.4rem;
      text-align: center;
      border: 0.1rem solid $g-color-blue-150;
      border-radius: 0.8rem;
      overflow: hidden;
      padding: 1rem 0;
      margin-bottom: 0;
    }

    @include media-breakpoint-up(xl) {
      font-size: 1.8rem;
    }
  }

  &__sub-col {
    flex: 0 0 auto;
    width: calc(1 / 3 * (100% + 2rem) - 2rem);
    @include media-breakpoint-up(lg) {
      width: calc(2 / 12 * (100% + 3.2rem) - 3.2rem);
    }
  }

  &__sub-image {
    border-radius: 0.8rem;
    overflow: hidden;
  }

  &__sub-img {
  }

  &__sub-text {
    font-size: 1.2rem;
    line-height: 1.6;
    letter-spacing: 0.02em;
    margin-top: 1rem;
    @include media-breakpoint-up(lg) {
      font-size: 1.4rem;
      margin-top: 1.6rem;
    }
  }
}
