// ==========================================================================
// About
// ==========================================================================

.p-about-indent {
  &__lv1 {
    margin-top: 1.8rem;
  }

  &__lv2 {
    margin-top: 1.8rem;
    @include media-breakpoint-up(lg) {
      margin-top: 1.5rem;
    }
  }

  &__lv3 {
    margin-top: 2.4rem;
  }

  &__lv4 {
    margin-top: 2.4rem;
    @include media-breakpoint-up(lg) {
      margin-top: 2.8rem;
    }
  }

  &__lv5 {
    margin-top: 2.6rem;
    @include media-breakpoint-up(lg) {
      margin-top: 2.2rem;
    }
  }

  &__lv6 {
    margin-top: 4rem;
    @include media-breakpoint-up(lg) {
      margin-top: 4.8rem;
    }
  }

  &__lv7 {
    margin-top: 4rem;
    @include media-breakpoint-up(lg) {
      margin-top: 5.8rem;
    }
  }

  &__lv8 {
    margin-top: 3.4rem;
    @include media-breakpoint-up(lg) {
      margin-top: 4rem;
    }
  }

  &__lv9 {
    margin-top: 2.5rem;
    margin-bottom: 1.8rem;
    @include media-breakpoint-up(lg) {
      margin-top: 2.5rem;
      margin-bottom: 1.4rem;
    }
  }

  &__img {
    width: 100%;
    margin-top: 2.5rem;
    margin-bottom: 5.5rem;
    @include media-breakpoint-up(lg) {
      width: auto;
      max-width: none;
      height: 7rem;
      margin: 2.3rem auto 5.3rem;
    }
  }
}
