// ==========================================================================
// Option
// ==========================================================================

.p-faq-heading {
  margin-bottom: 5rem;
  @include media-breakpoint-up(lg) {
    margin-bottom: 8.7rem;
  }
}
