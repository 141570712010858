// ==========================================================================
// Mypage
// ==========================================================================

.p-mypage-card__decoration {
  &-plus {
    width: 3.2rem;
    height: 3.2rem;
    margin: 1.6rem auto;
    position: relative;

    &::before,
    &::after {
      width: 100%;
      height: 100%;
      display: block;
      content: "";
      background-color: $g-color-blue;
      border-radius: 0.2rem;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }

    &::before {
      width: 0.4rem;
    }

    &::after {
      height: 0.4rem;
    }
  }

  &-arrow {
    margin: 1.6rem auto;
    @include media-breakpoint-up(lg) {
      margin-bottom: 4rem;
    }
  }

  &-arrow01 {
    width: 2rem;
    margin: 1.2rem auto;
  }
}
