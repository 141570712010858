// ==========================================================================
// Option
// ==========================================================================

.p-faq-search {
  margin-top: 2.8rem;
  @include media-breakpoint-up(lg) {
    margin-top: 4.2rem;
  }

  &__box {
    width: 100%;
    height: 5.4rem;
    display: flex;
    align-items: center;
    gap: 1.2rem;
    padding: 1.4rem 2rem;
    background: $g-color-white;
    border: 0.1rem solid $g-color-blue;
    border-radius: 1.6rem;
    overflow: hidden;
    @include media-breakpoint-up(lg) {
      // width: 52rem;
      height: 6.4rem;
      padding: 1.9rem 3.2rem;
    }

    &:has(.p-faq-search__input:placeholder-shown) {
      color: $g-color-gray-450;
      // font-size: 1.6rem;
      background: $g-color-blue-50;
      border: 0.1rem solid $g-color-blue-50;
    }
    &:has(.p-faq-search__input:focus) {
      border-color: $g-color-blue;
      background: $g-color-white;
      box-shadow: 0px 0px 0px 4px rgba(17, 49, 143, 0.13) inset;
    }
    &:has(.p-faq-search__input:focus-visible) {
      border-color: $g-color-blue;
      outline: none;
    }
  }

  &__input {
    width: 100%;
    height: 100%;
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 1.4;
    letter-spacing: 0.02em;
    outline: none;
    border: none;
    background: none;
    box-shadow: none;
    padding: 0;
    -webkit-appearance: none;
    border-radius: 0;
    margin-bottom: 0.1rem;
    @include media-breakpoint-up(lg) {
      font-size: 2rem;
    }

    &::placeholder {
      color: $g-color-gray-450;
      font-size: 1.6rem;
      @include media-breakpoint-up(lg) {
        font-size: 1.8rem;
      }
    }

    &::-webkit-input-placeholder {
      color: $g-color-gray-450;
    }

    &:is(:-webkit-autofill, :autofill) {
      box-shadow: 0 0 0 1000px white inset;
      transition: background-color 5000s ease-in-out 0s;
    }
  }

  &__button {
    cursor: pointer;
    width: 2.4rem;
    height: 2.4rem;
    flex-shrink: 0;
    outline: none;
    border: none;
    background: none;
    box-shadow: none;
  }

  &__result {
    width: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
    // transition: all 100ms;

    &.-active {
      width: auto;
      height: auto;
      opacity: 1;
      visibility: visible;
      padding: 2.4rem 0.8rem 0;
    }
  }

  &__result-link {
    display: inline-flex;
    flex-direction: column;
    gap: 2.4rem;
    margin-top: 1.6rem;

    * {
      align-self: baseline;
    }
  }
}
