// ==========================================================================
// Checkbox
// ==========================================================================

.c-checkbox-items {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  @include media-breakpoint-up(lg) {
    gap: 2.5rem 2.4rem;
  }
}

.c-checkbox-item {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 0 2.4rem;
  box-shadow: 0 0 0 0.1rem $g-color-blue-150 inset;
  border: none;
  border-radius: 0.8rem;
  padding: 0.8rem;
  @include media-breakpoint-up(lg) {
    display: block;
    flex: 0 0 auto;
    width: calc(3 / 12 * (100% + 2.4rem) - 2.4rem);
  }

  &:has(.c-checkbox-item__input:checked) {
    box-shadow: 0 0 0 0.2rem $g-color-blue inset;

    .c-checkbox-item__input {
      outline: none;
      box-shadow: 0 0 0 0.1rem $g-color-blue inset;
      background-color: $g-color-blue-70;

      &::before {
        content: "";
        background-image: url(/assets/img/icon_check.svg);
        width: 1.4rem;
        height: 1.1rem;
        flex-shrink: 0;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        margin-top: auto;
        margin-bottom: auto;
        transform: translateX(-50%);
      }
    }
  }

  &:has(.c-checkbox-item__input:focus),
  &:has(.c-checkbox-item__input:focus-visible) {
    .c-checkbox-item__input {
      outline: none;
    }
  }

  &__input {
    display: flex;
    width: 2rem;
    height: 2rem;
    position: relative;
    -webkit-appearance: none;
    appearance: none;
    flex-shrink: 0;
    box-shadow: 0 0 0 0.1rem $g-color-blue-150 inset;
    border: none;
    border-radius: 0.4rem;
    background-color: $g-color-white;
    cursor: pointer;
  }

  &__status-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex: 0 0 auto;
    margin-bottom: 0.8rem;
    @include media-breakpoint-up(lg) {
      width: auto;
    }
  }

  &__status {
  }

  &__img {
    width: calc(90 / 319 * 100%);
    @include media-breakpoint-up(lg) {
      width: 100%;
    }
  }
  &__datas {
    flex: 0 0 auto;
    width: calc(100% - (90 / 319 * 100%) - 2.4rem);
    // margin-top: -1rem;
    // @include media-breakpoint-up(lg) {
    //   margin-top: 0;
    // }
  }

  &__data-name {
    display: block;
    color: $g-color-gray-800;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.4;
    @include media-breakpoint-up(lg) {
      font-size: 1.4rem;
      margin-top: 0.8rem;
    }
  }

  &__data-text {
    color: $g-color-gray-800;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.4;
    @include media-breakpoint-up(lg) {
      font-size: 1.4rem;
    }
  }

  &__data {
    color: $g-color-gray-800;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.4;
    padding-left: 1.1rem;
    @include media-breakpoint-up(lg) {
      width: auto;
      font-size: 1.4rem;
    }
  }

  &__tags {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    gap: 0.8rem;
    margin-top: 0.8rem;
  }
}
