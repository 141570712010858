// ==========================================================================
// How to use
// ==========================================================================

.p-how-to-use-main {
  flex-direction: column-reverse;
  border-top: 0.4rem solid $g-color-yellow;
  padding: 2.4rem 2rem 0;
  margin-left: -2rem;
  margin-right: -2rem;

  @include media-breakpoint-up(lg) {
    flex-direction: row;
    border-width: 0.8rem;
    padding: 1.6rem 0 0;
    margin-left: auto;
    margin-right: auto;
  }

  &__figure {
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;

    &.-ver01 {
      height: 14rem;
      @include media-breakpoint-up(lg) {
        height: 20rem;

        .p-how-to-use-main__img {
          height: 21.2rem;
        }
      }
    }

    &.-ver02 {
      height: 14.4rem;

      .p-how-to-use-main__img {
        height: 17.56rem;
      }

      @include media-breakpoint-up(lg) {
        height: 20rem;

        .p-how-to-use-main__img {
          height: 24.4rem;
        }
      }
    }

    &.-ver03 {
      height: 13.2rem;
      .p-how-to-use-main__img {
        height: 16.1rem;
      }

      @include media-breakpoint-up(lg) {
        display: flex;
        align-items: center;
        height: 16rem;

        .p-how-to-use-main__img {
          height: 25.9rem;
        }
      }
    }
  }

  &__img {
    height: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  &__heading {
    text-align: center;
    margin-top: 3.2rem;
    @include media-breakpoint-up(lg) {
      text-align: left;
      margin-top: 0;
    }

    &.-ver01 {
      margin-top: 0.7rem;
      margin-bottom: 5.4rem;
    }
  }

  &__text {
    margin-top: 2.4rem;
    @include media-breakpoint-up(lg) {
      margin-top: 2.8rem;
    }
  }

  &__button {
    width: 100%;
    margin-top: 2.4rem;
    @include media-breakpoint-up(lg) {
      width: auto;
    }
  }
}
