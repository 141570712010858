// ==========================================================================
// Mypage
// ==========================================================================

.p-mypage-list {
  border-radius: 1.6rem;
  border: 0.1rem solid $g-color-blue-150;
  padding: 2.4rem 1.6rem;
  overflow: hidden;
  margin-top: 4rem;

  @include media-breakpoint-up(lg) {
    padding-top: 1.9rem;
    padding-bottom: 1.9rem;
  }

  & + .p-mypage-list {
    margin-top: 3.2rem;
  }

  &__item {
  }

  &__titlearea {
    cursor: pointer;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    @include media-breakpoint-up(lg) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1.6rem;
      position: relative;
      padding-left: 3.2rem;
      padding-right: 6rem;
    }
  }

  &__titlearea-wrap {
    position: relative;
    @include media-breakpoint-up(lg) {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      gap: 0.8rem 3.9rem;
      position: static;
    }
  }

  &__titlearea-status {
    font-size: 1.1rem;
    padding: 0.2rem 0.8rem;
  }

  &__titlearea-heading {
    color: $g-color-gray-800;
    font-weight: 700;
    line-height: 1.4;
    margin-top: 0.8rem;
    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }

    .-sub {
      display: block;
      font-size: 1.2rem;
      @include media-breakpoint-up(lg) {
        font-size: 1.4rem;
      }
    }

    .-main {
      display: block;
      font-size: 1.6rem;
      @include media-breakpoint-up(lg) {
        font-size: 1.8rem;
      }
    }

    .-num {
      padding-left: 1.8rem;
      @include media-breakpoint-up(lg) {
        padding-left: 2.4rem;
      }
    }

    .-unit {
      font-size: 1.2rem;
      @include media-breakpoint-up(lg) {
        font-size: 1.4rem;
      }
    }
  }

  &__titlearea-buttons {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    gap: 0.8rem;
    margin-top: 2rem;
    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }
  }

  & &__titlearea-button {
    --button-border-radius: 0.8rem;
    --button-padding: 0.35rem 0.8rem;
    line-height: 1.4;
    @include media-breakpoint-up(lg) {
      --button-font-size: 1.4rem;
    }
  }

  &__titlearea-icon {
    display: block;
    width: 2.1rem;
    height: 2.1rem;
    position: absolute;
    top: 0;
    right: 0.4rem;
    bottom: 0;
    margin: auto;

    @include media-breakpoint-up(lg) {
      width: 2.5rem;
      height: 2.5rem;
      right: 1.2rem;
    }

    &::before,
    &::after {
      content: "";
      background-color: $g-color-blue;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }

    &::before {
      width: 0.1rem;
      height: 100%;
      transition: transform 0.3s;
    }

    &::after {
      width: 100%;
      height: 0.1rem;
    }
  }

  &__titlearea.-active {
    .p-mypage-list__titlearea-icon {
      &::before {
        transform: rotate(90deg);
      }
    }
  }

  &__content {
  }

  &__content-list {
    border-top: 0.2rem solid $g-color-blue-150;
    padding-top: 2.4rem;
    margin-top: 2.4rem;
    @include media-breakpoint-up(lg) {
      padding-top: 0.4rem;
      margin-top: 1.9rem;
      margin-bottom: -1.5rem;
    }
  }

  //
  &__content-list-item {
    display: flex;
    align-items: center;
    gap: 1.6rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    @include media-breakpoint-up(lg) {
      align-items: center;
      border-width: 0.1rem;
      gap: 1.6rem;
    }

    & + .p-mypage-list__content-list-item {
      border-top: 0.1rem solid $g-color-blue-150;
      padding-top: 2rem;
      margin-top: 2rem;
      @include media-breakpoint-up(lg) {
        padding-top: 0.4rem;
        margin-top: 0.4rem;
      }
    }

    &:has(.p-mypage-list__content-list-item-option) {
      .p-mypage-list__content-list-item-num {
        @include media-breakpoint-up(lg) {
          margin-right: 23.6rem;
        }
      }

      .p-mypage-list__content-list-item-option {
        display: none;
      }
    }

    &:has(.p-mypage-list__content-list-item-option-text) {
      .p-mypage-list__content-list-item-num {
        @include media-breakpoint-up(lg) {
          margin-right: 0;
        }
      }

      .p-mypage-list__content-list-item-option {
        display: block;
      }
    }
  }

  &__content-list-item-image {
    flex-shrink: 0;
    width: calc(57 / 285 * 100%);
    @include media-breakpoint-up(lg) {
      width: 9.6rem;
      // padding: 1.2rem;
    }
  }

  &__content-list-item-area {
    flex-grow: 1;
    @include media-breakpoint-up(lg) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__content-list-item-titlearea {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.6rem;
    @include media-breakpoint-up(lg) {
      width: 100%;
      gap: 0;
      padding: 0;
    }
  }

  &__content-list-item-name {
    color: $g-color-gray-800;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.4;
    @include media-breakpoint-up(lg) {
      font-size: 1.6rem;
    }
  }

  &__content-list-item-num {
    flex-shrink: 0;
    color: $g-color-gray-800;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1.4;
    text-align: right;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    @include media-breakpoint-up(lg) {
      font-size: 1.8rem;
      padding-left: 0.8rem;
      padding-right: 0.8rem;
    }

    .-unit {
      font-size: 1.2rem;
      @include media-breakpoint-up(lg) {
        font-size: 1.4rem;
      }
    }
  }

  &__content-list-item-option {
    display: none;
    border-top: 0.1rem solid $g-color-gray-200;
    padding-top: 18px;
    padding-left: 1.6rem;
    margin-top: 18px;
    @include media-breakpoint-up(lg) {
      width: 21.2rem;
      flex-shrink: 0;
      border-top: none;
      border-left: 0.1rem solid $g-color-gray-200;
      padding: 0 0.8rem;
      margin-top: 0;
      padding-left: 3.2rem;
      margin-left: 2.4rem;
    }
  }

  &__content-list-item-option-inputarea {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.6rem;

    & + .p-mypage-list__content-list-item-option-inputarea {
      margin-top: 0.4rem;
    }
  }

  &__content-list-item-option-text {
    color: $g-color-gray-800;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.4;
    margin-bottom: 0.4rem;
    @include media-breakpoint-up(lg) {
      font-size: 1.2rem;
    }
  }

  &__content-list-item-option-text01 {
    color: $g-color-gray-800;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.4;
    @include media-breakpoint-up(lg) {
      font-size: 1.6rem;
    }
  }

  //
  &__content-card {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    padding-top: 4.8rem;
    @include media-breakpoint-up(lg) {
      flex-direction: row;
      flex-wrap: wrap;
      gap: 2.4rem;
      padding: 4.8rem 0.8rem 0;
    }
  }

  &__content-card-item {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0 1.2rem;
    flex: 0 0 auto;
    border-radius: 0.8rem;
    box-shadow: 0 0 0 0.2rem $g-color-blue-150 inset;
    padding: 0.8rem;
    @include media-breakpoint-up(lg) {
      width: calc(3 / 12 * (100% + 2.4rem) - 2.4rem);
      align-items: normal;
      flex-direction: column;
    }
  }

  &__content-card-item-img {
    width: calc(90 / 285 * 100%);
    @include media-breakpoint-up(lg) {
      width: 100%;
    }
  }

  &__content-card-item-name {
    color: $g-color-gray-800;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.4;
    @include media-breakpoint-up(lg) {
      font-size: 1.4rem;
      padding: 0.35rem 0.8rem;
      margin-top: 1.2rem;
    }
  }

  //
  &__content-simultaneous {
    display: flex;
    align-items: center;
    gap: 1.6rem;
    border-top: 0.2rem solid $g-color-blue-150;
    padding: 2.4rem 0.8rem 0;
    margin-top: 2.4rem;
    @include media-breakpoint-up(lg) {
      padding-top: 0.4rem;
      margin-top: 1.9rem;
      margin-bottom: -1.5rem;
    }
  }

  &__content-simultaneous-img {
    width: calc(59 / 285 * 100%);
    @include media-breakpoint-up(lg) {
      flex-shrink: 0;
      width: 9.6rem;
      padding: 1.2rem;
    }
  }

  &__content-simultaneous-area {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @include media-breakpoint-up(lg) {
      flex-direction: row;
      align-items: center;
      flex-grow: 1;
      gap: 1.6rem;
    }
  }

  &__content-simultaneous-title {
    color: $g-color-gray-800;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1.4;
    @include media-breakpoint-up(lg) {
      font-size: 1.6rem;
    }
  }

  &__content-simultaneous-text {
    color: $g-color-gray-800;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.4;
    margin-top: 0.6rem;
    margin-bottom: 2.1rem;
    @include media-breakpoint-up(lg) {
      font-size: 1.6rem;
      margin: 0;
    }
  }

  & &__content-simultaneous-button {
    flex-shrink: 0;
    --button-border-radius: 0.8rem;
    --button-padding: 0.35rem 0.8rem;
    line-height: 1.4;
    @include media-breakpoint-up(lg) {
      --button-font-size: 1.4rem;
      margin-left: auto;
    }
  }
}
